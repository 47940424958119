import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';


const AssignSpecialDuty = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    padding-top: 0rem;
    box-sizing: border-box;
    border-radius: 1rem;
    border: 1px solid #cbcbcb;
    /* background-color: green; */

`;

const InputHeader = styled.h2`
	font-size: 27px;
	font-weight: 600;
	text-align: start;    
    padding: .8rem 0.2rem ;
	color: #3d3c3c;
	margin: 0 0 0rem;
    /* background-color: pink; */
`;

const InputContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* background-color: yellow; */
`;

export default function NewSpecialDuty(props) {
    var moment = require('moment');
    const [availableTeachers, setAvailableTeachers] = useState([])
    const [availableDutyTypes, setAvailableDutyTypes] = useState([])
    const [selectedDuty, setSelectedDuty] = useState(null)
    const [selectedTeacher, setSelectedTeacher] = useState(null)
    const [date, setDate] = useState({ error: false, date: '' })
    const [dutyCount, setDutyCount] = useState(1)

    const dutyAutoCompRef = useRef(null);
    const teacherAutoCompRef = useRef(null);

    const addSpecialDuty = async () => {
        let result = await fetch(`${process.env.REACT_APP_API}/admin/specialDuty/addSpecialDuty`, {
            method: "POST",
            headers: { Accept: 'application/json', "Content-Type": "application/json", },
            credentials: 'include',
            body: JSON.stringify({ dutyType: selectedDuty, date: date.date, teacherId: selectedTeacher, dutyCount: dutyCount }),
        });

        // Clearing date field
        setDate({ error: false, date: '' })
        // Clearing duty type field
        const teacherAutoCElement = teacherAutoCompRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
        if (teacherAutoCElement) teacherAutoCElement.click();
        // Clearing duty type field
        const dutyAutoCElement = dutyAutoCompRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
        if (dutyAutoCElement) dutyAutoCElement.click();

        props.fetchSpecialDuties()
    }

    return <AssignSpecialDuty>
        <InputHeader>Assign Special Duty</InputHeader>
        <InputContainer>
            <Autocomplete
                sx={{ flex: 1, mr: 3 }}
                ref={dutyAutoCompRef}
                onOpen={async () => {
                    let res = await fetch(`${process.env.REACT_APP_API}/admin/specialDuty/getAllSpecialDutyTypes`, {
                        credentials: 'include',
                    });
                    res = await res.json();
                    // Filtering active teachers
                    const activeDutyType = res.filter((e) => e.Duty_Type_Status == 'active')
                    setAvailableDutyTypes(activeDutyType);
                }}
                onInputChange={(event, newInputValue, reason) => {
                    // Run when clear button is clicked
                    if (reason === 'clear') {
                        setSelectedDuty(null)
                    } else {
                        setSelectedDuty(newInputValue)
                    }
                }}
                getOptionLabel={(option) => option.Duty_Type_Name}
                options={availableDutyTypes}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select Duty Type"
                    />
                )}
            />
            <TextField
                label="From: YYYY/MM/DD"
                sx={{ flex: 1, mr: 3 }}
                value={date.date}
                error={date.error}
                onChange={(e) => {
                    setDate({ error: !moment(e.target.value, 'YYYY/MM/DD').isValid(), date: e.target.value })
                }}
            />
            <Autocomplete
                sx={{ flex: 1, mr: 3 }}
                ref={teacherAutoCompRef}
                onOpen={async () => {
                    let res = await fetch(`${process.env.REACT_APP_API}/admin/teacher`, {
                        credentials: 'include',
                    });
                    res = await res.json();
                    // Filtering active teachers
                    const activeTeacher = res.filter((e) => e.Teacher_status == 'active')
                    setAvailableTeachers(activeTeacher);
                }}
                onInputChange={(event, newInputValue, reason) => {
                    // Run when clear button is clicked
                    if (reason === 'clear') {
                        setSelectedTeacher(null)
                    } else {
                        setSelectedTeacher(newInputValue)
                    }
                }}
                getOptionLabel={(option) => option.Teacher_name}
                options={availableTeachers}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select Teacher"
                        fullWidth={true}
                    />
                )}
            />
            <TextField
                id="outlined-number"
                label="Duty"
                type="number"
                sx={{ flex: 1, mr: 3 }}
                InputProps={{ inputProps: { min: 1, max: 10 } }}
                InputLabelProps={{
                    shrink: true,
                }}
                value={dutyCount}
                onChange={(event, newValue, reason) => { setDutyCount(event.target.value) }}
            />

            <Tooltip title="Assign Special Duty" placement="top">
                <span>
                <IconButton
                    aria-label="delete"
                    color="primary"
                    disabled={selectedDuty == null || selectedTeacher == null || date.error || dutyCount < 1 || dutyCount > 10}
                    onClick={() => {
                        addSpecialDuty()
                    }}
                >
                    <PersonAddOutlinedIcon />
                </IconButton>
                </span>
            </Tooltip>
        </InputContainer>
    </AssignSpecialDuty>
}