import React, { useState} from 'react';
import styled from 'styled-components'
import SideBar from './sideBar'
import Dashboard from './dashboard'
import ExamNotification from './examNotification'
import LeaderBoard from './leaderBoard'
import Profile from './profile'

const TeacherWrapper = styled.div`
	height: 100vh;
	width: 100vw;
	display: flex;
	padding: 0px;
    box-sizing: border-box;
	
	/* background-color: yellow; */
`;

export function Teacher(props) {
	document.title = "Teacher Dashboard"

	const [active, setActive] = useState(false);
	const [menu, setMenu] = useState('dashboard');

	return <TeacherWrapper>

		<SideBar isActive={active} setActive={setActive} setMenu={setMenu} />

		{menu == 'dashboard' && <Dashboard isActive={active} setActive={setActive} />}
		{menu == 'examNotification' && <ExamNotification isActive={active} setActive={setActive} />}
		{menu == 'leaderBoard' && <LeaderBoard isActive={active} setActive={setActive} />}
		{menu == 'profile' && <Profile isActive={active} setActive={setActive} />} 
	</TeacherWrapper>

}