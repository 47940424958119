import styled from "styled-components";


export const HeaderText = styled.h2`
	font-size: 40px;
	font-weight: 600;
    text-align: center;
	color: #005ae6;
	margin: 0 0 2rem;
    padding-left: 2px;
`;

export const CardsContainer = styled.div`
    height: fit-content;
    width: 100%;
    box-sizing: border-box;
    /* padding: 1rem; */
    margin-bottom: 2rem;
    padding-left: 4px;
    /* border: 3px solid red;
    background-color: green; */
`;

export const MiniCard = styled.div`
    height: 70px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    background-color: white;
    
`;

export const MiniCardText = styled.p`
    padding-left: .8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    user-select: none;
`;

export const DataGridContainer = styled.div`
    /* width: 100%; */
    /* height: fit-content;;  */
    /* background-color: pink; */
`;