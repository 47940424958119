import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserProvider } from "./contexts/userContext";
import RequireAuth from './helpers/RequireAuth';
import Home from './components/Home';
import Login from "./pages/login";
import { AdminPannel } from "./pages/admin";
import { Teacher } from "./pages/teacher";

function App() {
	return (
		<UserProvider>
			<Router>
				<Routes>
					<Route path="/login" element={<Login />} />
					<Route
						path='/'
						element={
							<RequireAuth>
								<Home />
							</RequireAuth>
						}
					/>
					<Route
						path="/admin"
						element={
							<RequireAuth>
								<AdminPannel />
							</RequireAuth>
						}
					/>
					<Route
						path="/teacher"
						element={
							<RequireAuth>
								<Teacher />
							</RequireAuth>
						}
					/>
				</Routes>
			</Router>
		</UserProvider>
	);
}

export default App;
