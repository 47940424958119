import useUser from '../hooks/useUser';
import { AdminPannel } from '../pages/admin';
import { Teacher } from '../pages/teacher';

const Home = () => {
  const { user } = useUser();
  // console.log('inside home, user:', user)
  return <>
    {user.user_type === 'admin' && <AdminPannel />}
    {user.user_type === 'teacher' && <Teacher />}
  </>;
};

export default Home;
