import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import useUser from '../../../hooks/useUser';
import NoActiveExams from './NoActiveExams'
import AlreadyBooked from './AlreadyBooked'
import FullSlot from './FullSlot'
import { HeaderText, CardsContainer, MiniCard, MiniCardText, DataGridContainer } from "../common";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { borderRadius } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

const ContentWrapper = styled.div`
    width: 100%;
    padding: 5px 35px 10px 10px;
    position: relative;
    margin-left: ${props => props.isActive ? '230px' : '98px'}; 
    transition: all 0.2s ease;
    /* background-color:red; */
`;

const ExamsContainer = styled.div`
    width: 100%;
    marin-right: 10px;
    padding: .5rem;
    border-radius: 1rem;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    /* border: .1px solid #005ae6; */
    /* background-color: #0088ff54; */
`;

const TabPanelContainer = styled.div`
    width: 100%;
    /* height: 400px; */
    /* background-color: #0088ff54; */
`;

const SlotPanelContainer = styled.div`
    width: 100%;
    /* height: 100px; */
    /* border: 2px solid #d0ff0052; */
    /* background-color: #5d00ff53; */
`;

const SlotFooter = styled.div`
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    /* background-color: yellow; */
`;

export default function ExamNotification(props) {
    document.title = "Exam Notification";

    const [activeExams, setActiveExams] = useState([]);
    const [activePanel, setActivePanel] = useState(0);
    var moment = require('moment');

    function SlotPanel(props) {
        const { children, value, index, examId, remark, slotId, isAllocated, ...other } = props;
        const { user, setUser } = useUser();
        const [venues, setVenues] = useState([]);
        const [selectedVenue, setSelectedVenue] = useState(null);
        const [alreadyAllocated, setAlreadyAllocated] = useState(null);        // True if teacher already have an allocation in this slot

        const getDutyFromSlotIdTeacherId = async () => {
            let result = await fetch(`${process.env.REACT_APP_API}/teacher/getDutyFromSlotIdTeacherId`, {
                method: "POST",
                headers: { Accept: 'application/json', "Content-Type": "application/json", },
                credentials: 'include',
                body: JSON.stringify({ slotId: slotId, username: user.username, }),
            });
            const res = await result.json();
            setAlreadyAllocated(res.length > 0);
        }

        const getActiveVenueFromExamId = async () => {
            if (examId === slotId === undefined) return
            let result = await fetch(`${process.env.REACT_APP_API}/teacher/getActiveVenueFromExamId`, {
                method: "POST",
                headers: { Accept: 'application/json', "Content-Type": "application/json", },
                credentials: 'include',
                body: JSON.stringify({ examId: examId, slotId: slotId }),
            });
            const res = await result.json();
            setVenues(res);
        }

        const addDuty = async (data) => {
            let result = await fetch(`${process.env.REACT_APP_API}/teacher/addDuty`, {
                method: "POST",
                headers: { Accept: 'application/json', "Content-Type": "application/json", },
                credentials: 'include',
                body: JSON.stringify(data),
            });
            const res = await result.json();
            // console.log('returned object is ', (res));
            if (res.error) alert(res.message)
            getDutyFromSlotIdTeacherId()
            getActiveVenueFromExamId()
        };

        useEffect(() => {
            getDutyFromSlotIdTeacherId();
            getActiveVenueFromExamId();
        }, [])

        useEffect(() => {
            setSelectedVenue(null);
        }, [props.activeSlot])

        return <>
            <SlotPanelContainer
                role="tabpanel"
                hidden={value !== props.activeSlot}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === props.activeSlot && (
                    <Box sx={{ p: 3, pb: 0 }}>
                        {alreadyAllocated && <AlreadyBooked />}
                        {!alreadyAllocated && alreadyAllocated != null && venues.length <= 0 && <FullSlot />}
                        {!alreadyAllocated && venues.length > 0 && <>
                            <Typography align='center' sx={{ mb: 3, color: '#666666' }}>{venues.length} Duties Left!</Typography>
                            <FormControl sx={{ width: '100%', }}>
                                <FormLabel sx={{ ml: 3, mb: .8, fontSize: 20, }}>Select a Venue</FormLabel>
                                <RadioGroup row sx={{ width: '100%', p: 1, border: '2px solid #1976d2', borderRadius: '1rem', }}>
                                    {venues.map((venue, index) => <FormControlLabel
                                        sx={{
                                            color: 'primary',
                                            m: '.5rem',
                                            pr: '1rem',
                                            border: '1px solid #1976d2',
                                            color: '#666666',
                                            borderRadius: '3rem'
                                        }}
                                        value={venue.Room_id}
                                        control={<Radio />}
                                        label={venue.Venue_name}
                                        onChange={(event) => {
                                            setSelectedVenue(event.target.value);
                                        }}
                                    />)}
                                </RadioGroup>
                            </FormControl>
                            <SlotFooter>
                                <Button
                                    sx={{ height: '50px', width: '350px', mt: '20px', borderRadius: '10rem' }}
                                    variant="contained"
                                    startIcon={<EventAvailableIcon />}
                                    disabled={selectedVenue === null}
                                    onClick={() => {
                                        addDuty({
                                            examId: examId,
                                            slotId: slotId,
                                            username: user.username,
                                            room: selectedVenue
                                        });
                                    }}
                                >Confirm</Button>
                            </SlotFooter>
                        </>}
                        {remark != '' && <Typography variant="body2" sx={{ p: 0, mt: 3, color: '#585858', }}>{`Note: ${remark}`}</Typography>}
                    </Box>
                )}

            </SlotPanelContainer>
            {/* <button onClick={() => console.log(alreadyAllocated)}>fghffg</button> */}
        </>;
    }

    function TabPanel(props) {
        const { children, value, index, examId, remark, ...other } = props;
        const [slots, setSlots] = useState([]);
        const [activeSlot, setActiveSlot] = useState(0);

        const getActiveSlotFromExamId = async () => {
            if (examId === undefined) return
            // console.log('getActiveSlotFromExamId',);
            let result = await fetch(`${process.env.REACT_APP_API}/teacher/getActiveSlotFromExamId`, {
                method: "POST",
                headers: { Accept: 'application/json', "Content-Type": "application/json", },
                credentials: 'include',
                body: JSON.stringify({ examId: examId }),
            });
            const res = await result.json();
            setSlots(res);
        }

        useEffect(() => {
            getActiveSlotFromExamId();
        }, [])


        return <>
            <TabPanelContainer
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 1,  }}>
                        <TabContext value={activeSlot}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={(event, value) => { setActiveSlot(value); }} aria-label="lab API tabs example" centered variant="fullWidth">
                                    {slots.map((slot, index) => { return <Tab label={`Slot${index + 1}: ${moment(slot.Slot_startTime, "HH:mm").format("hh:mm A")} - ${moment(slot.Slot_endTime, "HH:mm").format("hh:mm A")}`} value={index} /> })}
                                </TabList>
                            </Box>
                            {slots.map((slot, index) => <SlotPanel value={index} examId={slot.Exam_id} remark={remark} slotId={slot.Slot_id} activeSlot={activeSlot} isAllocated={slot.Slot_status == 'allocated'} getActiveSlotFromExamId={getActiveSlotFromExamId}></SlotPanel>)}
                        </TabContext>
                    </Box>
                )}
            </TabPanelContainer>
        </>;
    }

    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    const getActiveExams = async () => {
        let res = await fetch(`${process.env.REACT_APP_API}/teacher/getActiveExams`, {
            credentials: 'include',
        });
        res = await res.json();
        // Filtering exams that are not over yet
        let liveExams = [];
        res.forEach(exam => {
            if (moment(exam.Exam_date).subtract(2, 'days').format('YYYY/MM/DD HH:mm') >= moment().format('YYYY/MM/DD HH:mm')) {
                liveExams.push(exam);
            }
        });
        setActiveExams(liveExams)
    };

    useEffect(() => {
        getActiveExams()
    }, [])

    return <ContentWrapper isActive={props.isActive}>
        <HeaderText>Exam Notifications</HeaderText>
        <ExamsContainer>
            {activeExams.length <= 0 && <NoActiveExams />}
            {activeExams.length > 0 && <Box sx={{ flexGrow: 1, display: 'flex', }} >
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={activePanel}
                    onChange={(event, newValue) => { setActivePanel(newValue); }}
                    aria-label="Vertical tabs example"
                    sx={{ width: '200px', pt: 8, borderRight: 1, borderColor: 'divider' }}
                >
                    {activeExams.map((exam, index) => {
                        const date = moment(exam.Exam_date, 'YYYY/MM/DD HH:mm').format('YYYY/MM/DD')
                        
                        return <Tab label={`on ${date} (${moment(exam.Exam_date).fromNow()}) {${exam.Exam_id}}`} {...a11yProps(index)} />

                    })}
                </Tabs>
                {activeExams.map((exam, index) => {
                    return <TabPanel value={activePanel} index={index} examId={exam.Exam_id} remark={exam.Exam_remark}></TabPanel>
                })}

            </Box>

            }
            {/* {activeExams.length > 0 && activeExams.map(exam => {
                console.log(exam)
                return <Accordion expanded={activePanel === exam.Exam_id} onChange={(event, isExpanded) => {
                    setActivePanel(isExpanded ? exam.Exam_id : null);
                }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>{exam.Exam_name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat.
                            Aliquam eget maximus est, id dignissim quam.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            })} */}
        </ExamsContainer>
    </ContentWrapper>
}