import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    gridVisibleSortedRowIdsSelector,
    useGridApiContext
} from '@mui/x-data-grid';
import { HeaderText, CardsContainer, MiniCard, MiniCardText, DataGridContainer } from "../common";
import useUser from '../../../hooks/useUser';
import jsPDF from 'jspdf';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import PrintIcon from '@mui/icons-material/Print';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const VenueWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 100px;
    overflow: auto;
    scrollbar-width: none;
    /* background-color: pink; */
`;

const AddVenueContainer = styled.div`
    width: 300px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Footer = styled.div`
    width: 100%;
    margin: 30px 0 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-evenly;
`;

export const ErrorText = styled.p`
    margin: 0;
    padding: 0;
    font-size: .8rem;
    text-align: right;
    color: red;
`;

export default function Venue(props) {

    const { user } = useUser();
    const [venue, setVenue] = useState([]);
    const [openDialogue, setOpenDialogue] = useState(false)
    const [newVenueName, setnewVenueName] = useState('')
    const [isValidName, setIsValidName] = useState(true)

    const exportPdf = (PrintData, Heading, FileName) => {
        const doc = new jsPDF()
        doc.autoTable({
            margin: { top: 82 },
            didDrawPage: (data) => {
                var currentPageNo = doc.internal.getCurrentPageInfo().pageNumber;
                var str = 'Page ' + currentPageNo;
                data.settings.margin.top = 10; // Reseting top margin. The change will be reflected only after print the first page.
                if (currentPageNo === 1) {
                    // doc.addImage(base64Img, 'PNG', 85, 15, 40, 10);
                    doc.setFontSize(15);
                    doc.text(Heading, 20, 40);
                    doc.setFontSize(12);
                    doc.text(`Generated By: ${user.username} (Admin)`, 20, 46);
                    doc.text(`Generated At : ${new Date()}`, 20, 52);
                }
                doc.setFontSize(10);
                var pageSize = doc.internal.pageSize;
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                doc.text(str, data.settings.margin.left, pageHeight - 10);
            },
            ...PrintData
        });
        doc.save(`${FileName}.pdf`)
    }

    const fetchVenue = async () => {
        let res = await fetch(`${process.env.REACT_APP_API}/admin/venues`, {
            credentials: 'include',
        });
        res = await res.json();
        res = res.map(({ Venue_id: id, ...rest }) => ({ id, ...rest }));

        setVenue(res)
    };

    const cellDoubleClick = async (params) => {
        if (params.field != 'Venue_status' || !window.confirm("Press ok to change status")) {
            return;
        }

        let res = await fetch(`${process.env.REACT_APP_API}/admin/venues/updateStatus`, {
            method: "POST",
            headers: { Accept: 'application/json', "Content-Type": "application/json", },
            credentials: 'include',
            body: JSON.stringify({ VenueId: params.row.id, VenueStatus: params.row.Venue_status }),
        });
        res = await res.json();
        if (res.error) {
            alert(res.message);
        } else {
            fetchVenue();
        }
    }

    const changeVenueName = async (params, event, details) => {
        if (params.field != 'Venue_name') {
            return;
        }

        let res = await fetch(`${process.env.REACT_APP_API}/admin/venues/changeVenueName`, {
            method: "POST",
            headers: { Accept: 'application/json', "Content-Type": "application/json", },
            credentials: 'include',
            body: JSON.stringify({ id: params.id, value: params.value }),
        });
        fetchVenue()
    }

    const addVenue = async () => {
        let result = await fetch(`${process.env.REACT_APP_API}/admin/venues/addVenue`, {
            method: "POST",
            headers: { Accept: 'application/json', "Content-Type": "application/json", },
            credentials: 'include',
            body: JSON.stringify({ newVenueName: newVenueName, }),
        });
        const res = await result.json();
        console.log('returned object is ', (res));
        setOpenDialogue(false)
        fetchVenue()
    };

    function validateVenue() {
        if (newVenueName == "") {
            setIsValidName(true);
            return true
        }
        for (const key in venue) {
            if (venue[key].Venue_name.toUpperCase() == newVenueName.toUpperCase()) {
                setIsValidName(false);
                break;
            }
            setIsValidName(true);
        }
    }

    useEffect(() => {
        fetchVenue()
    }, []);

    useEffect(() => {
        validateVenue()
    }, [newVenueName]);

    const getFilteredRows = ({ apiRef }) => gridVisibleSortedRowIdsSelector(apiRef);

    const CustomToolbar = (props) => {
        const apiRef = useGridApiContext();

        const CSVToJSON = csv => {
            const lines = csv.split('\n');
            // Removing '\r'
            for (var i = 0; i < lines.length; i++) { lines[i] = lines[i].replace('\r', ''); }
            const keys = lines[0].split(',');
            return lines.slice(1).map(line => {
                return line.split(',').reduce((acc, cur, i) => {
                    const toAdd = {};
                    toAdd[keys[i]] = cur;
                    return { ...acc, ...toAdd };
                }, {});
            });
        };

        const handleExport = (options) => {
            if (options.type === 'pdf') {
                exportPdf({
                    body: CSVToJSON(apiRef.current.getDataAsCsv(options)),
                    columns: [
                        { header: 'Venue ID', dataKey: 'ID' },
                        { header: 'Venue Name', dataKey: 'Venue Name' },
                        { header: `Venue Status`, dataKey: `Status` },
                    ]
                }, 'Venue Details', 'Venue Details');
            } else if (options.type === 'csv') {
                apiRef.current.exportDataAsCsv(options);
            }
        }

        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <Button
                    color={'primary'}
                    startIcon={<PrintIcon />}
                    onClick={() => handleExport({ getRowsToExport: getFilteredRows, type: 'pdf' })}
                >
                    Export pdf
                </Button>
                <Button
                    color={'primary'}
                    startIcon={<FileDownloadOutlinedIcon />}
                    onClick={() => handleExport({ getRowsToExport: getFilteredRows, type: 'csv' })}
                >
                    Export CSV
                </Button>
            </GridToolbarContainer>
        );
    };

    const columns = [
        { field: 'id', headerName: 'ID', type: 'number', headerAlign: 'left', align: "left", width: 150 },
        { field: 'Venue_name', headerName: 'Venue Name', width: 150, editable: true, },
        {
            field: 'Venue_status', headerName: 'Status', width: 150,
            renderCell: (params) => {
                if (params.value === 'active') {
                    return <Chip label={'Active'} variant="outlined" color="primary" icon={<CheckCircleOutlinedIcon />} />
                } else if (params.value === 'inactive') {
                    return <Chip label={'Inactive'} variant="outlined" color="error" icon={<DoNotDisturbOnOutlinedIcon />} />
                }
            }
        },
    ];

    return <VenueWrapper>
        <HeaderText onClick={() => console.log(venue)}>Venue Management</HeaderText>
        <CardsContainer>
            <MiniCard
                onClick={() => setOpenDialogue(true)}
            >
                <AddBusinessOutlinedIcon fontSize="large" color="primary" />
                <MiniCardText>Add Venue</MiniCardText>
            </MiniCard>
        </CardsContainer>
        <DataGridContainer>
            <DataGrid
                rows={venue}
                columns={columns}
                sx={{
                    height: '82vh', maxHeight: '82vh',
                }}
                components={{ Toolbar: CustomToolbar }}
                disableSelectionOnClick
                onCellDoubleClick={cellDoubleClick}
                onCellEditCommit={changeVenueName}

            ></DataGrid>
        </DataGridContainer>
        <Dialog open={openDialogue}
            PaperProps={{
                style: {
                    display: 'flex',
                    width: 'fit-content',
                    overflow: 'visible',
                    boxShadow: 'none',
                    borderRadius: '1rem'
                },
            }}>
            <AddVenueContainer>
                <HeaderText>Add Venue</HeaderText>
                <TextField
                    required
                    id="outlined-required"
                    label="New Venue Name"
                    fullWidth
                    error={!isValidName}
                    helperText={!isValidName ? "This venue already exists. Try another" : ""}
                    onChange={(event) => { setnewVenueName(event.target.value) }}

                />
                <Footer>
                    <Button
                        variant="contained"
                        startIcon={<AddBusinessOutlinedIcon />}
                        disabled={newVenueName == "" || !isValidName}
                        onClick={addVenue}
                    >Add</Button>
                    <Button
                        variant="outlined"
                        // startIcon={<ClearIcon />}
                        onClick={() => {
                            setnewVenueName("")
                            setOpenDialogue(false)
                        }}
                    >Cancel</Button>
                </Footer>
            </AddVenueContainer>
        </Dialog>
    </VenueWrapper>;
}