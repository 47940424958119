import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { DataGridContainer } from "../common";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import useUser from '../../../hooks/useUser';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined';
import FastForwardOutlinedIcon from '@mui/icons-material/FastForwardOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';

const RelieverTableWrapper = styled.div`
    width: 100%;  
    margin-top: 1rem;
    padding: .7rem;
    box-sizing: border-box;
    /* display: flex; */
    border-radius: 1rem;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    /* background-color: pink; */
`;

const TableHeader = styled.div`
    width: 100%;  
    /* margin-top: 1rem; */
    /* padding: .7rem; */
    /* box-sizing: border-box; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: pink; */
`;

const Header = styled.h2`
	font-size: 30px;
	font-weight: 500;
	text-align: start;
    padding: .5rem 1rem;
	color: #545454;
	margin: 0 0 0rem;
`;

const DateRangeContainer = styled.div`
    width: auto;
    margin-left: 18px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* align-items: flex-end; */

    p {
        padding: 0;
        color: #1A2027;
    /* background-color: lightblue; */
    }
`;

export default function ReleverTable() {
    var moment = require('moment');
    const [allRelievers, setAllRelievers] = useState([]);
    const [uniqueExams, setUniqueExams] = useState([]);
    const [minDate, setMinDate] = useState(`${moment().subtract((moment().format('MM') <= 5 ? 1 : 0), 'years').format('YYYY')}/06/01`);
    const [fromDate, setFromDate] = useState(`${moment().subtract((moment().format('MM') <= 5 ? 1 : 0), 'years').format('YYYY')}/06/01`);
    const [toDate, setToDate] = useState(moment().format('YYYY/MM/DD'));
    const { user } = useUser();
    const {
        register,
        formState: { errors }
    } = useForm({ mode: "all" });

    const exportPdf = (id, Exam_name) => {
        console.log("🚀 ~ file: releverTable.jsx ~ line 35 ~ exportPdf ~ Exam_name", Exam_name)
        const doc = new jsPDF()
        doc.autoTable({
            margin: { top: 82 },
            didDrawPage: (data) => {
                var currentPageNo = doc.internal.getCurrentPageInfo().pageNumber;
                var str = 'Page ' + currentPageNo;
                data.settings.margin.top = 10; // Reseting top margin. The change will be reflected only after print the first page.
                if (currentPageNo === 1) {
                    // doc.addImage(base64Img, 'PNG', 85, 15, 40, 10);
                    doc.setFontSize(12);
                    doc.text(`${Exam_name}`, 20, 40);
                    doc.text(`Generated By: ${user.username} (Admin)`, 20, 46);
                    // doc.text(`End Date : ${endDateFilter}`, 20, 52);
                    // doc.text(`Designation : ${designationFilter}`, 20, 58);
                    // doc.text(`Department : ${departmentFilter !== 'all' ? departments.find((department) => department.dept_id == departmentFilter).dept_name : 'all'}`, 20, 64);
                    doc.text(`Generated At : ${new Date()}`, 20, 70);
                    // applicantFilter && doc.text(`Applicant Name : ${applicantFilter}`, 20, 76);
                }
                doc.setFontSize(10);
                var pageSize = doc.internal.pageSize;
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                doc.text(str, data.settings.margin.left, pageHeight - 10);
            },
            html: `#RelieverTable-${id}`,
        });
        doc.save(` RelieverTable - ${id}.pdf`)
    }

    const getAllRelievers = async () => {
        let res = await fetch(`${process.env.REACT_APP_API}/admin/reliever/getAllRelievers`, {
            credentials: 'include',
        });
        res = await res.json();
        setAllRelievers(res);
        res = res.map(({ Exam_id: id, ...rest }) => ({ id, ...rest }));

        // Getting unique exams base on id
        const TempUniqueExams = res.filter((e, i) => res.findIndex(a => a['id'] === e['id']) === i);
        // console.log("🚀 ~ file: releverTable.jsx ~ line 129 ~ getAllRelievers ~ TempUniqueExams", TempUniqueExams)
        // console.table(TempUniqueExams)
        setUniqueExams(TempUniqueExams);

        // Finding minimum date
        // let small = TempUniqueExams[0].Exam_date;
        // let max = TempUniqueExams[0].Exam_date;
        // TempUniqueExams.forEach((e) => {
        //     if (e.Exam_date < small) {
        //         small = e.Exam_date;
        //     }
        //     if (e.Exam_date > max) {
        //         max = e.Exam_date;
        //     }
        // })
        // setFromDate(small);
        // setMinDate(small);
        // setToDate(max);
    };

    function RelieverSlotDetails(props) {
        const { slotDetails, index } = props;
        const [relieverVenues, setRelieverVenues] = useState([]);
        const [relieverDetails, setRelieverDetails] = useState([])

        const getRelieverVenues = async () => {
            if (slotDetails.Teacher_id === undefined || slotDetails.Exam_id === undefined) return
            let result = await fetch(`${process.env.REACT_APP_API}/admin/reliever/getRelieverVenues`, {
                method: "POST",
                headers: { Accept: 'application/json', "Content-Type": "application/json", },
                credentials: 'include',
                body: JSON.stringify({ teacherId: slotDetails.Teacher_id, examId: slotDetails.Exam_id }),
            });
            const res = await result.json();
            // Duplicating venue if there exist only 1 venue name
            if (res.length == 1) { res.push(res[0]) }
            setRelieverVenues(res)
        }

        const getRelieverAllocation = async () => {
            let result = await fetch(`${process.env.REACT_APP_API}/admin/reliever/getRelieverAllocation`, {
                method: "POST",
                headers: { Accept: 'application/json', "Content-Type": "application/json", },
                credentials: 'include',
                body: JSON.stringify({ relieverSlotId: slotDetails.Reliever_Slot_id, }),
            });
            const res = await result.json();
            setRelieverDetails(res)
        }

        useEffect(() => {
            getRelieverVenues();
            getRelieverAllocation()
        }, []);

        // console.log("🚀 ~ file: releverTable.jsx ~ line 187 ~ getRelieverVenues ~ slotDetails", slotDetails)
        return <TableRow key={slotDetails.Reliever_Slot_id}>
            <TableCell component="th" scope="row">{index}</TableCell>
            <TableCell component="th" scope="row">{slotDetails.Reliever_Slot_id}</TableCell>
            <TableCell component="th" scope="row">{slotDetails.Teacher_name} ({slotDetails.Dept_Name})</TableCell>
            <TableCell component="th" scope="row">{relieverVenues.map((venue, index) => {
                return <Chip key={index} variant="outlined" color="primary" sx={{ margin: '0 .2rem' }} icon={<StoreOutlinedIcon />} label={`${venue.Venue_name}  `} />
            })}</TableCell>
            {relieverDetails.length > 0 ? <>
                <TableCell component="th" scope="row">{relieverDetails[0].Teacher_name} ({relieverDetails[0].Dept_Name})</TableCell>
                <TableCell component="th" scope="row">Minimum: 20 Mins</TableCell>
                {/* <TableCell component="th" scope="row">{relieverDetails[0].Start_Time.split(' ')[1]} - {relieverDetails[0].End_Time.split(' ')[1]}</TableCell> */}
            </> : <>
                <TableCell component="th" scope="row">-</TableCell>
                <TableCell component="th" scope="row">-</TableCell>
            </>}
        </TableRow>

    }

    function Row(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);

        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">{`${row.id};   ${row.Exam_date}`}</TableCell>
                    <TableCell component="th" scope="row">{moment(row.Exam_date).fromNow()}</TableCell>
                    {row.Exam_status === 'active' && <TableCell><Chip label={'Active'} variant="outlined" color="primary" icon={<CheckCircleOutlinedIcon />} /></TableCell>}
                    {row.Exam_status === 'inactive' && <TableCell><Chip label={'Inactive'} variant="outlined" color="error" icon={<DoNotDisturbOnOutlinedIcon />} /></TableCell>}
                    {/* <TableCell component="th" scope="row"><Button variant="outlined"  startIcon={<PrintOutlinedIcon />}>Print</Button></TableCell> */}
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Reliever Slot Details
                                    <Tooltip title="Generate Report" placement="right">
                                        <IconButton aria-label="delete" color="primary" sx={{ ml: 2 }} onClick={() => exportPdf(row.id, row.Exam_name)}>
                                            <PrintOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                                <Table size="small" aria-label="purchases" id={`RelieverTable-${row.id}`}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Sl No.</TableCell>
                                            <TableCell>Reliever Slot ID</TableCell>
                                            <TableCell>Teacher</TableCell>
                                            <TableCell>Venues</TableCell>
                                            <TableCell>Relieving Teacher</TableCell>
                                            <TableCell>Timing</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {allRelievers.filter((e) => e.Exam_name == row.Exam_name).map((slotDetails, index) => {
                                            return <RelieverSlotDetails key={index} slotDetails={slotDetails} index={index + 1} />
                                        })}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    useEffect(() => {
        getAllRelievers()
    }, []);


    return <RelieverTableWrapper>
        <TableHeader>
            <Header>Reliever Table</Header>
            <DateRangeContainer>
                <TextField
                    name="from"
                    label="From: YYYY / MM / DD"
                    variant="outlined"
                    focused
                    color="primary"
                    error={!!errors.from}
                    helperText={!!errors.from ? errors.from.message : ''}
                    value={fromDate}
                    sx={{ width: 200, }}
                    {...register("from", {
                        onChange: (e) => { setFromDate(e.target.value) },
                        validate: value => value <= toDate || "Starting Date Must Come Before Ending Date"
                    })}
                />
                <FastForwardOutlinedIcon sx={{ m: 1, color: '#1976d2', }} />
                <TextField
                    name="to"
                    label="To: YYYY / MM / DD "
                    variant="outlined"
                    focused
                    color="primary"
                    error={!!errors.to}
                    helperText={!!errors.to ? errors.to.message : ''}
                    value={toDate}
                    sx={{ width: 200 }}
                    {...register("to", {
                        onChange: (e) => setToDate(e.target.value),
                        validate: value => value > fromDate || "Ending Date Must Come After Starting Date"
                    })}
                />
                <Tooltip title="Reset Date Range" placement="top" sx={{ width: 'fit-content', ml: 1, }}>
                    <IconButton
                        aria-label="Button to reset date range"
                        onClick={() => {
                            setFromDate(minDate);
                            setToDate(moment().format('YYYY/MM/DD'));
                        }}
                    >
                        <EventRepeatOutlinedIcon sx={{ color: '#1976d2', }} />
                    </IconButton>
                </Tooltip>
            </DateRangeContainer>
        </TableHeader>
        <DataGridContainer>
            <Table aria-label="collapsible table" sx={{ borderRadius: '50%', }}>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Exam Date</TableCell>
                        <TableCell>ETA / Completed</TableCell>
                        <TableCell>Exam Status</TableCell>
                        {/* <TableCell>Generate Report</TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {uniqueExams.filter((e) => e.Exam_date >= fromDate && e.Exam_date <= toDate).map((row) => {
                        return <Row key={row.id} row={row} />
                    })}
                </TableBody>
            </Table>
        </DataGridContainer>
    </RelieverTableWrapper>
}