import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    gridVisibleSortedRowIdsSelector,
    useGridApiContext
} from '@mui/x-data-grid';
import jsPDF from 'jspdf';
import useUser from '../../../hooks/useUser';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PrintIcon from '@mui/icons-material/Print';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import HowToRegIcon from '@mui/icons-material/HowToReg';

const LeaderBoardWrapper = styled.div`
    width: 100%;
    /* padding: 1rem .5rem; */
    display: flex;
    /* overflow: scroll; */
    /* scrollbar-width: none; */
    /* transition: all 0.2s ease;     */
    box-sizing: border-box;
    /* background-color:red; */
`;

const FilterWrapper = styled.div`
    width: 350px;
    overflow: auto;
    scrollbar-width: thin;
    /* box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px; */ */
    /* background-color: orange; */
`;

const SelectOptionsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    /* box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px; */ */
`;

const DataGridContainer = styled.div`
    width: 100%;
    padding: 1rem;
    /* height: fit-content;;  */
    /* background-color: pink; */
`;

export default function LeaderBoard(props) {

    const { user, setUser } = useUser();
    var moment = require('moment');
    const [openDateRangeFilter, setOpenDateRangeFilter] = React.useState(true);
    const [openTeacherFilter, setOpenTeacherFilter] = React.useState(false);
    const [startDate, setStartDate] = useState(`${moment().subtract((moment().format('MM') <= 5 ? 1 : 0), 'years').format('YYYY')}/06/01`);
    const [endDate, setEndDate] = useState(moment().format('YYYY/MM/DD'));
    const [allteachers, setAllTeachers] = useState([]);
    const [showActiveTeachers, setShowActiveTeachers] = useState(true)
    const [teacherSearchQuery, setTeacherSearchQuery] = React.useState('');
    const [leaderBoard, setLeaderBoard] = useState([])

    const exportPdf = (PrintData, Heading, FileName, startDate = null, endDate = null) => {
        const doc = new jsPDF()
        doc.autoTable({
            margin: { top: 82 },
            didDrawPage: (data) => {
                var currentPageNo = doc.internal.getCurrentPageInfo().pageNumber;
                var str = 'Page ' + currentPageNo;
                data.settings.margin.top = 10; // Reseting top margin. The change will be reflected only after print the first page.
                if (currentPageNo === 1) {
                    // doc.addImage(base64Img, 'PNG', 85, 15, 40, 10);
                    doc.setFontSize(15);
                    doc.text(Heading, 20, 40);
                    doc.setFontSize(12);
                    doc.text(`Generated By: ${user.username} (Admin)`, 20, 46);
                    doc.text(`Generated At : ${new Date()}`, 20, 52);
                    doc.text(`From: ${startDate}`, 20, 58);
                    doc.text(`To: ${endDate}`, 20, 64);
                }
                doc.setFontSize(10);
                var pageSize = doc.internal.pageSize;
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                doc.text(str, data.settings.margin.left, pageHeight - 10);
            },
            ...PrintData
        });
        doc.save(`${FileName}.pdf`)
    }

    const fetchTeacher = async () => {
        let res = await fetch(`${process.env.REACT_APP_API}/admin/teacher`, {
            credentials: 'include',
        });
        res = await res.json();
        res = res.map((val) => ({ checked: true, ...val }));

        setAllTeachers(res)
    };

    const teacherLeaderBoard = async () => {
        if (!isValidDate(startDate) || !isValidDate(endDate)) return
        let res = await fetch(`${process.env.REACT_APP_API}/common/report/teacherLeaderBoard`, {
            method: "POST",
            headers: { Accept: 'application/json', "Content-Type": "application/json", },
            credentials: 'include',
            body: JSON.stringify({ startDate: startDate, endDate: endDate, ascendingOrder: false }),
        });
        res = await res.json();

        // Renaming Teacher_id as id
        res = res.map(({ Teacher_id: id, ...rest }) => ({ id, ...rest }));
        setLeaderBoard(res);
    }


    useEffect(() => {
        fetchTeacher()
        teacherLeaderBoard()
    }, [startDate, endDate]);

    function isValidDate(dateString) {
        var regEx = /^\d{4}\/\d{2}\/\d{2}$/;
        if (!dateString.match(regEx)) return false;  // Invalid format
        return moment(dateString, 'YYYY/MM/DD').isValid()
    }

    const getFilteredRows = ({ apiRef }) => gridVisibleSortedRowIdsSelector(apiRef);

    const CustomToolbar = (props) => {
        const apiRef = useGridApiContext();

        const CSVToJSON = csv => {
            const lines = csv.split('\n');
            // Removing '\r'
            for (var i = 0; i < lines.length; i++) { lines[i] = lines[i].replace('\r', ''); }
            const keys = lines[0].split(',');
            return lines.slice(1).map(line => {
                return line.split(',').reduce((acc, cur, i) => {
                    const toAdd = {};
                    toAdd[keys[i]] = cur;
                    return { ...acc, ...toAdd };
                }, {});
            });
        };

        const handleExport = (options) => {
            if (options.type === 'pdf') {
                exportPdf({
                    body: CSVToJSON(apiRef.current.getDataAsCsv(options)),
                    columns: [
                        { header: 'Sl. No.', dataKey: 'Sl. No.' },
                        { header: 'Teacher Name', dataKey: 'Teacher Name' },
                        { header: 'Department Name', dataKey: 'Department Name' },
                        { header: `Exam Duties`, dataKey: `Exam Duties` },
                        { header: `Reliever Duties`, dataKey: `Reliever Duties` },
                        { header: `Special Duties`, dataKey: `Special Duties` },
                        { header: `Total Duties`, dataKey: `Total Duties` },
                    ]
                }, 'Teacher Leader Board', 'Teacher Leader Board', startDate, endDate);
            } else if (options.type === 'csv') {
                apiRef.current.exportDataAsCsv(options);
            }
        }

        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <Button
                    color={'primary'}
                    startIcon={<PrintIcon />}
                    onClick={() => handleExport({ getRowsToExport: getFilteredRows, type: 'pdf' })}
                >
                    Export pdf
                </Button>
                <Button
                    color={'primary'}
                    startIcon={<FileDownloadOutlinedIcon />}
                    onClick={() => handleExport({ getRowsToExport: getFilteredRows, type: 'csv' })}
                >
                    Export CSV
                </Button>
            </GridToolbarContainer>
        );
    };

    const columns = [
        { field: 'SlNo', headerName: 'Sl. No.', type: 'number', headerAlign: 'left', align: "left", flex: 1 },
        { field: 'id', headerName: 'ID', type: 'number', headerAlign: 'left', align: "left", hide: true, flex: 1 },
        { field: 'Teacher_name', headerName: 'Teacher Name', flex: 1, },
        { field: 'Dept_Name', headerName: 'Department Name', flex: 1, },
        { field: 'TotalExamDuties', headerName: 'Exam Duties', type: 'number', headerAlign: 'left', align: "left", flex: 1 },
        { field: 'TotalRelieverDuties', headerName: 'Reliever Duties', type: 'number', headerAlign: 'left', align: "left", flex: 1 },
        { field: 'TotalSpecialDuties', headerName: 'Special Duties', type: 'number', headerAlign: 'left', align: "left", flex: 1 },
        { field: 'TotalDuties', headerName: 'Total Duties', type: 'number', headerAlign: 'left', align: "left", flex: 1 },
    ];

    return <LeaderBoardWrapper >
        <FilterWrapper>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }} >
                <ListItem>
                    <ListItemAvatar>
                        <Avatar sx={{ bgcolor: '#005ae6' }}>
                            <TuneOutlinedIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Filters" />
                </ListItem>

                <ListItemButton onClick={() => setOpenDateRangeFilter(!openDateRangeFilter)}>
                    <ListItemIcon>
                        <DateRangeOutlinedIcon sx={{ color: '#1976d2', }} />
                    </ListItemIcon>
                    <ListItemText primary="Date Range" />
                    {openDateRangeFilter ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openDateRangeFilter} timeout="auto" >
                    <List component="div" disablePadding sx={{ p: 2, pl: 4 }}>
                        <TextField
                            focused
                            label="From"
                            fullWidth
                            size="small"
                            value={startDate}
                            error={!isValidDate(startDate)}
                            onChange={(e) => {
                                setStartDate(e.target.value)
                            }}
                        />
                        <TextField
                            focused
                            label="To"
                            fullWidth
                            size="small"
                            sx={{ mt: 2 }}
                            value={endDate}
                            error={!isValidDate(endDate)}
                            onChange={(e) => {
                                setEndDate(e.target.value)
                            }}
                        />
                        <Button
                            variant="contained"
                            fullWidth
                            sx={{ mt: 2 }}
                            startIcon={<EventRepeatOutlinedIcon />}
                            onClick={() => {
                                // Reseting start date(Starting of academic calendar) and end date
                                setStartDate(`${moment().subtract((moment().format('MM') <= 5 ? 1 : 0), 'years').format('YYYY')}/06/01`);
                                setEndDate(moment().format('YYYY/MM/DD'));
                            }}
                        >Reset</Button>
                    </List>
                </Collapse>
                <Divider />

                <ListItemButton onClick={() => setOpenTeacherFilter(!openTeacherFilter)}>
                    <ListItemIcon>
                        <GroupsOutlinedIcon sx={{ color: '#1976d2', }} />
                    </ListItemIcon>
                    <ListItemText primary="Teachers" />
                    {openTeacherFilter ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openTeacherFilter} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ p: 2, pl: 2, pt: 0, pb: 0 }}>
                        <SelectOptionsContainer>
                            <Tooltip title="Select All" placement="top" >
                                <IconButton>
                                    <CheckBoxOutlinedIcon
                                        sx={{ color: '#1976d2', }}
                                        onClick={() => {
                                            setAllTeachers(prevValue => [...prevValue].map(el => ({ ...el, checked: true })))
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Deselect All" placement="top" >
                                <IconButton>
                                    <IndeterminateCheckBoxOutlinedIcon
                                        sx={{ color: '#1976d2', }}
                                        onClick={() => {
                                            setAllTeachers(prevValue => [...prevValue].map(el => ({ ...el, checked: false })))
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>

                        </SelectOptionsContainer>
                        <TextField
                            focused
                            label="Search Teacher"
                            fullWidth
                            size="small"
                            sx={{ mt: 1 }}
                            value={teacherSearchQuery}
                            onChange={(e) => {
                                setTeacherSearchQuery(e.target.value)
                            }}
                        />
                        {/* Lists teachers which matches with teacher name in search box */}
                        {allteachers.filter(n => n.Teacher_name.toLowerCase().includes(teacherSearchQuery.toLowerCase())).map((teacher, index) => {
                            return <ListItem
                                key={teacher.Teacher_id}
                                disablePadding
                            >
                                <ListItemButton
                                    id={teacher.Teacher_id}
                                    role={undefined}
                                    sx={{ p: 0, display: 'flex', justifyContent: 'flex-start', height: '60px' }}
                                    onClick={(e) => {
                                        setAllTeachers(prevValue =>
                                            [...prevValue].map(el =>
                                                el.Teacher_id === teacher.Teacher_id ? ({ ...el, checked: !el.checked }) : el)
                                        )
                                    }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            sx={{ m: 0 }}
                                            edge="start"
                                            checked={teacher.checked}
                                            tabIndex={-1}
                                            key={teacher.Teacher_id}
                                            // disableRipple
                                            inputProps={{ 'aria-labelledby': 1 }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        id={teacher.Teacher_id}
                                        disableTypography
                                        primary={teacher.Teacher_name}
                                        secondary={<Typography variant="body2" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>{teacher.Dept_Name}</Typography>} />
                                </ListItemButton>
                            </ListItem>
                        })}
                    </List>
                </Collapse>
                <Divider />

                <ListItem>
                    <ListItemIcon>
                        <HowToRegIcon sx={{ color: '#1976d2', }} />
                    </ListItemIcon>
                    <ListItemText id="switch-list-label-active-teachers" primary="Active Teachers" />
                    <Switch
                        edge="end"
                        onChange={() => setShowActiveTeachers(!showActiveTeachers)}
                        checked={showActiveTeachers}
                    />
                </ListItem>
                <Divider />
            </List>
        </FilterWrapper>
        <Divider orientation="vertical" flexItem />
        <DataGridContainer>
            <DataGrid
                rows={leaderBoard.filter((row, index) => {
                    // First it filters allteacers with checked and status criterias and then that teachers are selected from teachers in leader board
                    return (
                        allteachers.filter(
                            (teacher) => teacher.checked && teacher.Teacher_status === (showActiveTeachers ? 'active' : 'inactive')
                        ).find((obj) => obj.Teacher_name == row.Teacher_name)
                    )
                    // Below map() adds serial No. to the table
                }).map((row, index) => ({ ...row, SlNo: index + 1 }))}
                columns={columns}
                autoHeight={true}
                components={{ Toolbar: CustomToolbar }}
                disableSelectionOnClick
            ></DataGrid>
        </DataGridContainer>
    </LeaderBoardWrapper>
}