import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    gridVisibleSortedRowIdsSelector,
    useGridApiContext
} from '@mui/x-data-grid';
import { HeaderText, CardsContainer, MiniCard, MiniCardText, DataGridContainer } from "../common";
import useUser from '../../../hooks/useUser';
import jsPDF from 'jspdf';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import PrintIcon from '@mui/icons-material/Print';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';

const TeacherWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 100px;
    overflow: auto;
scrollbar-width: none;
    /* background-color: pink; */
`;

const DialogContainer = styled.div`
    width: 400px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Footer = styled.div`
    width: 100%;
    margin: 30px 0 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-evenly;
`;

export const ErrorText = styled.p`
    margin: 0;
    padding: 0;
    font-size: .8rem;
    text-align: right;
    color: red;
`;

export default function Teacher(props) {
    const { user } = useUser();
    var moment = require('moment');
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors, isValid }
    } = useForm({ mode: "all", reValidateMode: "all" });
    const [teacher, setTeacher] = useState([]);
    const [openDialogue, setOpenDialogue] = useState(false)
    const [openChangeDepartmentDialogue, setOpenChangeDepartmentDialogue] = useState({ openStatus: false, currentDepartmentID: null, teacherId: null })
    const [newTeacherName, setNewTeacherName] = useState('')
    const [newTeacherDept, setNewTeacherDept] = useState(null)
    const [departments, setDepartments] = useState([])
    const [open, setOpen] = useState(false);
    const [changeTeacherOpen, setChangeTeacherOpen] = useState(false);
    const loading = (open || changeTeacherOpen) && departments.length === 0;

    const exportPdf = (PrintData, Heading, FileName) => {
        const doc = new jsPDF()
        doc.autoTable({
            margin: { top: 82 },
            didDrawPage: (data) => {
                var currentPageNo = doc.internal.getCurrentPageInfo().pageNumber;
                var str = 'Page ' + currentPageNo;
                data.settings.margin.top = 10; // Reseting top margin. The change will be reflected only after print the first page.
                if (currentPageNo === 1) {
                    // doc.addImage(base64Img, 'PNG', 85, 15, 40, 10);
                    doc.setFontSize(15);
                    doc.text(Heading, 20, 40);
                    doc.setFontSize(12);
                    doc.text(`Generated By: ${user.username} (Admin)`, 20, 46);
                    doc.text(`Generated At : ${new Date()}`, 20, 52);
                }
                doc.setFontSize(10);
                var pageSize = doc.internal.pageSize;
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                doc.text(str, data.settings.margin.left, pageHeight - 10);
            },
            ...PrintData
        });
        doc.save(`${FileName}.pdf`)
    }

    const fetchTeacher = async () => {
        let res = await fetch(`${process.env.REACT_APP_API}/admin/teacher`, {
            credentials: 'include',
        });
        res = await res.json();
        res = res.map(({ Teacher_id: id, ...rest }) => ({ id, ...rest }));
        // res.forEach((item, i) => { item.id = i + 1; });

        setTeacher(res)
    };

    const cellDoubleClick = async (params) => {
        if (params.field == 'Teacher_status') {
            if (window.confirm("Press ok to change status")) {
                let res = await fetch(`${process.env.REACT_APP_API}/admin/teacher/updateStatus`, {
                    method: "POST",
                    headers: { Accept: 'application/json', "Content-Type": "application/json", },
                    credentials: 'include',
                    body: JSON.stringify(params.row),
                });
                fetchTeacher()
            }
        } else if (params.field == 'Dept_Name') {
            setOpenChangeDepartmentDialogue({ openStatus: true, currentDepartmentID: params.row.Dept_Id, teacherId: params.row.id })
        } else {
            return;
        }
    }

    const changeTeacherDetails = async (params, event, details) => {
        let res = await fetch(`${process.env.REACT_APP_API}/admin/teacher/changeTeacherDetails`, {
            method: "POST",
            headers: { Accept: 'application/json', "Content-Type": "application/json", },
            credentials: 'include',
            body: JSON.stringify(params),
        });
        fetchTeacher()
    }

    const addTeacher = async (data) => {
        let result = await fetch(`${process.env.REACT_APP_API}/admin/teacher/addTeacher`, {
            method: "POST",
            headers: { Accept: 'application/json', "Content-Type": "application/json", },
            credentials: 'include',
            body: JSON.stringify(data),
        });
        const res = await result.json();
        console.log('returned object is ', (res));
        setOpenDialogue(false)
        fetchTeacher()
    };

    const updateTeacherDepartment = async () => {
        if (newTeacherDept === null) {
            return;
        }
        let res = await fetch(`${process.env.REACT_APP_API}/admin/teacher/updateTeacherDepartment`, {
            method: "POST",
            headers: { Accept: 'application/json', "Content-Type": "application/json", },
            credentials: 'include',
            body: JSON.stringify({ departmentId: newTeacherDept.Dept_Id, teacherId: openChangeDepartmentDialogue.teacherId }),
        });
        if (res.status === 200) {
            fetchTeacher()
        } else {
            alert(res.status, 'cannot update teacher department')
        }
        setOpenChangeDepartmentDialogue({ openStatus: false, currentDepartmentID: null, teacherId: null })
        setNewTeacherDept(null)
    }

    useEffect(() => {
        fetchTeacher()
    }, []);

    // Fetching departments when autocomplete is opened
    useEffect(async () => {
        if (open) {
            let res = await fetch(`${process.env.REACT_APP_API}/admin/department`, {
                credentials: 'include',
            });
            res = await res.json();
            setDepartments(res)
        }
    }, [open]);

    // Fetching departments when autocomplete is opened
    useEffect(async () => {
        if (changeTeacherOpen) {
            let res = await fetch(`${process.env.REACT_APP_API}/admin/department`, {
                credentials: 'include',
            });
            res = await res.json();
            setDepartments(res)
        }
    }, [changeTeacherOpen]);

    const getFilteredRows = ({ apiRef }) => gridVisibleSortedRowIdsSelector(apiRef);

    const CustomToolbar = (props) => {
        const apiRef = useGridApiContext();

        const CSVToJSON = csv => {
            const lines = csv.split('\n');
            // Removing '\r'
            for (var i = 0; i < lines.length; i++) { lines[i] = lines[i].replace('\r', ''); }
            const keys = lines[0].split(',');
            return lines.slice(1).map(line => {
                return line.split(',').reduce((acc, cur, i) => {
                    const toAdd = {};
                    toAdd[keys[i]] = cur;
                    return { ...acc, ...toAdd };
                }, {});
            });
        };

        const handleExport = (options) => {
            if (options.type === 'pdf') {
                exportPdf({
                    body: CSVToJSON(apiRef.current.getDataAsCsv(options)),
                    columns: [
                        { header: 'Teacher ID', dataKey: 'ID' },
                        { header: 'Teacher Email', dataKey: 'Teacher Email' },
                        { header: 'Teacher Name', dataKey: 'Teacher Name' },
                        { header: 'Contact Number', dataKey: 'Contact Number' },
                        { header: 'Join Date', dataKey: 'Join Date' },
                        { header: `Status`, dataKey: `User Status` },
                    ]
                }, 'Teacher Details', 'Teacher Details');
            } else if (options.type === 'csv') {
                apiRef.current.exportDataAsCsv(options);
            }
        }

        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <Button
                    color={'primary'}
                    startIcon={<PrintIcon />}
                    onClick={() => handleExport({ getRowsToExport: getFilteredRows, type: 'pdf' })}
                >
                    Export pdf
                </Button>
                <Button
                    color={'primary'}
                    startIcon={<FileDownloadOutlinedIcon />}
                    onClick={() => handleExport({ getRowsToExport: getFilteredRows, type: 'csv' })}
                >
                    Export CSV
                </Button>
            </GridToolbarContainer>
        );
    };

    const columns = [
        { field: 'id', headerName: 'ID', type: 'number', headerAlign: 'left', align: "left", },
        { field: 'Username', headerName: 'Teacher Email', flex: 1 },
        { field: 'Teacher_name', headerName: 'Teacher Name', editable: true, flex: 1 },
        { field: 'Dept_Name', headerName: 'Department Name', flex: 1 },
        { field: 'Teacher_contactNo', headerName: 'Contact Number', editable: true, flex: 1 },
        {
            field: 'Teacher_join_date', headerName: 'Join Date', type: 'date', flex: 1,
            renderCell: (params) => {
                return `${(moment(params.value).format('DD/MM/YYYY'))}`
            }
        },
        {
            field: 'Teacher_status', headerName: 'User Status', flex: 1,
            renderCell: (params) => {
                if (params.value === 'active') {
                    return <Chip label={'Active'} variant="outlined" color="primary" icon={<CheckCircleOutlinedIcon />} />
                } else if (params.value === 'inactive') {
                    return <Chip label={'Inactive'} variant="outlined" color="error" icon={<DoNotDisturbOnOutlinedIcon />} />
                }
            }
        },
        {
            field: 'reset_password', headerName: 'Reset Password', width: 150, align: "center",
            renderCell: (params) => {
                return <IconButton color="error" onClick={async () => {
                    if (!window.confirm(`Do you really want to reset password of ${params.row.Username}`)) {
                        return;
                    }
                    let res = await fetch(`${process.env.REACT_APP_API}/admin/teacher/resetPassword`, {
                        method: "POST",
                        headers: { Accept: 'application/json', "Content-Type": "application/json", },
                        credentials: 'include',
                        body: JSON.stringify({ username: params.row.Username }),
                    });
                    if (res.status === 200) {
                        alert(`Successfully Reseted Password of ${params.row.Username}`)
                    } else {
                        console.error(res)
                    }
                }}>
                    <LockResetOutlinedIcon />
                </IconButton>
            }
        },
    ];

    return <TeacherWrapper>
        <HeaderText onClick={() => reset()}>Teacher Management</HeaderText>
        <CardsContainer>
            <MiniCard
                onClick={() => setOpenDialogue(true)}
            >
                <PersonAddAltOutlinedIcon fontSize="large" color="primary" />
                <MiniCardText>Add Teacher</MiniCardText>
            </MiniCard>
        </CardsContainer>
        <DataGridContainer>
            <DataGrid
                rows={teacher}
                columns={columns}
                sx={{ height: '82vh', maxHeight: '82vh' }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'Teacher_join_date', sort: 'desc' }],
                    },
                }}
                // autoHeight={true}
                components={{ Toolbar: CustomToolbar }}
                disableSelectionOnClick
                onCellDoubleClick={cellDoubleClick}
                onCellEditCommit={changeTeacherDetails}
            ></DataGrid>
        </DataGridContainer>
        <Dialog open={openDialogue}
            PaperProps={{
                style: {
                    display: 'flex',
                    width: 'fit-content',
                    overflow: 'visible',
                    boxShadow: 'none',
                    borderRadius: '1rem'
                },
            }}>
            <DialogContainer>
                <HeaderText onClick={() => console.log(!!teacher.find(o => o.Username === 'gf@dfg.fhg'))}>Add Teacher</HeaderText>
                <TextField
                    name="name"
                    label="Name *"
                    fullWidth
                    sx={{ margin: '0 0 1rem' }}
                    error={errors.name}
                    helperText={errors.name && errors.name?.message}
                    {...register("name", {
                        required: "Please Enter your Full Name"
                    })}
                />
                <TextField
                    name="email"
                    label="E-mail *"
                    fullWidth
                    error={errors.email}
                    helperText={errors.email && errors.email?.message}
                    {...register("email", {
                        required: "Please Enter E-mail ID",
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Invalid E-mail Address"
                        },
                        validate: (value) => (
                            !teacher.find(o => o.Username === value)
                        ) || "This Already Exists",


                    })}

                />
                <Autocomplete
                    name="department"
                    sx={{ width: '100%', margin: '1rem 0' }}
                    disableClearable
                    options={departments.filter((e) => e.Dept_Status == 'active')}
                    onOpen={() => { setOpen(true); }}
                    onClose={() => { setOpen(false); }}
                    getOptionLabel={(option) => option.Dept_Name}
                    // filterOptions={(x) => console.log(x[0]?.Dept_Status) && true}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Department"
                            variant="outlined"
                            fullWidth
                            error={errors.department}
                            helperText={errors.department && errors.department?.message}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                            {...register("department", { required: "Please Select your Department" })}
                        />}
                    onChange={(event, value) => setValue('department', value.Dept_Name)}
                />
                <TextField
                    name="phoneNo"
                    label="Phone Number"
                    fullWidth
                    error={errors.phoneNo}
                    helperText={errors.phoneNo && errors.phoneNo?.message}
                    {...register("phoneNo", {
                        required: "Please Enter the Phone Number",
                        // minLength: { 
                        //     value: 10, 
                        //     message: "Please Enter 10 digit Number"
                        // }, 
                        pattern: {
                            value: /^[0-9]{10}$/i,
                            message: "Only numbers are Allowed"
                        },
                        maxLength: {
                            value: 10,
                            message: "Phone Number Should not Exede 10 digits"
                        },
                    })}

                />
                <Footer>
                    <Button
                        variant="contained"
                        startIcon={<PersonAddAltOutlinedIcon />}
                        disabled={!isValid}
                        onClick={handleSubmit(addTeacher)}
                    >Add</Button>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setNewTeacherName("")
                            reset({
                                name: '',
                                email: '',
                                department: '',
                                phoneNo: '',
                            })
                            setOpenDialogue(false)
                        }}
                    >Cancel</Button>
                </Footer>
            </DialogContainer>
        </Dialog>
        <Dialog open={openChangeDepartmentDialogue.openStatus}
            PaperProps={{
                style: {
                    display: 'flex',
                    width: 'fit-content',
                    overflow: 'visible',
                    boxShadow: 'none',
                    borderRadius: '1rem'
                },
            }}>
            <DialogContainer>
                <HeaderText onClick={() => console.log(teacher.find('gf@dfg.fhg'))}>Change Department</HeaderText>
                <Autocomplete
                    name="department"
                    sx={{ width: '100%', margin: '1rem 0' }}
                    disableClearable
                    options={departments.filter((e) => e.Dept_Status == 'active' && e.Dept_Id != openChangeDepartmentDialogue?.currentDepartmentID)}
                    onOpen={() => { setChangeTeacherOpen(true); }}
                    onClose={() => { setChangeTeacherOpen(false); }}
                    getOptionLabel={(option) => option.Dept_Name}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Department"
                            variant="outlined"
                            fullWidth
                            error={newTeacherDept === null}
                            helperText={newTeacherDept === null && "Please Select a Department"}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />}
                    onChange={(event, value) => setNewTeacherDept(value)}
                />
                <Footer>
                    <Button
                        variant="contained"
                        startIcon={<PersonAddAltOutlinedIcon />}
                        disabled={newTeacherDept === null}
                        onClick={updateTeacherDepartment}
                    >Re-allocate</Button>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setOpenChangeDepartmentDialogue({ openStatus: false, currentDepartmentID: null, teacherId: null })
                            setNewTeacherDept(null)
                        }}
                    >Cancel</Button>
                </Footer>
            </DialogContainer>
        </Dialog>
    </TeacherWrapper>;
}
