import React, { useState } from 'react'
import styled from 'styled-components'
import { Navbar } from './navbar'
import Admin from './admin' 
import Teacher from './teacher' 
import Exam from './exam' 
import Reliever from './reliever' 
import Report from './report' 
import SpecialDuties from './specialDuties'
import Venue from './venue' 
import Department  from './department'
import Help  from './help'

const AdminWrapper = styled.div`
	height: 100vh;
	width: 100vw;
	margin: 0;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	background-color: white;
`;

export function AdminPannel(props) {
    document.title = "Admin Panel"
	
    const [menu, setMenu] = useState('exam');

	return (
		<AdminWrapper>
			{menu == 'admin' && <Admin />}
			{menu == 'teacher' && <Teacher />}
			{menu == 'exam' && <Exam />}
			{menu == 'reliever' && <Reliever />}
			{menu == 'report' && <Report />}
			{menu == 'specialDuties' && <SpecialDuties />}
			{menu == 'venue' && <Venue />}
			{menu == 'department' && <Department />}
			{menu == 'help' && <Help />}

			<Navbar  menu={menu} setMenu={setMenu}/>
		</AdminWrapper>
	)
}