import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    gridVisibleSortedRowIdsSelector,
    useGridApiContext
} from '@mui/x-data-grid';
import useUser from '../../../hooks/useUser';
import { ReactComponent as YourSvg } from '../../../assets/images/done.svg';
import jsPDF from 'jspdf';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import PrintIcon from '@mui/icons-material/Print';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined';

const HistoryWrapper = styled.div`
    width: 100%;
    margin-top: 1rem;
    box-sizing: border-box;
`;

const DutyCountContainer = styled.div`
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid rgba(224, 224, 224, 1);
`;

const CountRangeContainer = styled.div`
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
`;

const TableHeader = styled.h2`
	font-size: 23px;
	font-weight: 600;
	text-align: center;
    padding: .5rem 1rem;
	color: #545454;
	margin: 1rem 0 0rem;
`;

export default function DutyHistory(props) {
    var moment = require('moment');
    const { user } = useUser();
    const [examDuties, setExamDuties] = useState([]);
    const [relieverDuties, setRelieverDuties] = useState([]);
    const [specialDuties, setSpecialDuties] = useState([]);
    const [startDate, setStartDate] = useState({ error: false, date: moment().format('YYYY/MM/DD') });
    const [endDate, setEndDate] = useState({ error: false, date: moment().format('YYYY/MM/DD') });

    const exportPdf = (PrintData, Heading, FileName) => {
        const doc = new jsPDF()
        doc.autoTable({
            margin: { top: 82 },
            didDrawPage: (data) => {
                var currentPageNo = doc.internal.getCurrentPageInfo().pageNumber;
                var str = 'Page ' + currentPageNo;
                data.settings.margin.top = 10; // Reseting top margin. The change will be reflected only after print the first page.
                if (currentPageNo === 1) {
                    // doc.addImage(base64Img, 'PNG', 85, 15, 40, 10);
                    doc.setFontSize(15);
                    doc.text(Heading, 20, 40);
                    doc.setFontSize(12);
                    doc.text(`Generated By: ${user.username} (Teacher)`, 20, 46);
                    doc.text(`Generated At : ${new Date()}`, 20, 52);
                }
                doc.setFontSize(10);
                var pageSize = doc.internal.pageSize;
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                doc.text(str, data.settings.margin.left, pageHeight - 10);
            },
            ...PrintData
        });
        doc.save(`${FileName}.pdf`)
    }

    const getAllDuties = async () => {
        let res = await fetch(`${process.env.REACT_APP_API}/teacher/dashboard/getAllDuties`, {
            method: "POST",
            headers: { Accept: 'application/json', "Content-Type": "application/json", },
            credentials: 'include',
            body: JSON.stringify({ username: user.username }),
        });
        res = await res.json();
        // Adding serial No. or ID
        res[0] = res[0].map((value, index) => ({ id: (index + 1), ETA: moment(value.Exam_date).fromNow(), Date: value.Exam_date.split(' ')[0], ...value }));
        res[1] = res[1].map((value, index) => ({ id: (index + 1), Date: value.Start_Time.split(' ')[0], Timing: `${value.Start_Time.split(' ')[1]} - ${value.End_Time.split(' ')[1]}`, ETA: moment(value.Start_Time, 'YYYY/MM/DD HH:mm').fromNow(), ...value }));
        res[2] = res[2].map((value, index) => ({ id: (index + 1), ETA: moment(value.Special_Duty_Date, 'YYYY/MM/DD HH:mm').fromNow(), ...value }));
        setExamDuties(res[0]);
        setRelieverDuties(res[1]);
        setSpecialDuties(res[2]);
    }

    useEffect(() => {
        getAllDuties()

        // Calculating start date(Starting of academic calendar)
        setStartDate({ error: false, date: `${moment().subtract((moment().format('MM') <= 5 ? 1 : 0), 'years').format('YYYY')}/06/01` });
    }, [])


    const getFilteredRows = ({ apiRef }) => gridVisibleSortedRowIdsSelector(apiRef);

    const CustomToolbar = (props) => {
        const apiRef = useGridApiContext();

        const CSVToJSON = csv => {
            const lines = csv.split('\n');
            // Removing '\r'
            for (var i = 0; i < lines.length; i++) { lines[i] = lines[i].replace('\r', ''); }
            const keys = lines[0].split(',');
            return lines.slice(1).map(line => {
                return line.split(',').reduce((acc, cur, i) => {
                    const toAdd = {};
                    toAdd[keys[i]] = cur;
                    return { ...acc, ...toAdd };
                }, {});
            });
        };

        const handleExport = (options) => {
            if (options.type === 'pdf') {
                if (props.tableType === 'examDuty') {
                    exportPdf({
                        body: CSVToJSON(apiRef.current.getDataAsCsv(options)),
                        columns: [
                            { header: 'Sl No', dataKey: 'Sl No' },
                            { header: 'Date', dataKey: 'Date' },
                            { header: 'ETA / Completed', dataKey: 'ETA / Completed' },
                            { header: 'Venue Name', dataKey: 'Venue Name' },
                            { header: 'Timing', dataKey: 'Timing' },
                            { header: `Duty ID`, dataKey: `Duty ID` },
                        ]
                    }, 'Exam Duty Allocation', 'Duty History');
                } else if (props.tableType === 'relieverDuty') {
                    exportPdf({
                        body: CSVToJSON(apiRef.current.getDataAsCsv(options)),
                        columns: [
                            { header: 'Sl No', dataKey: 'Sl No' },
                            { header: 'Date', dataKey: 'Date' },
                            { header: 'Timing', dataKey: 'Timing' },
                            { header: 'ETA / Completed', dataKey: 'ETA / Completed' },
                            { header: 'Venue Name', dataKey: 'Venue Name' },
                            { header: 'Teacher Name', dataKey: 'Teacher Name' },
                            { header: `Reliever ID`, dataKey: `Reliever ID` },
                        ]
                    }, 'Reliever Duty Allocation', 'Reliever Allocation');

                } else if (props.tableType === 'specialDuty') {
                    exportPdf({
                        body: CSVToJSON(apiRef.current.getDataAsCsv(options)),
                        columns: [
                            { header: 'Sl No', dataKey: 'Sl No' },
                            { header: 'Date', dataKey: 'Date' },
                            { header: 'ETA / Completed', dataKey: 'ETA / Completed' },
                            { header: 'Duty Type', dataKey: 'Duty Type' },
                            { header: 'Duty', dataKey: 'Duty' },
                        ]
                    }, 'Special Duty History', 'Special Duty History');

                }
            } else if (options.type === 'csv') {
                apiRef.current.exportDataAsCsv(options);
            }
        }

        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <Button
                    color={'primary'}
                    startIcon={<PrintIcon />}
                    onClick={() => handleExport({ getRowsToExport: getFilteredRows, type: 'pdf' })}
                >
                    Export pdf
                </Button>
                <Button
                    color={'primary'}
                    startIcon={<FileDownloadOutlinedIcon />}
                    onClick={() => handleExport({ getRowsToExport: getFilteredRows, type: 'csv' })}
                >
                    Export CSV
                </Button>
            </GridToolbarContainer>
        );
    };

    const examDutyColumns = [
        { field: 'id', headerName: 'Sl No', width: 100 },
        { field: 'Date', headerName: 'Date', type: 'date', flex: 1, },
        { field: 'ETA', headerName: 'ETA / Completed', type: 'date', flex: 1, },
        {
            field: 'Venue_name', headerName: 'Venue Name', flex: 1,
            renderCell: (params) => {
                return <Chip label={params.row.Venue_name} variant="outlined" color="primary" icon={<StoreOutlinedIcon />} />
            }
        },
        {
            field: 'Slot_startTime', headerName: 'Timing', flex: 1,
            renderCell: (params) => {
                return `${params.row.Slot_startTime} - ${params.row.Slot_endTime}`
            }
        },
        { field: 'Duty_id', headerName: 'Duty ID', type: 'number', headerAlign: 'left', align: "left", flex: 1 },
    ];

    const relieverDutyColumns = [
        { field: 'id', headerName: 'Sl No', width: 100 },
        { field: 'Date', headerName: 'Date', type: 'date', flex: 1, },
        { field: 'Timing', headerName: 'Timing', flex: 1, },
        { field: 'ETA', headerName: 'ETA / Completed', type: 'date', flex: 1, },
        {
            field: 'Venue_name', headerName: 'Venue Name', flex: 1,
            renderCell: (params) => {
                return <Chip label={params.row.Venue_name} variant="outlined" color="primary" icon={<StoreOutlinedIcon />} />
            }
        },
        { field: 'relievingTeacherName', headerName: 'Teacher Name', flex: 1 },
        { field: 'Reliever_Allocation_id', headerName: 'Reliever ID', type: 'number', headerAlign: 'left', align: "left", flex: 1 },
    ];

    const specialDutyColumns = [
        { field: 'id', headerName: 'Sl No', width: 100 },
        { field: 'Special_Duty_Date', headerName: 'Date', type: 'date', flex: 1, },
        { field: 'ETA', headerName: 'ETA / Completed', type: 'date', flex: 1, },
        { field: 'Duty_Type_Name', headerName: 'Duty Type', flex: 1 },
        { field: 'No_Of_Duties', headerName: 'Duty', type: 'number', headerAlign: 'left', align: "left", flex: 1 },
    ];

    return <HistoryWrapper>
        <DutyCountContainer>
            <YourSvg width="8%" height="100%" />
            {/* Total duty is sum of exam duties and reliever duties */}
            <Typography variant="h1" component="div" ml={3} color="text.primary">{examDuties.filter(examDuty => examDuty.Date >= startDate.date && examDuty.Date <= endDate.date).length + relieverDuties.filter(relieverDuty => relieverDuty.Date >= startDate.date && relieverDuty.Date <= endDate.date).length}</Typography>
            <Typography variant="h4" component="div" ml={3} color="text.primary">Duties Taken</Typography>
            <CountRangeContainer>
                <TextField
                    focused
                    label="From"
                    sx={{ width: "160px" }}
                    value={startDate.date}
                    error={startDate.error}
                    onChange={(e) => {
                        setStartDate({ error: !moment(e.target.value, 'YYYY/MM/DD').isValid(), date: e.target.value })
                    }}
                />
                <SwapHorizOutlinedIcon sx={{ m: 1.2, color: '#005ae6' }} />
                <TextField
                    focused
                    label="To"
                    sx={{ width: "160px" }}
                    value={endDate.date}
                    error={endDate.error}
                    onChange={(e) => {
                        setEndDate({ error: !moment(e.target.value, 'YYYY/MM/DD').isValid(), date: e.target.value })
                    }}
                />
                <Tooltip title="Reset Date Range" placement="top" sx={{ width: 'fit-content', ml: 1, }}>
                    <IconButton
                        aria-label="add to shopping cart"
                            onClick={() => {
                                // Reseting start date(Starting of academic calendar) and end date
                                setStartDate({ error: false, date: `${moment().subtract((moment().format('MM') <= 5 ? 1 : 0), 'years').format('YYYY')}/06/01` });
                                setEndDate({ error: false, date: moment().format('YYYY/MM/DD') });
                        }}>
                        <EventRepeatOutlinedIcon sx={{ color: '#1976d2', }} />
                    </IconButton>
                </Tooltip>
            </CountRangeContainer>
        </DutyCountContainer>
        <TableHeader>Exam Duty History</TableHeader>
        <DataGrid
            rows={examDuties}
            columns={examDutyColumns}
            autoHeight={true}
            initialState={{
                pagination: {
                    pageSize: 5,
                },
            }}
            rowsPerPageOptions={[5, 15, 25]}
            components={{ Toolbar: CustomToolbar }}
            componentsProps={{ toolbar: { tableType: 'examDuty' } }}
            disableSelectionOnClick
        ></DataGrid >
        <TableHeader>Reliever Duty History</TableHeader>
        <DataGrid
            rows={relieverDuties}
            columns={relieverDutyColumns}
            autoHeight={true}
            initialState={{
                pagination: {
                    pageSize: 5,
                },
            }}
            rowsPerPageOptions={[5, 15, 25]}
            components={{ Toolbar: CustomToolbar }}
            componentsProps={{ toolbar: { tableType: 'relieverDuty' } }}
            disableSelectionOnClick

        ></DataGrid >
        <TableHeader>Special Duty History</TableHeader>
        <DataGrid
            rows={specialDuties}
            columns={specialDutyColumns}
            autoHeight={true}
            initialState={{
                pagination: {
                    pageSize: 5,
                },
            }}
            rowsPerPageOptions={[5, 15, 25]}
            components={{ Toolbar: CustomToolbar }}
            componentsProps={{ toolbar: { tableType: 'specialDuty' } }}
            disableSelectionOnClick

        ></DataGrid >
    </HistoryWrapper >
}