import React, { useEffect, useState } from 'react';
import PageNotFound from '../components/404PageNotFound';

const UserContext = React.createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const getUser = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API}/me`, {
        credentials: 'include',
      });
      const data = await res.json();
      console.log("🚀 ~ file: userContext.js:15 ~ getUser ~ data", data)
      setUser(data);
    } catch (error) {
      console.log('error', error);
      alert('something went wrong, please try again later');
    }
  };
  
  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      {user ? (
        <UserContext.Provider value={{ user, setUser }}>
          {children}
        </UserContext.Provider>
      ) : (
        <PageNotFound>...</PageNotFound>
      )}
    </>
  );
};

export { UserContext, UserProvider };

