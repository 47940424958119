import React from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import Img from '../assets/lottie/404-page.json';

const PageWrapper = styled.div`
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default function PageNotFound(props) {
    return <PageWrapper>
        <Lottie
            options={{
                loop: true,
                autoplay: true,
                animationData: Img,
                rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                }
            }}
            height={'100vh'}
            width={'100vw'}
        />
    </PageWrapper>
}
