import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import useUser from '../../../hooks/useUser';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { HeaderText, CardsContainer, MiniCard, MiniCardText, DataGridContainer } from "../common";
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import AddModeratorOutlinedIcon from '@mui/icons-material/AddModeratorOutlined';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';

const AdminWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 100px;
    overflow: auto;
    scrollbar-width: none;
    /* background-color: pink; */
`;

const AddAdminContainer = styled.div`
    width: 300px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Footer = styled.div`
    width: 100%;
    margin: 30px 0 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-evenly;
`;

export const ErrorText = styled.p`
    margin: 0;
    padding: 0;
    font-size: .8rem;
    text-align: right;
    color: red;
`;

export default function Admin(props) {
    const { user, } = useUser();
    const [admin, setAdmin] = useState([]);
    const [newPassword, setNewPassword] = useState('')
    const [openDialogue, setOpenDialogue] = useState(false)
    const [newAdminName, setNewAdminName] = useState('')
    const [isValidName, setIsValidName] = useState(true)
    const [errorMsg, setErrorMsg] = useState("")

    const fetchAdmin = async () => {
        let res = await fetch(`${process.env.REACT_APP_API}/admin/admin`, {
            credentials: 'include',
        });
        res = await res.json();
        res.forEach((item, i) => { item.id = i + 1; });
        // console.log('res', res)

        setAdmin(res)
    };

    const cellDoubleClick = async (params) => {

        if (params.field != 'User_status' || !window.confirm("Press ok to change status")) {
            return;
        }
        let res = await fetch(`${process.env.REACT_APP_API}/admin/admi`, {
            method: "POST",
            headers: { Accept: 'application/json', "Content-Type": "application/json", },
            credentials: 'include',
            body: JSON.stringify(params.row),
        });
        fetchAdmin()
    }

    const addAdmin = async () => {
        let result = await fetch(`${process.env.REACT_APP_API}/admin/admin/addAdmin`, {
            method: "POST",
            headers: { Accept: 'application/json', "Content-Type": "application/json", },
            credentials: 'include',
            body: JSON.stringify({ newAdminName: newAdminName, }),
        });
        const res = await result.json();
        console.log('returned object is ', (res));
        if (res.error) {
            setIsValidName(false)
            setErrorMsg(res.message)
        }
        else {

            setOpenDialogue(false)
        }
        fetchAdmin()
    };

    function validateAdmin() {
        if (newAdminName == "") {
            setIsValidName(true);
            return true
        }
        for (const key in admin) {
            if (admin[key].Username.toUpperCase() == newAdminName.toUpperCase()) {
                setIsValidName(false);
                setErrorMsg("Admin already exists");
                return;
            }

        }
        if (!newAdminName.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)) {
            setIsValidName(false);
            setErrorMsg("Invalid email");
            return;
        }
        setErrorMsg("");
        setIsValidName(true);
    }

    useEffect(() => {
        fetchAdmin()
    }, []);

    useEffect(() => {
        validateAdmin()
    }, [newAdminName]);

    const columns = [
        { field: 'id', headerName: 'ID', width: 150 },
        { field: 'Username', headerName: 'User Name', width: 150 },
        {
            field: 'User_status', headerName: 'User Status', width: 150,
            renderCell: (params) => {
                if (params.value === 'active') {
                    return <Chip label={'Active'} variant="outlined" color="primary" icon={<CheckCircleOutlinedIcon />} />
                } else if (params.value === 'inactive') {
                    return <Chip label={'Inactive'} variant="outlined" color="error" icon={<DoNotDisturbOnOutlinedIcon />} />
                }
            }
        },
        {
            field: 'reset_password', headerName: 'Reset Password', width: 230,
            renderCell: (params) => {
                return (params.row.Username === user.username ?
                    <TextField
                        label="New Password"
                        size="small"
                        value={newPassword}
                        onChange={(event) => { setNewPassword(event.target.value) }}
                        InputProps={{
                            endAdornment: <IconButton
                                color="error"
                                disabled={newPassword.length <= 0}
                                onClick={async (event) => {
                                    if (!window.confirm("Press Ok To Update Your Password")) {
                                        return;
                                    }
                                    let res = await fetch(`${process.env.REACT_APP_API}/admin/admin/updateAdminPassword`, {
                                        method: "POST",
                                        headers: { Accept: 'application/json', "Content-Type": "application/json", },
                                        credentials: 'include',
                                        body: JSON.stringify({ username: params.row.Username, newPassword: newPassword }),
                                    });
                                    if (res.status === 200) {
                                        setNewPassword('')
                                        alert(`Successfully Reseted Your Password`)
                                    } else {
                                        console.error(res)
                                    }
                                }}>
                                <LockResetOutlinedIcon />
                            </IconButton>
                        }}
                    />
                    :
                    <IconButton color="error" onClick={async () => {
                        if (!window.confirm(`Do you really want to reset password of ${params.row.Username}`)) {
                            return;
                        }

                        let res = await fetch(`${process.env.REACT_APP_API}/admin/admin/resetPassword`, {
                            method: "POST",
                            headers: { Accept: 'application/json', "Content-Type": "application/json", },
                            credentials: 'include',
                            body: JSON.stringify({ username: params.row.Username }),
                        });
                        if (res.status === 200) {
                            alert(`Successfully Reseted Password of ${params.row.Username}`)
                        } else {
                            console.error(res)
                        }
                    }}>
                        <LockResetOutlinedIcon />
                    </IconButton>
                )
            }
        },
    ];

    return <AdminWrapper>
        <HeaderText onClick={() => console.log(newAdminName.match('/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i'))}>Admin Management</HeaderText>
        <CardsContainer>
            <MiniCard
                onClick={() => setOpenDialogue(true)}
            >
                <AddModeratorOutlinedIcon fontSize="large" color="primary" />
                <MiniCardText>Add Admin</MiniCardText>
            </MiniCard>
        </CardsContainer>
        <DataGridContainer>
            <DataGrid
                rows={admin}
                columns={columns}
                sx={{ height: '82vh', maxHeight: '82vh' }}
                // autoHeight={true}
                components={{ Toolbar: GridToolbar }}
                disableSelectionOnClick
                onCellDoubleClick={cellDoubleClick}
            ></DataGrid>
        </DataGridContainer>
        <Dialog open={openDialogue}
            PaperProps={{
                style: {
                    display: 'flex',
                    width: 'fit-content',
                    overflow: 'visible',
                    boxShadow: 'none',
                    borderRadius: '1rem'
                },
            }}>
            <AddAdminContainer>
                <HeaderText onClick={() => console.log(!!newAdminName.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i))}>Add Admin</HeaderText>
                <TextField
                    required
                    id="outlined-required"
                    label="New Admin Email"
                    fullWidth
                    error={!isValidName}
                    helperText={!isValidName && errorMsg}
                    onChange={(event) => { setNewAdminName(event.target.value) }}

                />
                <Footer>
                    <Button
                        variant="contained"
                        startIcon={<AddModeratorOutlinedIcon />}
                        disabled={newAdminName == "" || !isValidName}
                        onClick={addAdmin}
                    >Add</Button>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setNewAdminName("")
                            setOpenDialogue(false)
                        }}
                    >Cancel</Button>
                </Footer>
            </AddAdminContainer>
        </Dialog>
    </AdminWrapper>;
}
