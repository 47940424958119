import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { HeaderText, CardsContainer, MiniCard, MiniCardText, DataGridContainer } from "../common";
import SpecialDutyManagement from "./specialDutyManagement";
import NewSpecialDuty from './newSpecialDuty';
import useUser from '../../../hooks/useUser';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    gridVisibleSortedRowIdsSelector,
    useGridApiContext
} from '@mui/x-data-grid';
import jsPDF from 'jspdf';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Chip from '@mui/material/Chip';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import PrintIcon from '@mui/icons-material/Print';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

const SpecialDutyWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-grow: 1;
    margin-bottom: 100px;
    padding: 10px;
    padding-right: 24px;
    box-sizing: border-box;
    border-radius: 1rem; 
    overflow: auto;
    scrollbar-width: thin;
    /* background-color: orange; */
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
`;

const FilterWrapper = styled.div`
    width: 280px;
    overflow: auto;
    scrollbar-width: thin;
    /* box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px; */ */
    /* background-color: orange; */
`;

const SelectOptionsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    /* box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px; */ */
`;

const ContentWrapper = styled.div`
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    /* margin-bottom: 100px; */
    /* padding: 10px; */
    padding-left: 1rem;
    box-sizing: border-box;
    overflow: auto;
    scrollbar-width: none;
    /* background-color: orange; */
`;

export default function SpecialDuties(props) {
    var moment = require('moment');
    const { user, setUser } = useUser();
    const [openDateRangeFilter, setOpenDateRangeFilter] = React.useState(false);
    const [openTeacherFilter, setOpenTeacherFilter] = React.useState(false);
    const [openExamTypeFilter, setOpenExamTypeFilter] = React.useState(false);
    const [startDate, setStartDate] = useState({ error: false, date: moment().format('YYYY/MM/DD') });
    const [endDate, setEndDate] = useState({ error: false, date: moment().format('YYYY/MM/DD') });
    const [allteachers, setAllTeachers] = useState([]);
    const [allDutyType, setAllDutyType] = useState([]);
    const [allSpecialDuties, setAllSpecialDuties] = useState([]);
    const [teacherSearchQuery, setTeacherSearchQuery] = React.useState('');
    const [dutyTypeSearchQuery, setDutyTypeSearchQuery] = React.useState('');
    const [openSpecialDutyManagementView, setSpecialDutyManagementView] = useState(false)

    const exportPdf = (PrintData, Heading, FileName) => {
        const doc = new jsPDF()
        doc.autoTable({
            margin: { top: 82 },
            didDrawPage: (data) => {
                var currentPageNo = doc.internal.getCurrentPageInfo().pageNumber;
                var str = 'Page ' + currentPageNo;
                data.settings.margin.top = 10; // Reseting top margin. The change will be reflected only after print the first page.
                if (currentPageNo === 1) {
                    // doc.addImage(base64Img, 'PNG', 85, 15, 40, 10);
                    doc.setFontSize(15);
                    doc.text(Heading, 20, 40);
                    doc.setFontSize(12);
                    doc.text(`Generated By: ${user.username} (Admin)`, 20, 46);
                    doc.text(`Generated At : ${new Date()}`, 20, 52);
                }
                doc.setFontSize(10);
                var pageSize = doc.internal.pageSize;
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                doc.text(str, data.settings.margin.left, pageHeight - 10);
            },
            ...PrintData
        });
        doc.save(`${FileName}.pdf`)
    }

    const fetchTeacher = async () => {
        let res = await fetch(`${process.env.REACT_APP_API}/admin/teacher`, {
            credentials: 'include',
        });
        res = await res.json();
        res = res.map((val) => ({ checked: true, ...val }));

        setAllTeachers(res)
    };

    const fetchSpecialDuties = async () => {
        let res = await fetch(`${process.env.REACT_APP_API}/admin/specialDuty`, {
            credentials: 'include',
        });
        res = await res.json();
        res = res.map(({ Special_Duty_Id: id, ...rest }) => ({ id, ...rest }));

        setAllSpecialDuties(res)
    };

    const fetchSpecialDutyTypes = async () => {
        let res = await fetch(`${process.env.REACT_APP_API}/admin/specialDuty/getAllSpecialDutyTypes`, {
            credentials: 'include',
        });
        res = await res.json();
        res = res.map((val) => ({ checked: true, ...val }));

        setAllDutyType(res)
    };

    const changeNoOfSpecialDuty = async (params, event, details) => {
        
        if (params.field != 'No_Of_Duties' || params?.value === undefined) {
            return;
        }

        let res = await fetch(`${process.env.REACT_APP_API}/admin/specialDuty/changeNoOfSpecialDuty`, {
            method: "POST",
            headers: { Accept: 'application/json', "Content-Type": "application/json", },
            credentials: 'include',
            body: JSON.stringify({ id: params.id, value: params.value }),
        });
        fetchSpecialDuties()
    }

    useEffect(() => {
        fetchTeacher()
        fetchSpecialDuties()
        fetchSpecialDutyTypes()

        // Calculating start date(Starting of academic calendar)
        setStartDate({ error: false, date: `${moment().subtract((moment().format('MM') <= 5 ? 1 : 0), 'years').format('YYYY')}/06/01` });
    }, []);

    const getFilteredRows = ({ apiRef }) => gridVisibleSortedRowIdsSelector(apiRef);

    const CustomToolbar = (props) => {
        const apiRef = useGridApiContext();

        const CSVToJSON = csv => {
            const lines = csv.split('\n');
            // Removing '\r'
            for (var i = 0; i < lines.length; i++) { lines[i] = lines[i].replace('\r', ''); }
            const keys = lines[0].split(',');
            return lines.slice(1).map(line => {
                return line.split(',').reduce((acc, cur, i) => {
                    const toAdd = {};
                    toAdd[keys[i]] = cur;
                    return { ...acc, ...toAdd };
                }, {});
            });
        };

        const handleExport = (options) => {
            if (options.type === 'pdf') {
                exportPdf({
                    body: CSVToJSON(apiRef.current.getDataAsCsv(options)),
                    columns: [
                        { header: 'ID', dataKey: 'ID' },
                        { header: 'Duty Type', dataKey: 'Duty Type' },
                        { header: `Date`, dataKey: `Date` },
                        { header: `Teacher Name`, dataKey: `Teacher Name` },
                        { header: `Department Name`, dataKey: `Department Name` },
                        { header: `No of Duties`, dataKey: `No of Duties` },
                        { header: `Status`, dataKey: `Status` },
                    ]
                }, 'Special Duties', 'Special Duties');
            } else if (options.type === 'csv') {
                apiRef.current.exportDataAsCsv(options);
            }
        }

        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <Button
                    color={'primary'}
                    startIcon={<PrintIcon />}
                    onClick={() => handleExport({ getRowsToExport: getFilteredRows, type: 'pdf' })}
                >
                    Export pdf
                </Button>
                <Button
                    color={'primary'}
                    startIcon={<FileDownloadOutlinedIcon />}
                    onClick={() => handleExport({ getRowsToExport: getFilteredRows, type: 'csv' })}
                >
                    Export CSV
                </Button>
            </GridToolbarContainer>
        );
    };

    const columns = [
        { field: 'id', headerName: 'ID', type: 'number', headerAlign: 'left', align: "left", width: 60 },
        { field: 'Duty_Type_Name', headerName: 'Duty Type', flex: 1, },
        { field: 'Special_Duty_Date', headerName: 'Date', flex: 1, },
        { field: 'Teacher_name', headerName: 'Teacher Name', flex: 1, },
        { field: 'Dept_Name', headerName: 'Department Name', flex: 1, },
        { field: 'No_Of_Duties', headerName: 'No of Duties', type: 'number', editable: true, flex: 1, },
        {
            field: 'Special_Duty_Status', headerName: 'Status', flex: 1,
            renderCell: (params) => {
                if (params.value === 'active') {
                    return <Chip label={'Active'} variant="outlined" color="primary" icon={<CheckCircleOutlinedIcon />} />
                } else if (params.value === 'inactive') {
                    return <Chip label={'Inactive'} variant="outlined" color="error" icon={<DoNotDisturbOnOutlinedIcon />} />
                }
            }
        },
    ];

    return <SpecialDutyWrapper>
        <FilterWrapper>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }} >
                <ListItem>
                    <ListItemAvatar>
                        <Avatar sx={{ bgcolor: '#005ae6' }}>
                            <TuneOutlinedIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Filters" />
                </ListItem>

                <ListItemButton onClick={() => setOpenDateRangeFilter(!openDateRangeFilter)}>
                    <ListItemIcon>
                        <DateRangeOutlinedIcon sx={{ color: '#1976d2', }} />
                    </ListItemIcon>
                    <ListItemText primary="Date Range" />
                    {openDateRangeFilter ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openDateRangeFilter} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ p: 2, pl: 4 }}>
                        <TextField
                            focused
                            label="From"
                            fullWidth
                            size="small"
                            value={startDate.date}
                            error={startDate.error}
                            onChange={(e) => {
                                setStartDate({ error: !moment(e.target.value, 'YYYY/MM/DD').isValid(), date: e.target.value })
                            }}
                        />
                        <TextField
                            focused
                            label="To"
                            fullWidth
                            size="small"
                            sx={{ mt: 2 }}
                            value={endDate.date}
                            error={endDate.error}
                            onChange={(e) => {
                                setEndDate({ error: !moment(e.target.value, 'YYYY/MM/DD').isValid(), date: e.target.value })
                            }}
                        />
                        <Button
                            variant="contained"
                            fullWidth
                            sx={{ mt: 2 }}
                            startIcon={<EventRepeatOutlinedIcon />}
                            onClick={() => {
                                // Reseting start date(Starting of academic calendar) and end date
                                setStartDate({ error: false, date: `${moment().subtract((moment().format('MM') <= 5 ? 1 : 0), 'years').format('YYYY')}/06/01` });
                                setEndDate({ error: false, date: moment().format('YYYY/MM/DD') });
                            }}
                        >Reset</Button>
                    </List>
                </Collapse>
                <Divider />

                <ListItemButton onClick={() => setOpenTeacherFilter(!openTeacherFilter)}>
                    <ListItemIcon>
                        <GroupsOutlinedIcon sx={{ color: '#1976d2', }} />
                    </ListItemIcon>
                    <ListItemText primary="Teachers" />
                    {openTeacherFilter ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openTeacherFilter} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ p: 2, pl: 4, pt: 0, pb: 0 }}>
                        <SelectOptionsContainer>
                            <Tooltip title="Select All" placement="top" >
                                <span>
                                    <IconButton>
                                        <CheckBoxOutlinedIcon
                                            sx={{ color: '#1976d2', }}
                                            onClick={() => {
                                                setAllTeachers(prevValue => [...prevValue].map(el => ({ ...el, checked: true })))
                                            }}
                                        />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip title="Deselect All" placement="top" >
                                <span>
                                    <IconButton>
                                        <IndeterminateCheckBoxOutlinedIcon
                                            sx={{ color: '#1976d2', }}
                                            onClick={() => {
                                                setAllTeachers(prevValue => [...prevValue].map(el => ({ ...el, checked: false })))
                                            }}
                                        />
                                    </IconButton>
                                </span>
                            </Tooltip>

                        </SelectOptionsContainer>
                        <TextField
                            focused
                            label="Search Teacher"
                            fullWidth
                            size="small"
                            sx={{ mt: 1 }}
                            value={teacherSearchQuery}
                            onChange={(e) => {
                                setTeacherSearchQuery(e.target.value)
                            }}
                        />
                        {/* Lists teachers which matches with teacher name in search box */}
                        {allteachers.filter(n => n.Teacher_name.toLowerCase().includes(teacherSearchQuery.toLowerCase())).map((teacher, index) => {
                            return <ListItem
                                key={teacher.Teacher_id}
                                disablePadding

                            >
                                <ListItemButton
                                    id={teacher.Teacher_id}
                                    role={undefined}
                                    sx={{ p: 1, display: 'flex', justifyContent: 'flex-start', height: '60px' }}
                                    onClick={(e) => {
                                        setAllTeachers(prevValue =>
                                            [...prevValue].map(el =>
                                                el.Teacher_id === teacher.Teacher_id ? ({ ...el, checked: !el.checked }) : el)
                                        )
                                    }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            sx={{ m: 0, }}
                                            edge="start"
                                            checked={teacher.checked}
                                            tabIndex={-1}
                                            key={teacher.Teacher_id}
                                            // disableRipple
                                            inputProps={{ 'aria-labelledby': 1 }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={teacher.Teacher_id} primary={teacher.Teacher_name} secondary={teacher.Dept_Name} />
                                </ListItemButton>
                            </ListItem>
                        })}
                    </List>
                </Collapse>
                <Divider />

                <ListItemButton onClick={() => setOpenExamTypeFilter(!openExamTypeFilter)}>
                    <ListItemIcon>
                        <BallotOutlinedIcon sx={{ color: '#1976d2', }} />
                    </ListItemIcon>
                    <ListItemText primary="Duty Type" />
                    {openExamTypeFilter ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openExamTypeFilter} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ p: 2, pl: 4, pt: 0, pb: 0 }} >
                        <SelectOptionsContainer>
                            <Tooltip title="Select All" placement="top" >
                                <IconButton>
                                    <CheckBoxOutlinedIcon
                                        sx={{ color: '#1976d2', }}
                                        onClick={() => {
                                            setAllDutyType(prevValue => [...prevValue].map(el => ({ ...el, checked: true })))
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Deselect All" placement="top" >
                                <IconButton>
                                    <IndeterminateCheckBoxOutlinedIcon
                                        sx={{ color: '#1976d2', }}
                                        onClick={() => {
                                            setAllDutyType(prevValue => [...prevValue].map(el => ({ ...el, checked: false })))
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>

                        </SelectOptionsContainer>
                        <TextField
                            focused
                            label="Search Duty Type"
                            fullWidth
                            size="small"
                            sx={{ mt: 1 }}
                            value={dutyTypeSearchQuery}
                            onChange={(e) => {
                                setDutyTypeSearchQuery(e.target.value)
                            }}
                        />
                        {/* Lists Duty types which matches with Duty type name in search box */}
                        {allDutyType.length != 0 && allDutyType.filter(n => n.Duty_Type_Name?.toLowerCase().includes(dutyTypeSearchQuery.toLowerCase())).map((dutyType, index) => {
                            return <ListItem
                                key={dutyType.Duty_Type_Id}
                                disablePadding
                            >
                                <ListItemButton
                                    id={dutyType.Duty_Type_Id}
                                    role={undefined}
                                    sx={{ p: 1, height: '40px' }}
                                    onClick={(e) => {
                                        setAllDutyType(prevValue =>
                                            [...prevValue].map(el =>
                                                el.Duty_Type_Id === dutyType.Duty_Type_Id ? ({ ...el, checked: !el.checked }) : el)
                                        )
                                    }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            sx={{ m: 0, }}
                                            edge="start"
                                            checked={dutyType.checked}
                                            tabIndex={-1}
                                            key={dutyType.Duty_Type_Id}
                                            // disableRipple
                                            inputProps={{ 'aria-labelledby': 1 }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={dutyType.Duty_Type_Id} primary={dutyType.Duty_Type_Name} />
                                </ListItemButton>
                            </ListItem>
                        })}
                    </List>
                </Collapse>
                <Divider />
            </List>
        </FilterWrapper>

        <Divider orientation="vertical" flexItem />

        <ContentWrapper>
            <HeaderText >Special Duty</HeaderText>
            <CardsContainer>
                <MiniCard
                    onClick={() => setSpecialDutyManagementView(true)}
                >
                    <AddchartOutlinedIcon fontSize="large" color="primary" />
                    <MiniCardText>Manage Special Duty</MiniCardText>
                </MiniCard>
            </CardsContainer>
            <NewSpecialDuty fetchSpecialDuties={fetchSpecialDuties} />
            <DataGridContainer>
                <DataGrid
                    rows={allSpecialDuties.filter((SDuty, index) => {
                        return (startDate.date <= SDuty.Special_Duty_Date && SDuty.Special_Duty_Date <= endDate.date) &&
                            allteachers.filter((e) => e.checked).find((obj) => obj.Teacher_name == SDuty.Teacher_name) &&
                            allDutyType.filter((e) => e.checked).find((obj) => obj.Duty_Type_Name == SDuty.Duty_Type_Name)
                    })}
                    columns={columns}
                    sx={{
                        mt: 2, height: '72vh', maxHeight: '72vh',
                    }}
                    components={{ Toolbar: CustomToolbar }}
                    disableSelectionOnClick
                    onCellEditCommit={changeNoOfSpecialDuty}
                ></DataGrid>
            </DataGridContainer>
            {/* <pre>{JSON.stringify(allDutyType, null, 2)}</pre> */}
        </ContentWrapper>
        {openSpecialDutyManagementView != false && <Backdrop
            sx={{ display: 'flex', zIndex: (theme) => theme.zIndex.drawer + 1, }}
            open={openSpecialDutyManagementView != false}
        >
            <SpecialDutyManagement setSpecialDutyManagementView={setSpecialDutyManagementView} />
        </Backdrop>}
    </SpecialDutyWrapper>

}