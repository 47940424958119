import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import useUser from '../../../hooks/useUser';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    gridVisibleSortedRowIdsSelector,
    useGridApiContext
} from '@mui/x-data-grid';
import { HeaderText, DataGridContainer } from "../common";
import { ReactComponent as YourSvg } from '../../../assets/images/chart.svg';
import jsPDF from 'jspdf';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PrintIcon from '@mui/icons-material/Print';

const LeaderBoardWrapper = styled.div`
    width: 100%;
    padding: 5px 35px 10px 10px;
    overflow: auto;
    scrollbar-width: thin;
    position: relative;
    margin-left: ${props => props.isActive ? '230px' : '98px'}; 
    transition: all 0.2s ease;
    /* background-color:red; */
`;

const DateRangeContainer = styled.div`
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem;
    margin-bottom: 2rem;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid rgba(224, 224, 224, 1);
    /* background-color: #939393; */
`;

const DateRange = styled.div`
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    /* background-color: #9393; */
`;

export default function LeaderBoard(props) {
    document.title = "Leader Board";

    const { user, setUser } = useUser();
    var moment = require('moment');
    const [startDate, setStartDate] = useState(`${moment().subtract((moment().format('MM') <= 5 ? 1 : 0), 'years').format('YYYY')}/06/01`);
    const [endDate, setEndDate] = useState(moment().format('YYYY/MM/DD'));
    const [leaderBoard, setLeaderBoard] = useState([])

    const exportPdf = (PrintData, Heading, FileName, startDate = null, endDate = null) => {
        const doc = new jsPDF()
        doc.autoTable({
            margin: { top: 82 },
            didDrawPage: (data) => {
                var currentPageNo = doc.internal.getCurrentPageInfo().pageNumber;
                var str = 'Page ' + currentPageNo;
                data.settings.margin.top = 10; // Reseting top margin. The change will be reflected only after print the first page.
                if (currentPageNo === 1) {
                    // doc.addImage(base64Img, 'PNG', 85, 15, 40, 10);
                    doc.setFontSize(15);
                    doc.text(Heading, 20, 40);
                    doc.setFontSize(12);
                    doc.text(`Generated By: ${user.username} (Admin)`, 20, 46);
                    doc.text(`Generated At : ${new Date()}`, 20, 52);
                    doc.text(`From: ${startDate}`, 20, 58);
                    doc.text(`To: ${endDate}`, 20, 64);
                }
                doc.setFontSize(10);
                var pageSize = doc.internal.pageSize;
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                doc.text(str, data.settings.margin.left, pageHeight - 10);
            },
            ...PrintData
        });
        doc.save(`${FileName}.pdf`)
    }

    const teacherLeaderBoard = async () => {
        if (!isValidDate(startDate) || !isValidDate(endDate)) return
        let res = await fetch(`${process.env.REACT_APP_API}/common/report/teacherLeaderBoard`, {
            method: "POST",
            headers: { Accept: 'application/json', "Content-Type": "application/json", },
            credentials: 'include',
            body: JSON.stringify({ startDate: startDate, endDate: endDate, ascendingOrder: false }),
        });
        res = await res.json();
        res = res.map(({ Teacher_id: id, ...rest }) => ({ id, ...rest }));
        setLeaderBoard(res);
    }


    useEffect(() => {
        teacherLeaderBoard()
    }, [startDate, endDate]);

    function isValidDate(dateString) {
        var regEx = /^\d{4}\/\d{2}\/\d{2}$/;
        if (!dateString.match(regEx)) return false;  // Invalid format
        return moment(dateString, 'YYYY/MM/DD').isValid()
    }

    const getFilteredRows = ({ apiRef }) => gridVisibleSortedRowIdsSelector(apiRef);

    const CustomToolbar = (props) => {
        const apiRef = useGridApiContext();

        const CSVToJSON = csv => {
            const lines = csv.split('\n');
            // Removing '\r'
            for (var i = 0; i < lines.length; i++) { lines[i] = lines[i].replace('\r', ''); }
            const keys = lines[0].split(',');
            return lines.slice(1).map(line => {
                return line.split(',').reduce((acc, cur, i) => {
                    const toAdd = {};
                    toAdd[keys[i]] = cur;
                    return { ...acc, ...toAdd };
                }, {});
            });
        };

        const handleExport = (options) => {
            if (options.type === 'pdf') {
                exportPdf({
                    body: CSVToJSON(apiRef.current.getDataAsCsv(options)),
                    columns: [
                        { header: 'Sl. No.', dataKey: 'Sl. No.' },
                        { header: 'Teacher Name', dataKey: 'Teacher Name' },
                        { header: 'Department Name', dataKey: 'Department Name' },
                        { header: `Exam Duties`, dataKey: `Exam Duties` },
                        { header: `Reliever Duties`, dataKey: `Reliever Duties` },
                        { header: `Special Duties`, dataKey: `Special Duties` },
                        { header: `Total Duties`, dataKey: `Total Duties` },
                    ]
                }, 'Teacher Leader Board', 'Teacher Leader Board', startDate, endDate);
            } else if (options.type === 'csv') {
                apiRef.current.exportDataAsCsv(options);
            }
        }

        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <Button
                    color={'primary'}
                    startIcon={<PrintIcon />}
                    onClick={() => handleExport({ getRowsToExport: getFilteredRows, type: 'pdf' })}
                >
                    Export pdf
                </Button>
                <Button
                    color={'primary'}
                    startIcon={<FileDownloadOutlinedIcon />}
                    onClick={() => handleExport({ getRowsToExport: getFilteredRows, type: 'csv' })}
                >
                    Export CSV
                </Button>
            </GridToolbarContainer>
        );
    };

    const columns = [
        { field: 'SlNo', headerName: 'Sl. No.', type: 'number', headerAlign: 'left', align: "left", flex: 1 },
        { field: 'id', headerName: 'ID', type: 'number', headerAlign: 'left', align: "left", hide: true, flex: 1 },
        { field: 'Teacher_name', headerName: 'Teacher Name', flex: 1, },
        { field: 'Dept_Name', headerName: 'Department Name', flex: 1, },
        { field: 'TotalExamDuties', headerName: 'Exam Duties', type: 'number', headerAlign: 'left', align: "left", flex: 1 },
        { field: 'TotalRelieverDuties', headerName: 'Reliever Duties', type: 'number', headerAlign: 'left', align: "left", flex: 1 },
        { field: 'TotalSpecialDuties', headerName: 'Special Duties', type: 'number', headerAlign: 'left', align: "left", flex: 1 },
        { field: 'TotalDuties', headerName: 'Total Duties', type: 'number', headerAlign: 'left', align: "left", flex: 1 },
    ];

    return <LeaderBoardWrapper isActive={props.isActive}>
        <HeaderText>Leader Board</HeaderText>
        <DateRangeContainer>
            <YourSvg width="8%" height="100%" />
            <Typography variant="h4" component="div" ml={3} color="text.primary">Filter By Date</Typography>
            <DateRange>
                <TextField
                    focused
                    label="From"
                    // sx={{ width: "160px" }}
                    value={startDate}
                    error={!isValidDate(startDate)}
                    onChange={(e) => {
                        setStartDate(e.target.value)
                    }}
                />
                <SwapHorizOutlinedIcon sx={{ m: 1.2, color: '#005ae6' }} />
                <TextField
                    focused
                    label="To"
                    // sx={{ width: "160px" }}
                    value={endDate}
                    error={!isValidDate(endDate)}
                    onChange={(e) => {
                        setEndDate(e.target.value)
                    }}
                />
                <Tooltip title="Reset Date Range" placement="top" sx={{ width: 'fit-content', ml: 1, }}>
                    <IconButton aria-label="add to shopping cart">
                        <EventRepeatOutlinedIcon
                            sx={{ color: '#1976d2', }}
                            onClick={() => {
                                // Reseting start date(Starting of academic calendar) and end date
                                setStartDate(`${moment().subtract((moment().format('MM') <= 5 ? 1 : 0), 'years').format('YYYY')}/06/01`);
                                setEndDate(moment().format('YYYY/MM/DD'));
                            }}
                        />
                    </IconButton>
                </Tooltip>
            </DateRange>
        </DateRangeContainer>
        <DataGridContainer>
            <DataGrid
                // Showing leaderboard of active teachers with serial number
                rows={leaderBoard.filter(row => row.Teacher_status === 'active').map((row, index) => ({ ...row, SlNo: index + 1 }))}
                columns={columns}
                // sx={{
                //     height: '92vh', maxHeight: '92vh',
                // }}
                autoHeight
                components={{ Toolbar: CustomToolbar }}
                disableSelectionOnClick
            // onCellDoubleClick={cellDoubleClick}
            // onCellEditCommit={changeVenueName}

            ></DataGrid>
        </DataGridContainer>
    </LeaderBoardWrapper>
}