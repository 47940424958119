import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    gridVisibleSortedRowIdsSelector,
    useGridApiContext
} from '@mui/x-data-grid';
import { withStyles } from "@material-ui/core";
import { CardsContainer, MiniCard, MiniCardText, DataGridContainer } from "../common";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import useUser from '../../../hooks/useUser';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Divider from '@mui/material/Divider';
import TableCell from '@mui/material/TableCell';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import RemoveDoneOutlinedIcon from '@mui/icons-material/RemoveDoneOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import FastForwardOutlinedIcon from '@mui/icons-material/FastForwardOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import ReduceCapacityOutlinedIcon from '@mui/icons-material/ReduceCapacityOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import PrintIcon from '@mui/icons-material/Print';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined';


const SpecialDutyManagementWrapper = styled.div`
    width: 90%;
    height: 90vh;
    padding: 1.1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* overflow: scroll; */
    align-items: center;
    border-radius: 1rem;
    background-color: white;
    color: black;
`;

const Header = styled.h2`
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    color: #005ae6;
    margin: 0 0 2rem;
    padding-left: 2px;
    /* font-family: 'Varela Round', sans-serif; */
`;

const SpecialDutyManagementContent = styled.div`
    width: 100%;
    max-height: 89%;
    display: flex;
    flex-direction: column;
    /* background-color: pink; */
`;

const AddDutyType = styled.div`
    width: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: .5rem;
    /* margin-top: 1.5rem; */
    border-radius: 1rem;
    border: 1px solid #cbcbcb;
    /* box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px; */
    /* background-color: green; */

`;


const HeaderText = styled.h2`
    /* width: 100%; */
	font-size: 30px;
	font-weight: 500;
	text-align: start;
    padding: .5rem 1rem;
	color: #545454;
	margin: 0 0 0rem;
    /* background-color: blue; */
`;


export default function SpecialDutyManagement(props) {
    const { user, setUser } = useUser();
    const [newDutyType, setnewDutyType] = useState('');
    const [specialDutyTypes, setSpecialDutyTypes] = useState([]);

    const exportPdf = (PrintData, Heading, FileName) => {
        const doc = new jsPDF()
        doc.autoTable({
            margin: { top: 82 },
            didDrawPage: (data) => {
                var currentPageNo = doc.internal.getCurrentPageInfo().pageNumber;
                var str = 'Page ' + currentPageNo;
                data.settings.margin.top = 10; // Reseting top margin. The change will be reflected only after print the first page.
                if (currentPageNo === 1) {
                    // doc.addImage(base64Img, 'PNG', 85, 15, 40, 10);
                    doc.setFontSize(15);
                    doc.text(Heading, 20, 40);
                    doc.setFontSize(12);
                    doc.text(`Generated By: ${user.username} (Admin)`, 20, 46);
                    doc.text(`Generated At : ${new Date()}`, 20, 52);
                }
                doc.setFontSize(10);
                var pageSize = doc.internal.pageSize;
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                doc.text(str, data.settings.margin.left, pageHeight - 10);
            },
            ...PrintData
        });
        doc.save(`${FileName}.pdf`)
    }

    const fetchSpecialDutyTypes = async () => {
        let res = await fetch(`${process.env.REACT_APP_API}/admin/specialDuty/getAllSpecialDutyTypes`, {
            credentials: 'include',
        });
        res = await res.json();
        res = res.map(({ Duty_Type_Id: id, ...rest }) => ({ id, ...rest }));

        setSpecialDutyTypes(res)
    };

    const addSpecialDutyType = async () => {
        if (newDutyType === '') return
        let result = await fetch(`${process.env.REACT_APP_API}/admin/specialDuty/addSpecialDutyType`, {
            method: "POST",
            headers: { Accept: 'application/json', "Content-Type": "application/json", },
            credentials: 'include',
            body: JSON.stringify({ dutyName: newDutyType }),
        });
        
        fetchSpecialDutyTypes()
    }

    const changeDutyTypeName = async (params, event, details) => {
        if (params.field != 'Duty_Type_Name' || !window.confirm("Are you sure you want to change this?")) {
            return;
        }

        let res = await fetch(`${process.env.REACT_APP_API}/admin/specialDuty/changeDutyTypeName`, {
            method: "POST",
            headers: { Accept: 'application/json', "Content-Type": "application/json", },
            credentials: 'include',
            body: JSON.stringify({ id: params.id, value: params.value }),
        });
        fetchSpecialDutyTypes()
    }

    const cellDoubleClick = async (params) => {
        if (params.field != 'Duty_Type_Status' || !window.confirm("Press ok to change status")) {
            return;
        }
        let res = await fetch(`${process.env.REACT_APP_API}/admin/specialDuty/updateDutyTypeStatus`, {
            method: "POST",
            headers: { Accept: 'application/json', "Content-Type": "application/json", },
            credentials: 'include',
            body: JSON.stringify(params.row),
        });
        fetchSpecialDutyTypes()
    }

    useEffect(() => {
        fetchSpecialDutyTypes();
    }, []);

    const getFilteredRows = ({ apiRef }) => gridVisibleSortedRowIdsSelector(apiRef);

    const CustomToolbar = (props) => {
        const apiRef = useGridApiContext();

        const CSVToJSON = csv => {
            const lines = csv.split('\n');
            // Removing '\r'
            for (var i = 0; i < lines.length; i++) { lines[i] = lines[i].replace('\r', ''); }
            const keys = lines[0].split(',');
            return lines.slice(1).map(line => {
                return line.split(',').reduce((acc, cur, i) => {
                    const toAdd = {};
                    toAdd[keys[i]] = cur;
                    return { ...acc, ...toAdd };
                }, {});
            });
        };

        const handleExport = (options) => {
            if (options.type === 'pdf') {
                exportPdf({
                    body: CSVToJSON(apiRef.current.getDataAsCsv(options)),
                    columns: [
                        { header: 'ID', dataKey: 'ID' },
                        { header: 'Duty Type', dataKey: 'Duty Type' },
                        { header: `Status`, dataKey: `Status` },
                    ]
                }, 'Special Duty Types', 'Special Duty Types');
            } else if (options.type === 'csv') {
                apiRef.current.exportDataAsCsv(options);
            }
        }

        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <Button
                    color={'primary'}
                    startIcon={<PrintIcon />}
                    onClick={() => handleExport({ getRowsToExport: getFilteredRows, type: 'pdf' })}
                >
                    Export pdf
                </Button>
                <Button
                    color={'primary'}
                    startIcon={<FileDownloadOutlinedIcon />}
                    onClick={() => handleExport({ getRowsToExport: getFilteredRows, type: 'csv' })}
                >
                    Export CSV
                </Button>
            </GridToolbarContainer>
        );
    };

    const columns = [
        { field: 'id', headerName: 'ID', type: 'number', headerAlign: 'left', align: "left", width: 150 },
        { field: 'Duty_Type_Name', headerName: 'Duty Type', width: 250, editable: true, },
        {
            field: 'Duty_Type_Status', headerName: 'Status', width: 150,
            renderCell: (params) => {
                if (params.value === 'active') {
                    return <Chip label={'Active'} variant="outlined" color="primary" icon={<CheckCircleOutlinedIcon />} />
                } else if (params.value === 'inactive') {
                    return <Chip label={'Inactive'} variant="outlined" color="error" icon={<DoNotDisturbOnOutlinedIcon />} />
                }
            }
        },
    ];


    return <SpecialDutyManagementWrapper>
        <Header>Manage Special Duties
            <IconButton aria-label="delete" onClick={() => props.setSpecialDutyManagementView(false)}>
                <CancelOutlinedIcon />
            </IconButton>
        </Header>
        <SpecialDutyManagementContent>
            <AddDutyType>
                <HeaderText>Add New Duty Type</HeaderText>
                <TextField
                    label="Duty Name"
                    variant="outlined"
                    value={newDutyType}
                    onChange={(event) =>
                        setnewDutyType(event.target.value)
                    }
                />

                <Tooltip title="Add" placement="right">
                    <span>
                    <IconButton
                        aria-label="delete"
                        color="primary"
                        disabled={newDutyType == ''}
                        onClick={() => {
                            setnewDutyType('')
                            addSpecialDutyType()
                        }}>
                        <PlaylistAddOutlinedIcon />
                    </IconButton>
                    </span>
                </Tooltip>
            </AddDutyType>
        <DataGridContainer>
            <DataGrid
                rows={specialDutyTypes}
                columns={columns}
                sx={{
                    mt:2, height: '62vh', maxHeight: '62vh',
                }}
                components={{ Toolbar: CustomToolbar }}
                disableSelectionOnClick
                onCellDoubleClick={cellDoubleClick}
                onCellEditCommit={changeDutyTypeName}

            ></DataGrid>
        </DataGridContainer>

        </SpecialDutyManagementContent>
    </SpecialDutyManagementWrapper>
}