import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector
} from '@mui/x-data-grid';
import { HeaderText, CardsContainer, MiniCard, MiniCardText, DataGridContainer } from "../common";
import { Box, withStyles } from "@material-ui/core";
import ExamDetails from "./examDetails";
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import PinchOutlinedIcon from '@mui/icons-material/PinchOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

const TeacherWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0;
    margin-bottom: 100px;
    box-sizing: border-box;
    overflow: auto;
    scrollbar-width: thin;
    /* background-color: orange; */
`;

const AddTeacherContainer = styled.div`
    width: 500px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const RelieverContaiver = styled.div`
    width: 100%;
    margin: 10px 15px 0px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* background-color: red; */
`;

const Footer = styled.div`
    width: 100%;
    margin: 20px 0 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-evenly;
`;

const DurationContainer = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: .8rem;
`;

const StyledDataGrid = withStyles({
    root: {
        "& .MuiDataGrid-renderingZone": {
            maxHeight: "none !important"
        },
        "& .MuiDataGrid-cell": {

            lineHeight: "unset !important",
            maxHeight: "none !important",
            whiteSpace: "normal"
        },
        "& .MuiDataGrid-row": {
            maxHeight: "none !important",
        }
    }
})(DataGrid);

export default function Exam(props) {

    const {
        watch,
        register,
        handleSubmit,
        control,
        setValue,
        trigger,
        reset,
        formState: { errors, isValid }
    } = useForm({ mode: "all", reValidateMode: "all" });
    const [exam, setExam] = useState([]);
    const [venues, setVenues] = useState([]);
    const [roomAllocations, setRoomAllocations] = useState([]);
    const [selectedVenues, setSelectedVenues] = useState([]);
    const [openDialogue, setOpenDialogue] = useState(false)
    const [openExamNutshellView, setOpenExamNutshellView] = useState(null)
    var moment = require('moment');

    const fetchExam = async () => {
        let res = await fetch(`${process.env.REACT_APP_API}/admin/exam`, {
            credentials: 'include',
        });
        res = await res.json();
        res = res.map(({ Exam_id: id, ...rest }) => ({ id, ...rest }));

        setExam(res)
    };

    const getAllRoomAllocations = async () => {
        let res = await fetch(`${process.env.REACT_APP_API}/admin/exam/getAllRoomAllocations`, {
            credentials: 'include',
        });
        res = await res.json();
        // console.log("🚀 ~ file: index.jsx ~ line 143 ~ getAllRoomAllocations ~ res", res)
        // res = res.map(({ Exam_id: id, ...rest }) => ({ id, ...rest }));
        // res.forEach((item, i) => { item.id = i + 1; });
        // console.log('getAllRoomAllocations', res)

        setRoomAllocations(res)
    };

    const fetchVenue = async () => {
        let res = await fetch(`${process.env.REACT_APP_API}/admin/venues`, {
            credentials: 'include',
        });
        res = await res.json();
        res.forEach((item, i) => {
            if (item.Venue_status == 'inactive') {
                // console.log('incative found:', item)
                delete res[i]
            }
        });
        // res = res.map(({ Venue_id: id, ...rest }) => ({ id, ...rest }));
        // console.table(res)
        setVenues(res)
    };

    const cellDoubleClick = async (params) => {
        if (params.field == 'Exam_status' && window.confirm("Press ok to change status")) {
            let res = await fetch(`${process.env.REACT_APP_API}/admin/exam/updateStatus`, {
                method: "POST",
                headers: { Accept: 'application/json', "Content-Type": "application/json", },
                credentials: 'include',
                body: JSON.stringify(params.row),
            });
            fetchExam()
        } else {
            //Opening exam nutshell view
            // setOpenExamDialogue(true)
        }

    }

    const changeExamDate = async (params, event, details) => {
        if (params.field == 'Exam_date') {
            var regEx = /^\d{4}\/\d{2}\/\d{2} \d{2}:\d{2}$/;

            // Invalid format
            if (!params.value.match(regEx) || !moment(params.value, 'YYYY/MM/DD HH:mm').isValid()) {
                alert('Invalid Date Format');
                fetchExam()
                return;
            } else if (window.confirm("Press ok to update date")) {
                let res = await fetch(`${process.env.REACT_APP_API}/admin/exam/changeExamDate`, {
                    method: "POST",
                    headers: { Accept: 'application/json', "Content-Type": "application/json", },
                    credentials: 'include',
                    body: JSON.stringify({ examId: params.id, newDate: params.value }),
                });
            }
        } else if (params.field == 'Exam_remark' && window.confirm("Press ok to update remark")) {
            let res = await fetch(`${process.env.REACT_APP_API}/admin/exam/changeExamRemark`, {
                method: "POST",
                headers: { Accept: 'application/json', "Content-Type": "application/json", },
                credentials: 'include',
                body: JSON.stringify({ examId: params.id, newRemark: params.value }),
            });
        } else { return; }
    }

    const addExam = async (data) => {
        let result = await fetch(`${process.env.REACT_APP_API}/admin/exam/addExam`, {
            method: "POST",
            headers: { Accept: 'application/json', "Content-Type": "application/json", },
            credentials: 'include',
            body: JSON.stringify(data),
        });
        const res = await result.json();
        console.log('returned object is ', (res));
        setOpenDialogue(false)
        fetchExam();
        getAllRoomAllocations();
    };

    function AllocationProgress(props) {
        // const [progress, setProgress] = useState(0);

        // useEffect(async () => {
        //     if (props.examId === undefined) return
        //     let result = await fetch(`${process.env.REACT_APP_API}/admin/exam/getAllocationProgressFromExamId`, {
        //         method: "POST",
        //         headers: { Accept: 'application/json', "Content-Type": "application/json", },
        //         credentials: 'include',
        //         body: JSON.stringify({ examId: props.examId, }),
        //     });
        //     const res = await result.json();
        //     setProgress(res[0])
        // }, []);


        if (props.variant == 'AllocationProgress') {
            return <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress variant="determinate" value={((props?.NoOfAllocations / props?.TotalDuties) * 100) || 0} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="caption" component="div" color="text.secondary">
                        {`${Math.round((props?.NoOfAllocations / props?.TotalDuties) * 100) || 0}%`}
                    </Typography>
                </Box>
            </Box>
        } else if (props.variant == 'slotsAllocated') {
            return `${props?.NoOfAllocations} / ${props?.TotalDuties}`
        } else if (props.variant == 'deleteExam') {
            return <Tooltip title={props?.NoOfAllocations === 0 ? 'Delete the exam and allocated slots and rooms from the database' : 'You cannot delete this because there are duties assigned to this exam'}>
                {/* Wrapped in <div> so that tooltip will show even IconButton is disabled */}
                <div>
                    <IconButton
                        color="error"
                        disabled={props?.NoOfAllocations > 0}
                        onClick={async () => {
                            if (!window.confirm(`Do you really want to delete exam(examId: ${props.examId})?`)) {
                                return;
                            }
                            let result = await fetch(`${process.env.REACT_APP_API}/admin/exam/deleteExam`, {
                                method: "POST",
                                headers: { Accept: 'application/json', "Content-Type": "application/json", },
                                credentials: 'include',
                                body: JSON.stringify({ examId: props.examId, }),
                            });
                            const res = await result.json();
                            if (result.status !== 200) {
                                alert(`Cannot perform delete`)
                                console.error(res)
                                return;
                            }
                            fetchExam()
                        }}
                    >
                        <DeleteForeverRoundedIcon />
                    </IconButton>
                </div>
            </Tooltip>
        }

    }

    useEffect(() => {
        fetchExam()
        fetchVenue()
        getAllRoomAllocations()
    }, []);

    useEffect(() => {
        setValue("venues", selectedVenues)
        trigger("venues")
    }, [selectedVenues]);

    useEffect(() => {
        if (openDialogue) {
            // setSelectedVenues([])
            setValue("dateAndTime", null);
            setValue("venues", [])
            setValue("remarks", '')
            setValue("hasReliever", true);
            setValue("showNotification", true);
        }
    }, [openDialogue]);

    const columns = [
        {
            field: 'actions', headerName: 'Expand', align: "center", width: 85, sortable: false, disableColumnMenu: true,
            renderCell: (params) => {
                return <IconButton aria-label="delete" color="primary" onClick={() => setOpenExamNutshellView(params.row)}>
                    <PinchOutlinedIcon />
                </IconButton>
            }
        },
        {
            field: '', headerName: 'Progress', align: "center", width: 100,
            renderCell: (params) => {
                return <AllocationProgress examId={params.row.id} TotalDuties={params.row.TotalDuties} NoOfAllocations={params.row.NoOfAllocations} variant='AllocationProgress' />
            }
        },
        { field: 'id', headerName: 'Exam ID', hide: true, width: 100 },
        { field: 'Exam_date', headerName: 'Date', editable: true, width: 150 },
        {
            field: 'a', headerName: 'Duties Allocated', width: 140,
            renderCell: (params) => {
                return <AllocationProgress examId={params.row.id} TotalDuties={params.row.TotalDuties} NoOfAllocations={params.row.NoOfAllocations} variant='slotsAllocated' />
            }
        },
        {
            field: 'Exam_ETA', headerName: 'ETA / Completed', width: 150,
            renderCell: (params) => {
                return (moment(params.row.Exam_date).fromNow())
            }
        },
        {
            field: '  ', headerName: 'No of Rooms', width: 120,
            renderCell: (params) => {
                const rooms = roomAllocations.filter(item => item.Exam_id === params.row.id)

                return `${rooms.length}`
            }
        },
        {
            field: 'Exam_status', headerName: 'Exam Status', width: 130,
            renderCell: (params) => {
                if (params.value === 'active') {
                    return <Chip label={'Active'} variant="outlined" color="primary" icon={<CheckCircleOutlinedIcon />} />
                } else if (params.value === 'inactive') {
                    return <Chip label={'Inactive'} variant="outlined" color="error" icon={<DoNotDisturbOnOutlinedIcon />} />
                }
            }
        },
        {
            field: ' ', headerName: 'Allocated Rooms', flex: 1,
            renderCell: (params) => {
                const rooms = roomAllocations.filter(item => item.Exam_id === params.row.id)
                return <div style={{ paddingBlock: 5,  }}>
                    {rooms.map((room, item) => {
                        return <Chip
                            key={room.Room_id}
                            variant="filled"
                            color="primary"
                            size="small"
                            sx={{ padding: '.1rem', m: '2px -3px', scale: '.79 .85'  }}
                            // sx={{ margin: '-1px -17px', padding: '.3rem', scale: '0.55 0.65', fontSize: '1.3rem' }}
                            label={room.Venue_name}
                            icon={<StoreOutlinedIcon />}
                        />
                    })}
                </div>
            }
        },
        { field: 'Exam_remark', headerName: 'Remark For Teachers', editable: true, hide: true, width: 300 },
        {
            field: 'delete_exam', headerName: 'Delete Exam', align: "center", hide: true, width: 130,
            renderCell: (params) => {
                return <AllocationProgress examId={params.row.id} NoOfAllocations={params.row.NoOfAllocations} variant='deleteExam' />
            }
        },
    ];

    return <TeacherWrapper>
        <HeaderText onClick={() => trigger("venues")}>Exam Management</HeaderText>
        <CardsContainer>
            <MiniCard
                onClick={() => setOpenDialogue(true)}
            >
                <PendingActionsOutlinedIcon fontSize="large" color="primary" />
                <MiniCardText>Add Exam</MiniCardText>
            </MiniCard>
        </CardsContainer>
        <DataGridContainer>
            <StyledDataGrid
                rows={exam}
                columns={columns}
                autoHeight
                components={{
                    Toolbar: () => <GridToolbarContainer>
                        <GridToolbarColumnsButton />
                        <GridToolbarFilterButton />
                        <GridToolbarDensitySelector />
                    </GridToolbarContainer>
                }}
                disableSelectionOnClick
                onCellDoubleClick={cellDoubleClick}
                onCellEditCommit={changeExamDate}
            ></StyledDataGrid>
        </DataGridContainer>
        <Dialog
            open={openDialogue}
            PaperProps={{
                style: {
                    display: 'flex',
                    width: 'fit-content',
                    // overflow: 'visible',
                    boxShadow: 'none',
                    borderRadius: '1rem'
                },
            }}
        >
            <AddTeacherContainer>
                <HeaderText onClick={() => console.log(watch()?.venues?.length == 0, watch()?.dateAndTime === 'Invalid date')}>Add Exam</HeaderText>
                <Controller
                    control={control}
                    name='dateAndTime'
                    rules={{
                        required: "Please Select Date and Time",
                        validate: (value) => value !== null
                    }}
                    render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                minutesStep={5}
                                ampm={false}
                                minDate={new Date()}
                                inputFormat="dd/MM/yyyy hh:mm a"
                                renderInput={(props) => <TextField
                                    fullWidth
                                    value={watch().dateAndTime}
                                    {...props}
                                />}
                                label="Date & Time *"
                                value={watch().dateAndTime}
                                onChange={(newValue) => {
                                    if (newValue !== null) {
                                        setValue("dateAndTime", moment(newValue).format('YYYY/MM/DD HH:mm'));
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    )}
                />
                <DurationContainer>
                    {/* <TextField
                        name="durationHr"
                        label="Duration: hr"
                        sx={{ marginRight: '1rem' }}
                        error={errors.durationHr}
                        helperText={errors.durationHr ? errors.durationHr?.message : ""}
                        {...register("durationHr", {
                            required: "Enter Hours of Exam",
                            min: { value: 0, message: "Min Value is 0" },
                            max: { value: 10, message: "Max Value is 10" },
                            validate: value => value.match(/^[0-9]+$/) != null || "Enter Numbers only",
                        })}
                    /> */}
                    {/* <TextField
                        name="durationMin"
                        label="Duration: min"
                        error={errors.durationMin}
                        helperText={errors.durationMin ? errors.durationMin?.message : ""}
                        {...register("durationMin", {
                            required: "Enter Minutes of Exam",
                            min: { value: 0, message: "Min Value is 0" },
                            max: { value: 59, message: "Max Value is 59" },
                            validate: value => value.match(/^[0-9]+$/) != null || "Enter Numbers only",
                        })}
                    /> */}
                </DurationContainer>
                <Typography
                    variant="span"
                    component="div"
                    align="right"
                    sx={{ width: "100%", margin: ".3rem" }}
                    color="text.secondary"
                >{`${watch()?.venues == undefined ? '0' : watch()?.venues.length} Venues Selected`}</Typography>
                <Autocomplete
                    multiple
                    fullWidth
                    freeSolo
                    options={venues}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.Venue_name}
                    onClose={(event, newInputValue) => {
                        setValue("venues", selectedVenues)
                    }}
                    onChange={(event, params) => {
                        setSelectedVenues(params)
                    }}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.Venue_name}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name="venues"
                            label="Venues *"
                            placeholder="Select Venues"
                            error={errors.venues}
                            helperText={errors.venues && errors.venues?.message}
                            {...register("venues", {
                                // required: "Please Select your Venues" ,
                                validate: (value) => selectedVenues.length > 0 || "Please Select Atleast One Venue"
                            })}
                        />
                    )}
                />

                <TextField
                    name='remarks'
                    label="Remarks For Teacher"
                    fullWidth
                    multiline
                    rows={2}
                    sx={{ marginTop: '1.1rem' }}
                    {...register("remarks",)}
                />
                <RelieverContaiver>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Controller
                                    name="hasReliever"
                                    control={control}
                                    render={(props) => (
                                        <Checkbox
                                            {...props}
                                            defaultChecked
                                            checked={props.value}
                                            onChange={(e) => setValue('hasReliever', e.target.checked)}
                                        />
                                    )}
                                />
                            }
                            label="Teachers Can Apply For Reliever Duties"
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Controller
                                    name="showNotification"
                                    control={control}
                                    render={(props) => (
                                        <Checkbox
                                            {...props}
                                            defaultChecked
                                            checked={props.value}
                                            onChange={(e) => setValue('showNotification', e.target.checked)}
                                        />
                                    )}
                                />
                            }
                            label="Teachers Get Exam Notification"
                        />
                    </FormGroup>
                </RelieverContaiver>
                <Footer>
                    <Button
                        variant="contained"
                        startIcon={<PersonAddAltOutlinedIcon />}
                        disabled={watch()?.dateAndTime === null || watch()?.dateAndTime === 'Invalid date' || watch()?.venues?.length == 0}
                        onClick={handleSubmit(addExam)}
                    >Add</Button>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setSelectedVenues([])
                            setValue("dateAndTime", null)
                            reset({
                                venues: [],
                                dateAndTime: null,
                                // durationHr: "",
                                // durationMin: "",
                            })
                            setOpenDialogue(false)
                        }}
                    >Cancel</Button>
                </Footer>
                {/* <pre>{JSON.stringify(watch(), null, 2)}</pre> */}
            </AddTeacherContainer>
        </Dialog>
        {openExamNutshellView != null && <Backdrop
            sx={{ display: 'flex', zIndex: (theme) => theme.zIndex.drawer + 1, }}
            open={openExamNutshellView != null}
        >
            <ExamDetails row={openExamNutshellView} setOpenExamNutshellView={setOpenExamNutshellView} />
        </Backdrop>}
    </TeacherWrapper>;
}