import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    gridVisibleSortedRowIdsSelector,
    useGridApiContext
} from '@mui/x-data-grid';
import { withStyles } from "@material-ui/core";
import { DataGridContainer } from "../common";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import useUser from '../../../hooks/useUser';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import ReduceCapacityOutlinedIcon from '@mui/icons-material/ReduceCapacityOutlined';
import PrintIcon from '@mui/icons-material/Print';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';


const ExamNutshellWrapper = styled.div`
    width: 90%;
    height: 90vh;
    padding: 1.1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* overflow: scroll; */
    align-items: center;
    border-radius: 1rem;
    background-color: white;
    color: black;
`;

const Header = styled.h2`
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    color: #005ae6;
    margin: 0 0 2rem;
    padding-left: 2px;
    /* font-family: 'Varela Round', sans-serif; */
`;

const ExamNutshellContent = styled.div`
    width: 100%;
    max-height: 89%;
    display: flex;
    /* background-color: pink; */
`;

const SlotTableHeader = styled.h2`
	font-size: 23px;
	font-weight: 600;
	text-align: center;    
    padding: .5rem 1rem;
	color: #545454;
	margin: 0 0 0rem;
`;

const SlotPanelContainer = styled.div`
    width: 100%;
    height: 100%;
    color: black;
    box-sizing: border-box;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    padding-right:4px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    /* background-color: orange; */

    &::-webkit-scrollbar {
        height: 12px;
        width: 8px;
        background: #f0f0f0;
    }
    &::-webkit-scrollbar-thumb {
        background: #cdcdcd;
        -webkit-border-radius: 1ex;
    }
`;


const StyledDataGrid = withStyles({
    root: {
        "& .MuiDataGrid-renderingZone": {
            maxHeight: "none !important"
        },
        "& .MuiDataGrid-cell": {

            lineHeight: "unset !important",
            maxHeight: "none !important",
            whiteSpace: "normal"
        },
        "& .MuiDataGrid-row": {
            maxHeight: "none !important",
        }
    }
})(DataGrid);

export default function ExamDetails(props) {
    const { row } = props;
    var moment = require('moment');

    const [allocationProgress, setAllocationProgress] = useState([]);
    const [slots, setSlots] = useState([]);

    const getActiveSlotFromExamId = async () => {
        if (row.id === undefined) return
        let result = await fetch(`${process.env.REACT_APP_API}/teacher/getActiveSlotFromExamId`, {
            method: "POST",
            headers: { Accept: 'application/json', "Content-Type": "application/json", },
            credentials: 'include',
            body: JSON.stringify({ examId: row.id }),
        });
        const res = await result.json();
        setSlots(res);
    }

    const getAllocationProgressFromExamId = async () => {
        if (row.id === undefined) return
        let result = await fetch(`${process.env.REACT_APP_API}/admin/exam/getAllocationProgressFromExamId`, {
            method: "POST",
            headers: { Accept: 'application/json', "Content-Type": "application/json", },
            credentials: 'include',
            body: JSON.stringify({ examId: row.id, }),
        });
        const res = await result.json();
        setAllocationProgress(res[0])
    }

    function SlotPanel(props) {
        const { user } = useUser();
        const { children, slot, Exam_date, index, ...other } = props;
        const [venuesAndAllocations, setVenuesAndAllocations] = useState([]);
        const [availableTeachersForDuty, setAvailableTeachersForDuty] = useState([]);
        const [selectedTeacher, setSelectedTeacher] = useState('');

        const exportPdf = (PrintData, Heading, tableHeader, FileName) => {
            const doc = new jsPDF()
            doc.autoTable({
                margin: { top: 92 },
                didDrawPage: (data) => {
                    var currentPageNo = doc.internal.getCurrentPageInfo().pageNumber;
                    var str = 'Page ' + currentPageNo;
                    data.settings.margin.top = 10; // Reseting top margin. The change will be reflected only after print the first page.
                    if (currentPageNo === 1) {
                        // doc.addImage(base64Img, 'PNG', 85, 15, 40, 10);
                        doc.setFontSize(15);
                        doc.text(Heading, 20, 40);
                        doc.setFontSize(12);
                        doc.text(tableHeader, 20, 46);
                        doc.text(`Exam Date: ${row.Exam_date}(${moment(row.Exam_date).fromNow()})`, 20, 52);
                        doc.text(`Exam Duration: ${row.Exam_duration / 60} Hours, ${row.Exam_duration % 60} Minutes`, 20, 58);
                        doc.text(`No of Venues: ${row.Exam_rooms}`, 20, 64);
                        doc.text(`Reliever: ${row.Exam_reliever == 1 ? 'Yes' : 'No'}`, 20, 70);
                        doc.text(`Allocation: ${allocationProgress?.NoOfAllocations} of ${allocationProgress?.TotalDuties}`, 20, 76);
                        doc.text(`Generated By: ${user.username} (Admin)`, 20, 82);
                        doc.text(`Generated At : ${new Date()}`, 20, 88);
                    }
                    doc.setFontSize(10);
                    var pageSize = doc.internal.pageSize;
                    var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                    doc.text(str, data.settings.margin.left, pageHeight - 10);
                },
                ...PrintData
            });
            doc.save(`${FileName}.pdf`)
        }

        const getVenuesAndAllocations = async () => {
            if (row.id === slot.Slot_id === undefined) return
            let result = await fetch(`${process.env.REACT_APP_API}/admin/exam/getVenuesAndAllocations`, {
                method: "POST",
                headers: { Accept: 'application/json', "Content-Type": "application/json", },
                credentials: 'include',
                body: JSON.stringify({ examId: row.id, slotId: slot.Slot_id }),
            });
            const res = await result.json();
            res[0].forEach((venue, index) => {
                // Checking if venue is allocates
                let obj = res[1].find(o => o.Room_id === venue.Room_id);
                // True if venue is allocated
                if (obj !== undefined) {
                    res[0][index].id = index;
                    res[0][index].Allocated = true;
                    res[0][index].Duty_id = obj.Duty_id;
                    res[0][index].Room_id = obj.Room_id
                    res[0][index].Teacher_id = obj.Teacher_id
                    res[0][index].Duty_Timestamp = obj.Duty_Timestamp
                    res[0][index].Duty_status = obj.Duty_status
                    res[0][index].Teacher_name = obj.Teacher_name
                    res[0][index].Dept_Name = obj.Dept_Name
                } else {
                    res[0][index].id = index;
                    res[0][index].Allocated = false
                    res[0][index].Duty_id = '-'
                    res[0][index].Teacher_id = '-'
                    res[0][index].Duty_Timestamp = '-'
                    res[0][index].Duty_status = '-'
                    res[0][index].Teacher_name = '-'
                    res[0][index].Dept_Name = '-'
                }
            })
            setVenuesAndAllocations(res[0]);
        }

        const addDutyToTeacher = async (roomId) => {
            if (selectedTeacher?.Username === undefined || slot.Slot_id === undefined || roomId === undefined) return
            let result = await fetch(`${process.env.REACT_APP_API}/admin/exam/addDutyToTeacher`, {
                method: "POST",
                headers: { Accept: 'application/json', "Content-Type": "application/json", },
                credentials: 'include',
                body: JSON.stringify({ username: selectedTeacher.Username, slotId: slot.Slot_id, roomId: roomId }),
            });
            const res = await result.json();
            setSelectedTeacher('');
            getVenuesAndAllocations();
        }

        const cellDoubleClick = async (params) => {
            if (params.field != 'Allocated' || params.row.Allocated == false || !window.confirm("Press ok to de-allocate")) {
                return;
            }
            let res = await fetch(`${process.env.REACT_APP_API}/admin/exam/updateDutyStatus`, {
                method: "POST",
                headers: { Accept: 'application/json', "Content-Type": "application/json", },
                credentials: 'include',
                body: JSON.stringify({ DutyID: params.row.Duty_id, TeacherID: params.row.Teacher_id, ExamID: props.slot.Exam_id }),
            });
            getVenuesAndAllocations()
        }

        useEffect(() => {
            getVenuesAndAllocations()
        }, []);

        const getFilteredRows = ({ apiRef }) => gridVisibleSortedRowIdsSelector(apiRef);

        const CustomToolbar = (props) => {
            const apiRef = useGridApiContext();

            const CSVToJSON = csv => {
                const lines = csv.split('\n');
                // Removing '\r'
                for (var i = 0; i < lines.length; i++) { lines[i] = lines[i].replace('\r', ''); }
                const keys = lines[0].split(',');
                return lines.slice(1).map(line => {
                    return line.split(',').reduce((acc, cur, i) => {
                        const toAdd = {};
                        toAdd[keys[i]] = cur;
                        return { ...acc, ...toAdd };
                    }, {});
                });
            };

            const handleExport = (options) => {
                // console.log(row)
                if (options.type === 'pdf') {
                    exportPdf({
                        body: CSVToJSON(apiRef.current.getDataAsCsv(options)),
                        columns: [
                            { header: 'Allocation Status', dataKey: 'Allocation Status' },
                            { header: 'Venue Name', dataKey: 'Venue Name' },
                            { header: 'Teacher Name', dataKey: 'Teacher Name' },
                            { header: 'Department Name', dataKey: 'Department Name' },
                            { header: `Allocation Timestamp`, dataKey: `Allocation Timestamp` },
                        ]
                    }, 'Exam Details', props.tableHeader, 'Exam Details');
                } else if (options.type === 'csv') {
                    apiRef.current.exportDataAsCsv(options);
                }
            }

            return (
                <GridToolbarContainer>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                    <Button
                        color={'primary'}
                        startIcon={<PrintIcon />}
                        onClick={() => handleExport({ getRowsToExport: getFilteredRows, type: 'pdf' })}
                    >
                        Export pdf
                    </Button>
                    <Button
                        color={'primary'}
                        startIcon={<FileDownloadOutlinedIcon />}
                        onClick={() => handleExport({ getRowsToExport: getFilteredRows, type: 'csv' })}
                    >
                        Export CSV
                    </Button>
                </GridToolbarContainer>
            );
        };

        const columns = [
            {
                field: 'Allocated', headerName: 'Allocation Status', flex: 2, type: 'boolean',
                renderCell: (params) => {
                    if (params.row.Allocated) {
                        return <Chip label={'Allocated'} variant="outlined" color="primary" icon={<DoneAllOutlinedIcon />} />
                    } else {
                        // const currDate = moment().format('YYYY/MM/DD')
                        // if (moment(Exam_date).format('YYYY/MM/DD') >= currDate) {
                        return <>
                            <Autocomplete
                                sx={{ width: '80%', m: .2 }}
                                onOpen={async () => {
                                    if (slot.Slot_id === undefined) return
                                    let result = await fetch(`${process.env.REACT_APP_API}/admin/exam/getAvailableTeachersForDuty`, {
                                        method: "POST",
                                        headers: { Accept: 'application/json', "Content-Type": "application/json", },
                                        credentials: 'include',
                                        body: JSON.stringify({ startDate: `${moment().subtract((moment().format('MM') <= 5 ? 1 : 0), 'years').format('YYYY')}/06/01`, endDate: moment().format('YYYY/MM/DD'), slotId: slot.Slot_id }),
                                    });
                                    const availableTeachersList = await result.json();
                                    setAvailableTeachersForDuty(availableTeachersList);
                                }}
                                onChange={(event, value) => {
                                    // Assign unique ID of the row in table
                                    value.id = params.row.id
                                    setSelectedTeacher(value)
                                }}
                                onInputChange={(event, newInputValue, reason) => {
                                    // Run when clear button is clicked
                                    if (reason === 'clear') {
                                        setSelectedTeacher('')
                                    }
                                }}
                                // inputValue={selectedTeacher}
                                getOptionLabel={(option) => `${option.Teacher_name} - ${option.TotalDuties}`}
                                options={availableTeachersForDuty}
                                loading={true}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Available Teachers"
                                        size="small"
                                        color="error"
                                    />
                                )}
                            />
                            {selectedTeacher != null && selectedTeacher.id == params.row.id ?
                                <IconButton
                                    aria-label="Add Teacher"
                                    color="primary"
                                    onClick={() => addDutyToTeacher(params.row.Room_id)}
                                >
                                    <PersonAddOutlinedIcon />
                                </IconButton>
                                :
                                null
                            }
                        </>
                        // } else {
                        //     return <Chip label={'Not Allocated'} variant="outlined" color="error" icon={<RemoveDoneOutlinedIcon />} />
                        // }
                    }
                }
            },
            {
                field: 'Venue_name', headerName: 'Venue Name', flex: 1,
                renderCell: (params) => {
                    return <Chip label={params.row.Venue_name} variant="outlined" color={params.row.Allocated ? "primary" : "error"} icon={<StoreOutlinedIcon />} />
                }
            },
            { field: 'Room_id', headerName: 'Room ID', flex: 1, hide: true },
            { field: 'Teacher_name', headerName: 'Teacher Name', flex: 1 },
            { field: 'Dept_Name', headerName: 'Department Name', flex: 1 },
            {
                field: 'Duty_Timestamp', headerName: 'Allocation Timestamp', flex: 1,
                renderCell: (params) => {
                    return (params.row.Allocated ? moment(params.row.Duty_Timestamp).format('YYYY/MM/DD HH:mm') : '-')
                }
            },
        ];

        return <DataGridContainer style={{ marginBottom: '2rem' }}>
            <SlotTableHeader>{`${slot.Slot_id} Slot ${index + 1} - From: ${slot.Slot_startTime}, To: ${slot.Slot_endTime}`}</SlotTableHeader>
            <StyledDataGrid
                rows={venuesAndAllocations}
                columns={columns}
                hideFooter
                autoHeight={true}
                components={{ Toolbar: CustomToolbar }}
                componentsProps={{ toolbar: { tableHeader: `Slot ${index + 1} - From: ${slot.Slot_startTime}, To: ${slot.Slot_endTime}` } }}
                disableSelectionOnClick
                onCellDoubleClick={cellDoubleClick}
            ></StyledDataGrid>
        </DataGridContainer>

    }

    useEffect(() => {
        getActiveSlotFromExamId();
        getAllocationProgressFromExamId();
    }, []);

    return <ExamNutshellWrapper>
        <Header>Exam Details
            <IconButton aria-label="delete" onClick={() => props.setOpenExamNutshellView(null)}>
                <CancelOutlinedIcon />
            </IconButton>
        </Header>
        <ExamNutshellContent>
            <List sx={{ width: 294 }}>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar sx={{ bgcolor: '#005ae6' }}>
                            <EventOutlinedIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={`${row.Exam_date}`} secondary={`${moment(row.Exam_date).fromNow()}`} />
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar sx={{ bgcolor: '#005ae6' }}>
                            <TimerOutlinedIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Exam Duration" secondary={`${row.Exam_duration / 60} Hours, ${row.Exam_duration % 60} Minutes`} />
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar sx={{ bgcolor: '#005ae6' }}>
                            <StoreOutlinedIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Venues" secondary={row.Exam_rooms} />
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar sx={{ bgcolor: '#005ae6' }}>
                            <ReduceCapacityOutlinedIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Reliever" secondary={row.Exam_reliever == 1 ? 'Yes' : 'No'} />
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar sx={{ bgcolor: '#005ae6' }}>
                            <HowToRegOutlinedIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={`Allocation ${Math.round((allocationProgress?.NoOfAllocations / allocationProgress?.TotalDuties) * 100)}%`} secondary={`${allocationProgress?.NoOfAllocations} of ${allocationProgress?.TotalDuties}`} />
                </ListItem>
            </List>
            <SlotPanelContainer>
                {slots.map((slot, index) => { return <SlotPanel key={slot.Slot_id} slot={slot} Exam_date={row.Exam_date} index={index} /> })}
            </SlotPanelContainer>
        </ExamNutshellContent>
    </ExamNutshellWrapper>
}