import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    gridVisibleSortedRowIdsSelector,
    useGridApiContext
} from '@mui/x-data-grid';
import { HeaderText, CardsContainer, MiniCard, MiniCardText, DataGridContainer } from "../common";
import useUser from '../../../hooks/useUser';
import jsPDF from 'jspdf';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import PrintIcon from '@mui/icons-material/Print';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const DepartmentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 100px;
    overflow: auto;
    scrollbar-width: none;
    /* background-color: pink; */
`;

const AddDepartmentContainer = styled.div`
    width: 350px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Footer = styled.div`
    width: 100%;
    margin: 30px 0 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-evenly;
`;

export const ErrorText = styled.p`
    margin: 0;
    padding: 0;
    font-size: .8rem;
    text-align: right;
    color: red;
`;



export default function Department(props) {

    const { user, setUser } = useUser();
    const [department, setDepartment] = useState([]);
    const [openDialogue, setOpenDialogue] = useState(false)
    const [newDepartmentName, setNewDepartmentName] = useState('')
    const [isValidName, setIsValidName] = useState(true)

    const exportPdf = (PrintData, Heading, FileName) => {
        const doc = new jsPDF()
        doc.autoTable({
            margin: { top: 82 },
            didDrawPage: (data) => {
                var currentPageNo = doc.internal.getCurrentPageInfo().pageNumber;
                var str = 'Page ' + currentPageNo;
                data.settings.margin.top = 10; // Reseting top margin. The change will be reflected only after print the first page.
                if (currentPageNo === 1) {
                    // doc.addImage(base64Img, 'PNG', 85, 15, 40, 10);
                    doc.setFontSize(15);
                    doc.text(Heading, 20, 40);
                    doc.setFontSize(12);
                    doc.text(`Generated By: ${user.username} (Admin)`, 20, 46);
                    doc.text(`Generated At : ${new Date()}`, 20, 52);
                }
                doc.setFontSize(10);
                var pageSize = doc.internal.pageSize;
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                doc.text(str, data.settings.margin.left, pageHeight - 10);
            },
            ...PrintData
        });
        doc.save(`${FileName}.pdf`)
    }

    const fetchDepartment = async () => {
        let res = await fetch(`${process.env.REACT_APP_API}/admin/department`, {
            credentials: 'include',
        });
        res = await res.json();
        res = res.map(({ Dept_Id: id, ...rest }) => ({ id, ...rest }));
        // console.log(res);
        setDepartment(res)
    };

    const cellDoubleClick = async (params) => {

        if (params.field != 'Dept_Status' || !window.confirm("Press ok to change status")) {
            return;
        }

        let res = await fetch(`${process.env.REACT_APP_API}/admin/department/updateStatus`, {
            method: "POST",
            headers: { Accept: 'application/json', "Content-Type": "application/json", },
            credentials: 'include',
            body: JSON.stringify(params.row),
        });
        fetchDepartment()
    }

    const changeDepartmentName = async (params, event, details) => {
        if (params.field != 'Dept_Name') {
            return;
        } 

        // True if the department name  already exists
        if (department.some((dept) => dept.Dept_Name === params.value)) {
            alert(`'${params.value}' Already Exists!`)
        } else {
            let res = await fetch(`${process.env.REACT_APP_API}/admin/department/changeDepartmentName`, {
                method: "POST",
                headers: { Accept: 'application/json', "Content-Type": "application/json", },
                credentials: 'include',
                body: JSON.stringify({ id: params.id, value: params.value }),
            });
        }
        fetchDepartment()
    }

    const addDepartment = async () => {
        let result = await fetch(`${process.env.REACT_APP_API}/admin/department/addDepartment`, {
            method: "POST",
            headers: { Accept: 'application/json', "Content-Type": "application/json", },
            credentials: 'include',
            body: JSON.stringify({ newDepartmentName: newDepartmentName, }),
        });
        const res = await result.json();
        console.log('returned object is ', (res));
        setOpenDialogue(false)
        fetchDepartment()
    };

    function validateDepartment() {
        if (newDepartmentName == "") {
            setIsValidName(true);
            return true
        }
        console.log('change');
        for (const key in department) {
            if (department[key].Dept_Name.toUpperCase() == newDepartmentName.toUpperCase()) {
                setIsValidName(false);
                break;
            }
            setIsValidName(true);
        }
    }

    useEffect(() => {
        fetchDepartment()
    }, []);

    useEffect(() => {
        validateDepartment()
    }, [newDepartmentName]);

    const columns = [
        { field: 'id', headerName: 'Department ID', type: 'number', headerAlign: 'left', align: "left", width: 150 },
        { field: 'Dept_Name', headerName: 'Department Name', width: 300, editable: true, },
        {
            field: 'Dept_Status', headerName: 'Status', width: 150,
            renderCell: (params) => {
                if (params.value === 'active') {
                    return <Chip label={'Active'} variant="outlined" color="primary" icon={<CheckCircleOutlinedIcon />} />
                } else if (params.value === 'inactive') {
                    return <Chip label={'Inactive'} variant="outlined" color="error" icon={<DoNotDisturbOnOutlinedIcon />} />
                }
            }
        },
    ];

    const getFilteredRows = ({ apiRef }) => gridVisibleSortedRowIdsSelector(apiRef);

    const CustomToolbar = (props) => {
        const apiRef = useGridApiContext();

        const CSVToJSON = csv => {
            const lines = csv.split('\n');
            // Removing '\r'
            for (var i = 0; i < lines.length; i++) { lines[i] = lines[i].replace('\r', ''); }
            const keys = lines[0].split(',');
            return lines.slice(1).map(line => {
                return line.split(',').reduce((acc, cur, i) => {
                    const toAdd = {};
                    toAdd[keys[i]] = cur;
                    return { ...acc, ...toAdd };
                }, {});
            });
        };

        const handleExport = (options) => {
            if (options.type === 'pdf') {
                exportPdf({
                    body: CSVToJSON(apiRef.current.getDataAsCsv(options)),
                    columns: [
                        { header: 'Department ID', dataKey: 'Department ID' },
                        { header: 'Department Name', dataKey: 'Department Name' },
                        { header: `Department Status`, dataKey: `Status` },
                    ]
                }, 'Department Details', 'Department Details');
            } else if (options.type === 'csv') {
                apiRef.current.exportDataAsCsv(options);
            }
        }

        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <Button
                    color={'primary'}
                    startIcon={<PrintIcon />}
                    onClick={() => handleExport({ getRowsToExport: getFilteredRows, type: 'pdf' })}
                >
                    Export pdf
                </Button>
                <Button
                    color={'primary'}
                    startIcon={<FileDownloadOutlinedIcon />}
                    onClick={() => handleExport({ getRowsToExport: getFilteredRows, type: 'csv' })}
                >
                    Export CSV
                </Button>
            </GridToolbarContainer>
        );
    };


    return <DepartmentWrapper>
        <HeaderText onClick={() => console.log(newDepartmentName)}>Department Management</HeaderText>
        <CardsContainer>
            <MiniCard
                onClick={() => setOpenDialogue(true)}
            >
                <GroupAddOutlinedIcon fontSize="large" color="primary" />
                <MiniCardText>Add Department</MiniCardText>
            </MiniCard>
        </CardsContainer>
        <DataGridContainer>
            <DataGrid
                rows={department}
                columns={columns}
                sx={{ height: '82vh', maxHeight: '82vh' }}
                // autoHeight={true}
                components={{ Toolbar: CustomToolbar }}
                disableSelectionOnClick
                onCellDoubleClick={cellDoubleClick}
                onCellEditCommit={changeDepartmentName}
                onFilterModelChange={(params) => console.log(params)}
            ></DataGrid>
        </DataGridContainer>
        <Dialog open={openDialogue}
            PaperProps={{
                style: {
                    display: 'flex',
                    width: 'fit-content',
                    overflow: 'visible',
                    boxShadow: 'none',
                    borderRadius: '1rem'
                },
            }}>
            <AddDepartmentContainer>
                <HeaderText>Add Department</HeaderText>
                <TextField
                    required
                    id="outlined-required"
                    label="New Department Name"
                    fullWidth
                    error={!isValidName}
                    helperText={!isValidName ? "This department already exists. Try another" : ""}
                    onChange={(event) => { setNewDepartmentName(event.target.value) }}

                />
                <Footer>
                    <Button
                        variant="contained"
                        startIcon={<GroupAddOutlinedIcon />}
                        disabled={newDepartmentName == "" || !isValidName}
                        onClick={addDepartment}
                    >Add</Button>
                    <Button
                        variant="outlined"
                        // startIcon={<ClearIcon />}
                        onClick={() => {
                            setNewDepartmentName("")
                            setOpenDialogue(false)
                        }}
                    >Cancel</Button>
                </Footer>
            </AddDepartmentContainer>
        </Dialog>
    </DepartmentWrapper>;
}
