import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import useUser from '../../../hooks/useUser';
import Lottie from 'react-lottie';
import Img from '../../../assets/lottie/profile.json';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import SyncLockOutlinedIcon from '@mui/icons-material/SyncLockOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';

const ContentWrapper = styled.div`
    width: 100%;
    padding: 10px;
    /* padding-right: 50px; */
    /* overflow: scroll; */
    /* scrollbar-width: none; */
    position: relative;
    display: flex;
    box-sizing: border-box;
    margin-left: ${props => props.isActive ? '230px' : '98px'}; 
    /* background-color: red; */
    transition: all 0.2s ease;
`;

const ProfileDetails = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* flex-wrap: flex; */
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
	border-radius: 1rem;
    /* box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px; */
    background-color: white;
`;

const ImageContainer = styled.div`
    height: 50%;
    width: 100%;
    display: flex;
    flex-shrink: 1;
    align-items: center;
    /* background-color: #f5f5; */
`;

const ProfileHeader = styled.div`
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;

    h1 {        
        height: fit-content;
        font-size: 40px;
        font-weight: 600;
        text-align: center;
        color: #005ae6;
        margin: .5rem 1rem;
        padding-left: 2px;
    }
`;

const ProfileContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    
    align-items: center;
    flex-wrap: wrap;
    `;

const PswdContainer = styled.div`
    
    p {
        width: fit-content;
        margin: 1.2rem 1.2rem .3rem;
        cursor: pointer;
        color: #005ae6;
        transition: .3s;
        letter-spacing: .5px;
        
        &:hover {
            letter-spacing: 1px;
        }
    }
`;

export default function Profile(props) {
    document.title = "Profile";

    const { user, setUser } = useUser();
    const [openDialogue, setOpenDialogue] = useState(false)
    const [teacher, setTeacher] = useState({})
    const [originalTeacher, setOriginalTeacher] = useState([])
    const [password, setPassword] = useState('')
    var moment = require('moment');

    const getTeacherDetails = async () => {
        let res = await fetch(`${process.env.REACT_APP_API}/teacher/getTeacherDetailsFromUsername`, {
            method: "POST",
            headers: { Accept: 'application/json', "Content-Type": "application/json", },
            credentials: 'include',
            body: JSON.stringify({ username: user.username }),
        });
        res = await res.json();
        setTeacher(res[0])
        setOriginalTeacher(res[0])
    }

    useEffect(() => {
        getTeacherDetails() 
    }, []);

    return <ContentWrapper isActive={props.isActive}>
        <ProfileDetails>
            <ImageContainer>
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: Img,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    style={{ width: "280px", height: "100%" }}
                />
            </ImageContainer>
            <ProfileHeader>
                <h1>Profile Details</h1>
            </ProfileHeader>
            <ProfileContent>
                <TextField
                    label="Teacher Name"
                    variant="outlined"
                    sx={{ width: '300px', margin: '.7rem 1rem' }}
                    value={teacher?.Teacher_name}
                    onChange={(event) => {
                        setTeacher((prevState) => ({
                            ...prevState,
                            Teacher_name: event.target.value
                        }))
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AccountCircle />
                            </InputAdornment>
                        ),
                        endAdornment: <IconButton
                            disabled={originalTeacher?.Teacher_name == teacher?.Teacher_name}
                            onClick={async (event) => {
                                if (!window.confirm("Are You Sure To Update Your Name?")) {
                                    return;
                                }
                                let res = await fetch(`${process.env.REACT_APP_API}/teacher/updateTeacherDetails`, {
                                    method: "POST",
                                    headers: { Accept: 'application/json', "Content-Type": "application/json", },
                                    credentials: 'include',
                                    body: JSON.stringify({
                                        username: user.username,
                                        details: { Teacher_name: teacher?.Teacher_name }
                                    }),
                                });
                                getTeacherDetails();
                            }}
                            edge="end"
                        >
                            <ModeEditOutlinedIcon sx={{ color: originalTeacher?.Teacher_name == teacher?.Teacher_name ? '#757575' : '#005ae6' }} />
                        </IconButton>
                    }}
                />
                < TextField
                    label="Phone Number"
                    variant="outlined"
                    sx={{ width: '300px', margin: '.7rem 1rem' }}
                    error={(teacher?.Teacher_contactNo?.match(/^[0-9]{10}$/i) == null)}
                    helperText={(teacher?.Teacher_contactNo?.match(/^[0-9]{10}$/i) == null) && "10 Digit Number Required"}
                    value={teacher?.Teacher_contactNo}
                    onChange={(event) => {
                        setTeacher((prevState) => ({
                            ...prevState,
                            Teacher_contactNo: event.target.value
                        }))
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <ContactPhoneOutlinedIcon />
                            </InputAdornment>
                        ),
                        endAdornment: <IconButton
                            disabled={(originalTeacher?.Teacher_contactNo == teacher?.Teacher_contactNo) || (teacher?.Teacher_contactNo.match(/^[0-9]{10}$/i) == null)}
                            onClick={async (event) => {

                                if (!window.confirm("Are You Sure To Update Your Phone Number?")) {
                                    return;
                                }
                                let res = await fetch(`${process.env.REACT_APP_API}/teacher/updateTeacherDetails`, {
                                    method: "POST",
                                    headers: { Accept: 'application/json', "Content-Type": "application/json", },
                                    credentials: 'include',
                                    body: JSON.stringify({
                                        username: user.username,
                                        details: { Teacher_contactNo: teacher?.Teacher_contactNo }
                                    }),
                                });
                                getTeacherDetails();
                            }}
                            edge="end"
                        >
                            <ModeEditOutlinedIcon sx={{ color: (originalTeacher?.Teacher_contactNo == teacher?.Teacher_contactNo || teacher?.Teacher_contactNo.match(/^[0-9]{10}$/i) == null) ? '#757575' : '#005ae6' }} />
                        </IconButton>
                    }}
                />
                <TextField
                    label="Department"
                    variant="outlined"
                    sx={{ width: '300px', margin: '.7rem 1rem' }}
                    value={teacher?.Dept_Name}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <GroupsOutlinedIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    label="E-mail"
                    variant="outlined"
                    sx={{ width: '300px', margin: '.7rem 1rem' }}
                    value={teacher?.Username}
                    InputProps={{
                        readOnly: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <EmailOutlinedIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    label="Joined"
                    variant="outlined"
                    sx={{ width: '300px', margin: '.7rem 1rem' }}
                    value={moment(teacher?.Teacher_join_date).fromNow()}
                    InputProps={{
                        readOnly: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <CakeOutlinedIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </ProfileContent>
            <PswdContainer>
                <p onClick={(event) => {  
                    setPassword('')
                    setOpenDialogue(!openDialogue) 
                }}>Change Password</p>
                { openDialogue && <TextField
                    label="New Password"
                    variant="outlined"
                    sx={{ width: '300px', margin: '.7rem 1rem' }}
                    error={password.length < 8 && password != ''}
                    helperText={password.length < 8 && password != '' ? "Password Must Contain Atleast 8 characters" : ""}
                    onChange={(event) => {
                        console.log(event)
                        setPassword(event.target.value)
                    }}
                    InputProps={{
                        endAdornment: <IconButton
                            disabled={ password.length < 8 }
                            onClick={async (event) => {
                                if (!window.confirm("Are You Sure To Update Your Password?")) {
                                    return;
                                }
                                let res = await fetch(`${process.env.REACT_APP_API}/teacher/updateTeacherPassword`, {
                                    method: "POST",
                                    headers: { Accept: 'application/json', "Content-Type": "application/json", },
                                    credentials: 'include',
                                    body: JSON.stringify({
                                        username: user.username,
                                        password: password
                                    }),
                                });
                                setOpenDialogue(false) 
                            }}
                            edge="end"
                        >
                            <SyncLockOutlinedIcon sx={{ color: password.length < 8 ? '#757575' : '#005ae6' }} />
                        </IconButton>
                    }}
                />}
            </PswdContainer>

        </ProfileDetails>
    </ContentWrapper>
}