import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { HeaderText, CardsContainer, MiniCard, MiniCardText } from "../common";
import ReleverTable from "./releverTable";
import NoRelieverSlots from './NoRelieverSlots'
import moment from 'moment'
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import InputAdornment from '@mui/material/InputAdornment';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';

const RelieverWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 100px;
    padding: 10px;
    padding-right: 24px;
    box-sizing: border-box;
    overflow: auto;
    scrollbar-width: none;
    /* background-color: orange; */
`;

const TabPanelContainer = styled.div`
    width: 100%;
    height: fit-content;
    /* height: 400px; */
    /* background-color: #0088ff54; */
`;

const ReliverContainer = styled.div`
    width: 100%;
    margin-right: 20px;
    padding: .5rem;
    box-sizing: border-box;
    display: flex;
    border-radius: 1rem;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    /* border: .1px solid #005ae6; */
    /* background-color: #0088ff54; */

`;

const Header = styled.h2`
	font-size: 30px;
	font-weight: 500;
	text-align: start;
    padding: .5rem 1rem;
	color: #545454;
	margin: 0 0 0rem;
    /* background-color: blue; */
`;

const ExamContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 1rem;
    /* background-color: pink; */
`;
const Reviever = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: .5rem;
    margin-top: 1.5rem;
    border-radius: 1rem;
    border: 1px solid #cbcbcb;
    /* box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px; */
    /* background-color: green; */

    &:nth-child(1) {
        /* background-color: red; */
        margin-top: 0rem;
    }
`;


const ReleiverDetails = (props) => {
    const { children, slot, examId, ...other } = props;
    const [relieverVenues, setRelieverVenues] = useState([]);
    const [relievingTeachers, setRelievingTeachers] = useState([]);
    const [reliever, setReliever] = useState(null);
    const [open, setOpen] = useState(false);
    const loading = open && relievingTeachers.length === 0;

    const getRelieverVenues = async () => {
        if (slot.Teacher_id === undefined || examId === undefined) return
        let result = await fetch(`${process.env.REACT_APP_API}/admin/reliever/getRelieverVenues`, {
            method: "POST",
            headers: { Accept: 'application/json', "Content-Type": "application/json", },
            credentials: 'include',
            body: JSON.stringify({ teacherId: slot.Teacher_id, examId: examId }),
        });
        const res = await result.json();
        setRelieverVenues(res)
    }

    const getAvailableRelievers = async () => {
        if (slot.Teacher_id === undefined || examId === undefined) return
        let result = await fetch(`${process.env.REACT_APP_API}/admin/reliever/getAvailableRelievers`, {
            method: "POST",
            headers: { Accept: 'application/json', "Content-Type": "application/json", },
            credentials: 'include',
            body: JSON.stringify({ examId: examId }),
        });
        const res = await result.json();
        setRelievingTeachers(res)
    }

    const addReliever = async () => {
        let result = await fetch(`${process.env.REACT_APP_API}/admin/reliever/addReliever`, {
            method: "POST",
            headers: { Accept: 'application/json', "Content-Type": "application/json", },
            credentials: 'include',
            body: JSON.stringify({ relieverSlotId: slot.Reliever_Slot_id, teacherId: reliever, examId: examId }),
        });
        const res = await result.json();
        setReliever(null);
        props.getExamsForReliever()
        props.getActiveRelieverSlotsFromExamId()
    }

    useEffect(() => {
        getRelieverVenues();
    }, []);

    return <Reviever>
        <TextField
            label="Teacher Name"
            variant="outlined"
            sx={{ margin: '.7rem 1rem' }}
            value={slot?.Teacher_name}
            InputProps={{
                readOnly: true,
            }}
        />
        <TextField
            label="Venues"
            variant="outlined"
            fullWidth={false}
            sx={{ width: '250px', margin: '.7rem 1rem' }}
            InputProps={{
                readOnly: true,
                startAdornment: (
                    <InputAdornment position="start">
                        {relieverVenues.map((venue, index) => {
                            return <Chip variant="outlined" color="primary" sx={{ margin: '0 .2rem' }} icon={<StoreOutlinedIcon />} label={venue.Venue_name} />
                        })}
                    </InputAdornment>
                ),
            }}
        />
        <Autocomplete
            sx={{ width: '350px', margin: '.7rem 1rem' }}
            onOpen={() => { setOpen(true); getAvailableRelievers() }}
            onChange={(event, value) => { setReliever(value.Teacher_id); }}
            onInputChange={(event, newInputValue, reason) => {
                // Run when clear button is clicked
                if (reason === 'clear') {
                    setReliever(null)
                }
            }}
            getOptionLabel={(option) => `(${option.TotalDuties}) ${option.Teacher_name} - ${option.Remaining_Opportunity} Remaining`}
            options={relievingTeachers}

            renderInput={(params) => <>
                <TextField
                    {...params}
                    label="Select Reliever"
                    variant="outlined"
                    // InputProps={{
                    //     ...params.InputProps,
                    //     endAdornment: (
                    //         <React.Fragment>
                    //             {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    //             {params.InputProps.endAdornment}
                    //         </React.Fragment>
                    //     ),
                    // }}
                />
            </>}
        />
        <Tooltip title="Add Reliever" sx={{ width: 'fit-content' }}>
            <span>
                <IconButton aria-label="Add Reliever" size="large" disabled={reliever == null} onClick={() => addReliever()}>
                    <GroupAddOutlinedIcon color={reliever == null ? '' : 'primary'} />
                </IconButton>
            </span>
        </Tooltip>
    </Reviever>

}

export default function Reliever(props) {

    const [exams, setExams] = useState([]);
    const [activePanel, setActivePanel] = useState(0);

    const checkForReliever = async () => {
        let res = await fetch(`${process.env.REACT_APP_API}/admin/reliever/checkForReliever`, {
            credentials: 'include',
        });
        res = await res.json();
    };

    const getExamsForReliever = async () => {
        let res = await fetch(`${process.env.REACT_APP_API}/admin/reliever/getExamsForReliever`, {
            credentials: 'include',
        });
        res = await res.json();
        setExams(res)
    };

    function TabPanel(props) {
        const { children, value, index, examId, ...other } = props;
        const [relieverSlots, setRelieverSlots] = useState([]);

        const getActiveRelieverSlotsFromExamId = async () => {
            if (examId === undefined) return
            let result = await fetch(`${process.env.REACT_APP_API}/admin/reliever/getActiveRelieverSlotsFromExamId`, {
                method: "POST",
                headers: { Accept: 'application/json', "Content-Type": "application/json", },
                credentials: 'include',
                body: JSON.stringify({ examId: examId }),
            });
            const reliever = await result.json();
            setRelieverSlots(reliever);
        }

        useEffect(() => {
            getActiveRelieverSlotsFromExamId();
        }, []);

        return <>
            <TabPanelContainer
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}
            >
                {value === index && <>
                    {relieverSlots.length == 0 && <>
                        <Header>No Reliever Slots Are Available</Header>
                        <NoRelieverSlots />
                    </>}
                    {relieverSlots.length > 0 && <>
                        <Header>Assign Relievers To Full Duty Teachers, {relieverSlots.length} More Left!</Header>
                        <ExamContainer>
                            {relieverSlots.map((slot, index) => {
                                return <ReleiverDetails index={index} key={index} slot={slot} examId={examId} getExamsForReliever={getExamsForReliever} getActiveRelieverSlotsFromExamId={getActiveRelieverSlotsFromExamId} />
                            })}
                        </ExamContainer>
                    </>}
                </>}
            </TabPanelContainer>
        </>;
    }

    useEffect(() => {
        checkForReliever()
        getExamsForReliever()
    }, []);

    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    return <RelieverWrapper>
        <HeaderText onClick={() => getExamsForReliever()}>Reliever Management</HeaderText>
        <CardsContainer>
            <MiniCard
                onClick={() => checkForReliever()}
            >
                <CloudSyncOutlinedIcon fontSize="large" color="primary" />
                <MiniCardText>Refresh</MiniCardText>
            </MiniCard>
        </CardsContainer>
        {exams.length > 0 && <ReliverContainer>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={activePanel}
                onChange={(event, newValue) => { setActivePanel(newValue); }}
                aria-label="Vertical tabs example"
                sx={{ width: '200px', pt: 9, borderRight: 1, borderColor: 'divider', }}
            >
                {exams.map((exam, index) => {
                    return <Tab key={index} label={`on ${moment(exam.Exam_date, 'YYYY/MM/DD HH:mm').format('YYYY/MM/DD')} (${moment(exam.Exam_date).fromNow()}) {${exam.Exam_id}}`} {...a11yProps(index)} />
                })}
            </Tabs>
            {exams.map((exam, index) => {
                return <TabPanel key={index} value={activePanel} index={index} examId={exam.Exam_id}>{index}</TabPanel>
            })}
        </ReliverContainer>}
        <ReleverTable />
    </RelieverWrapper>;
}