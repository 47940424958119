import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom';
import useUser from '../../../hooks/useUser';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import ReduceCapacityOutlinedIcon from '@mui/icons-material/ReduceCapacityOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import DirectionsWalkOutlinedIcon from '@mui/icons-material/DirectionsWalkOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';

const NavbarWrapper = styled.div`
    height: fit-content;
    width: fit-content;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: fixed;
    bottom: 10px;
    border-radius: 10px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    background-color: #005ae6;
`;

const Logo = styled.div`
    width: 100%;
    padding-left: .6rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    /* background-color: pink; */

    svg {
        height: 100%;
        width: 59px;
    }
`;

const MenuContainer = styled.div`
    position: relative;
    width: fit-content;
    height: 65px;
    background: #005ae6;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
`;

const NavList = styled.ul`
    display: flex;
    /* width: 350px; */
    padding: 0 30px;
    box-sizing: border-box;
    /* background-color: blue; */
`;

const Nav = styled.li`
    position: relative;
    list-style: none;
    width: 70px;
    height: 70px;
    line-height: 12px;
    z-index: 1;
    /* background-color: green; */
`;

const Link = styled.a`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
    /* background-color: green; */
`;

const LinkIcon = styled.span`
    position: relative;
    display: block;
    line-height: 75px;
    font-size: 1.5em;
    text-align: center;
    transform: translateY(${props => props.isActive ? '-31px' : '-7px'});
    transition: .5s;
    color: ${props => props.isActive ? '#fff' : '#fff'};
`;

const LinkName = styled.span`
    position: absolute;
    color: #fff;
    font-weight: 400;
    padding: 0 4px;
    border-radius: 2rem;
    font-size: 0.75em;
    letter-spacing: 0.05em; 
    user-select: none;
    transition: .5s;
    transform: translateY(${props => props.isActive ? '12px' : '17px'});
`;

const Indicator = styled.div`
    position: absolute;
    top: -50%;
    left: 31px;
    width: 55px;
    height: 55px;
    background: #005ae6;
    border-radius: 50%;
    border: 6px solid white;
    transition: .5s;
    transform: translateX(calc(70px * ${props => props.indicatorPosition}));

    &::before {
        content: '';
        position: absolute;
        top: 48%;
        left: -22px;
        width: 20px;
        height: 20px;
        background: transparent;
        border-top-right-radius: 20px;
        box-shadow: 1px -10px 0px 0px white;
    }

    &::after {
        content: '';
        position: absolute;
        top: 48%;
        right: -22px;
        width: 20px;
        height: 20px;
        background: transparent;
        border-top-left-radius: 20px;
        box-shadow: -1px -10px 0px 0px white;
    }
`;

const Accessibility = styled.div`
    width: 100%;
    margin-left: .3rem;
    padding-right: .6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;

`;

export function Navbar(props) {
    const [indicatorPosition, setIndicatorPosition] = useState(0);
    const { user, setUser } = useUser();
    const navigate = useNavigate();

    const logout = async () => {
        let res = await fetch(`${process.env.REACT_APP_API}/logout`, {
            credentials: 'include',
        });
        setUser({ isLoggedIn: false });
        navigate('/');
        res = await res.json();
    }

    useEffect(() => {
        const menuList = ['admin', 'teacher', 'exam', 'reliever', 'report', 'specialDuties', 'venue', 'department'];
        setIndicatorPosition(menuList.indexOf(props.menu));
    }, [props.menu]);

    return (
        <NavbarWrapper>

            <Logo>
                <svg width="234.375" height="265.74310128341455" viewBox="0 0 234.375 265.74310128341455" className="css-1j8o68f">
                    <defs id="SvgjsDefs8003"></defs>
                    <g id="SvgjsG8004" featurekey="rootContainer"
                        transform="matrix(4.725301111045653,0,0,4.725301111045653,0.0000347689931040783,0.019721259814305742)"
                        fill="#005ae6">
                        <path xmlns="http://www.w3.org/2000/svg"
                            d="M23.11 55.78L1.69 43.41A3.39 3.39 0 0 1 0 40.48V15.75a3.39 3.39 0 0 1 1.69-2.94L23.11.45a3.39 3.39 0 0 1 3.39 0l21.41 12.37a3.39 3.39 0 0 1 1.69 2.94v24.72a3.39 3.39 0 0 1-1.69 2.94L26.5 55.78a3.39 3.39 0 0 1-3.39 0z">
                        </path>
                    </g>
                    <g id="SvgjsG8005" featurekey="symbolFeature-0" transform="matrix(1.2,0,0,1.2,81,74)" fill="#ffffff">
                        <g xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M59,4h-7V1c0-0.552-0.447-1-1-1h-4c-0.553,0-1,0.448-1,1v3H33V1c0-0.552-0.447-1-1-1h-4c-0.552,0-1,0.448-1,1v3H14V1   c0-0.552-0.448-1-1-1H9C8.448,0,8,0.448,8,1v3H1C0.448,4,0,4.448,0,5v16v38c0,0.553,0.448,1,1,1h58c0.553,0,1-0.447,1-1V21V5   C60,4.448,59.553,4,59,4z M48,2h2v8h-2V2z M29,2h2v8h-2V2z M10,2h2v8h-2V2z M58,58H2V22h56V58z M58,20H2V6h6v5c0,0.552,0.448,1,1,1   h4c0.552,0,1-0.448,1-1V6h13v5c0,0.552,0.448,1,1,1h4c0.553,0,1-0.448,1-1V6h13v5c0,0.552,0.447,1,1,1h4c0.553,0,1-0.448,1-1V6h6   V20z">
                            </path>
                            <path
                                d="M8,35h8c0.552,0,1-0.447,1-1v-8c0-0.552-0.448-1-1-1H8c-0.552,0-1,0.448-1,1v8C7,34.553,7.448,35,8,35z M9,27h6v6H9V27z">
                            </path>
                            <path
                                d="M20,35h8c0.552,0,1-0.447,1-1v-8c0-0.552-0.448-1-1-1h-8c-0.552,0-1,0.448-1,1v8C19,34.553,19.448,35,20,35z M21,27h6v6h-6   V27z">
                            </path>
                            <path d="M32,35h8v-2h-7v-6h3v-2h-4c-0.553,0-1,0.448-1,1v8C31,34.553,31.447,35,32,35z"></path>
                            <path
                                d="M32,51h8c0.553,0,1-0.447,1-1v-8c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v8C31,50.553,31.447,51,32,51z M33,43h6v6h-6   V43z">
                            </path>
                            <path
                                d="M44,51h8c0.553,0,1-0.447,1-1v-8c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v8C43,50.553,43.447,51,44,51z M45,43h6v6h-6   V43z">
                            </path>
                            <path
                                d="M34.293,29.707l2,2C36.488,31.902,36.744,32,37,32s0.512-0.098,0.707-0.293l4-4l-1.414-1.414L37,29.586l-1.293-1.293   L34.293,29.707z">
                            </path>
                            <path d="M44,35h8v-2h-7v-6h3v-2h-4c-0.553,0-1,0.448-1,1v8C43,34.553,43.447,35,44,35z"></path>
                            <path
                                d="M46.293,29.707l2,2C48.488,31.902,48.744,32,49,32s0.512-0.098,0.707-0.293l4-4l-1.414-1.414L49,29.586l-1.293-1.293   L46.293,29.707z">
                            </path>
                            <path d="M8,51h8v-2H9v-6h3v-2H8c-0.552,0-1,0.447-1,1v8C7,50.553,7.448,51,8,51z"></path>
                            <path
                                d="M13,45.586l-1.293-1.293l-1.414,1.414l2,2C12.488,47.902,12.744,48,13,48s0.512-0.098,0.707-0.293l4-4l-1.414-1.414   L13,45.586z">
                            </path>
                            <path d="M20,51h8v-2h-7v-6h3v-2h-4c-0.552,0-1,0.447-1,1v8C19,50.553,19.448,51,20,51z"></path>
                            <path
                                d="M25,45.586l-1.293-1.293l-1.414,1.414l2,2C24.488,47.902,24.744,48,25,48s0.512-0.098,0.707-0.293l4-4l-1.414-1.414   L25,45.586z">
                            </path>
                        </g>
                    </g>
                    <g id="SvgjsG8006" featurekey="nameFeature-0"
                        transform="matrix(0.8750027926852031,0,0,0.8750027926852031,39.499856360294935,156.00055030362608)"
                        fill="#fff">
                        <path
                            d="M27.143 17.714 l-16.857 0 l0 4.8571 l14.571 0 l0 6.2857 l-14.571 0 l0 4.8571 l16.857 0 l0 6.2857 l-20 0 l-4.2857 -4.2857 l0 -24.286 l24.286 0 l0 6.2857 z M32.85714285714286 40 l0 -3.4286 l7.8857 -10.857 l-7.8857 -10.857 l0 -3.4286 l6.5714 0 l6.2857 9.1429 l6.2857 -9.1429 l6.5714 0 l0 3.4286 l-7.8857 10.857 l7.8857 10.857 l0 3.4286 l-6.5714 0 l-6.2857 -9.1429 l-6.2857 9.1429 l-6.5714 0 z M62.857171428571434 23.143 l14.286 0 l0 5.1429 l-14.286 0 l0 -5.1429 z M103.42857142857143 11.428999999999998 l7.4286 0 l0 28.571 l-7.4286 0 l0 -16 l-5.0286 9.7143 l-3.7143 0 l-4.9714 -9.7143 l0 16 l-7.4286 0 l0 -28.571 l7.4286 0 l6.8571 12.971 z M117.42852857142857 40 l0 -24.286 l4.2857 -4.2857 l16.571 0 l4.2857 4.2857 l0 24.286 l-7.4286 0 l0 -8 l-10.286 0 l0 8 l-7.4286 0 z M124.85742857142857 25.714 l10.286 0 l0 -8 l-10.286 0 l0 8 z M166.85671428571428 11.428999999999998 l7.4286 0 l0 28.571 l-6.5714 0 l-11.143 -16 l0 16 l-7.4286 0 l0 -28.571 l6.5714 0 l11.143 15.886 l0 -15.886 z">
                        </path>
                    </g>
                </svg>
            </Logo>
            <MenuContainer>
                <NavList>
                    <Nav>
                        <Link onClick={() => props.setMenu('admin')}>
                            <LinkIcon isActive={props.menu == 'admin'}>
                                <AdminPanelSettingsOutlinedIcon />
                            </LinkIcon>
                            <LinkName isActive={props.menu == 'admin'}>Admin</LinkName>
                        </Link>
                    </Nav>
                    <Nav>
                        <Link onClick={() => props.setMenu('teacher')}>
                            <LinkIcon isActive={props.menu == 'teacher'}>
                                <SchoolOutlinedIcon />
                            </LinkIcon>
                            <LinkName isActive={props.menu == 'teacher'}>Teacher</LinkName>
                        </Link>
                    </Nav>
                    <Nav>
                        <Link onClick={() => props.setMenu('exam')}>
                            <LinkIcon isActive={props.menu == 'exam'}>
                                <EventNoteOutlinedIcon />
                            </LinkIcon>
                            <LinkName isActive={props.menu == 'exam'}>Exams</LinkName>
                        </Link>
                    </Nav>
                    <Nav>
                        <Link onClick={() => props.setMenu('reliever')}>
                            <LinkIcon isActive={props.menu == 'reliever'}>
                                <ReduceCapacityOutlinedIcon />
                            </LinkIcon>
                            <LinkName isActive={props.menu == 'reliever'}>Reliever</LinkName>
                        </Link>
                    </Nav>
                    <Nav>
                        <Link onClick={() => props.setMenu('report')}>
                            <LinkIcon isActive={props.menu == 'report'}>
                                <PrintOutlinedIcon />
                            </LinkIcon>
                            <LinkName isActive={props.menu == 'report'}>Reports</LinkName>
                        </Link>
                    </Nav>
                    <Nav>
                        <Link onClick={() => props.setMenu('specialDuties')}>
                            <LinkIcon isActive={props.menu == 'specialDuties'}>
                                <FactCheckOutlinedIcon />
                            </LinkIcon>
                            <LinkName isActive={props.menu == 'specialDuties'}>Special Duties</LinkName>
                        </Link>
                    </Nav>
                    <Nav>
                        <Link onClick={() => props.setMenu('venue')}>
                            <LinkIcon isActive={props.menu == 'venue'}>
                                <StoreOutlinedIcon />
                            </LinkIcon>
                            <LinkName isActive={props.menu == 'venue'}>Venue</LinkName>
                        </Link>
                    </Nav>
                    <Nav>
                        <Link onClick={() => props.setMenu('department')}>
                            <LinkIcon isActive={props.menu == 'department'}>
                                <GroupsOutlinedIcon />
                            </LinkIcon>
                            <LinkName isActive={props.menu == 'department'}>Department</LinkName>
                        </Link>
                    </Nav>
                    {/* <Nav>
                        <Link onClick={() => props.setMenu('help')}>
                            <LinkIcon isActive={props.menu == 'help'}>
                                <OndemandVideoOutlinedIcon />
                            </LinkIcon>
                            <LinkName isActive={props.menu == 'help'}>Help</LinkName>
                        </Link>
                    </Nav> */}
                    <Indicator indicatorPosition={indicatorPosition}></Indicator>
                </NavList>
            </MenuContainer>
            <Accessibility>
                <Tooltip title="Logout">
                    <IconButton aria-label="logout" size="large" onClick={logout}>
                        <DirectionsWalkOutlinedIcon sx={{ color: '#fff' }} />
                    </IconButton>
                </Tooltip>
            </Accessibility>
        </NavbarWrapper>
    )
}