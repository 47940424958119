import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import GeneralReport from "./generalReport"
import LeaderBoard from "./leaderBoard"
import ExamSchedule from "./examSchedule"
import jsPDF from 'jspdf';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Chip from '@mui/material/Chip';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import PrintIcon from '@mui/icons-material/Print';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

const ReportWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-grow: 1;
    margin-bottom: 100px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 1rem; 
    overflow: auto;
    scrollbar-width: thin;
    /* background-color: orange; */
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
`;

export default function Report() {
    document.title = "Reports";

    var moment = require('moment');
    const [currTab, setCurrTab] = React.useState(1);
    const [openDateRangeFilter, setOpenDateRangeFilter] = React.useState(false);
    const [openTeacherFilter, setOpenTeacherFilter] = React.useState(false);
    const [openExamTypeFilter, setOpenExamTypeFilter] = React.useState(false);
    const [startDate, setStartDate] = useState(`${moment().subtract((moment().format('MM') <= 5 ? 1 : 0), 'years').format('YYYY')}/06/01`);
    const [endDate, setEndDate] = useState(moment().format('YYYY/MM/DD'));
    const [allteachers, setAllTeachers] = useState([]);
    const [allDutyType, setAllDutyType] = useState([]);
    const [teacherSearchQuery, setTeacherSearchQuery] = React.useState('');
    const [dutyTypeSearchQuery, setDutyTypeSearchQuery] = React.useState('');


    const fetchTeacher = async () => {
        let res = await fetch(`${process.env.REACT_APP_API}/admin/teacher`, {
            credentials: 'include',
        });
        res = await res.json();
        res = res.map((val) => ({ checked: true, ...val }));

        setAllTeachers(res)
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <>
                        {children}
                    </>
                )}
            </div>
        );
    }

    useEffect(() => {
        // fetchVenue()
    }, []);

    function isValidDate(dateString) {
        var regEx = /^\d{4}\/\d{2}\/\d{2}$/;
        if (!dateString.match(regEx)) return false;  // Invalid format
        return moment(dateString, 'YYYY/MM/DD').isValid()
    }

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }


    return <ReportWrapper>
        <Box sx={{ width: '100%', }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={currTab} onChange={(event, newValue) => setCurrTab(newValue)} aria-label="basic tabs example"
                    variant="fullWidth">
                    <Tab label="General Report" {...a11yProps(0)} />
                    <Tab label="Leader Board" {...a11yProps(1)} />
                    <Tab label="Exam Schedule" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={currTab} index={0}>
                <GeneralReport/>
            </TabPanel>
            <TabPanel value={currTab} index={1} >
                <LeaderBoard />
            </TabPanel>
            <TabPanel value={currTab} index={2}>
                <ExamSchedule />
            </TabPanel>
        </Box>
    </ReportWrapper>;
}