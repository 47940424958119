import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import Img from '../../../assets/lottie/empty-box.json';

const ContentWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: red; */

    span {
        margin: 0;
        font-size: 40px;
        font-weight: 500;
        color: #585858;
    }
`;

export default function NoActiveExams(props) {
    return <ContentWrapper>
        <Lottie
            options={{
                loop: false,
                autoplay: true,
                animationData: Img,
                rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                }
            }}
            height={'300px'}
            width={'300px'}
        />
        <span>No Exams Are Scheduled Yet!</span>
    </ContentWrapper>
}