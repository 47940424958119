import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import DutyHistory from './dutyHistory';
import useUser from '../../../hooks/useUser';
import Lottie from 'react-lottie';
import Img from '../../../assets/lottie/time-table.json';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import ReduceCapacityOutlinedIcon from '@mui/icons-material/ReduceCapacityOutlined';

const ContentWrapper = styled.div`
    width: 100%;
    padding: 10px;
    padding-left: 5px;
    display: flex;
    overflow: scroll;
    scrollbar-width: none;
    position: relative;
    margin-left: ${props => props.isActive ? '230px' : '98px'}; 
    /* background-color: red; */
    transition: all 0.2s ease;
    
    &::-webkit-scrollbar {
        display: none;
    }
`;

const DashboardContent = styled.div`
    width: 90%;
    margin-right: 15px;
    padding: .5rem;
    display: flex; 
    flex-direction: column;
    align-items: flex-start;
    flex: 2.5;
    flex-shrink: 1;
    box-sizing: border-box;
    overflow-y: auto;
    scrollbar-width: thin;
    /* border: 2px solid red; */
    /* background-colo#291518ink; */

    &::-webkit-scrollbar {
        height: 12px;
        width: 6px;
        background: #f0f0f0;
    }
    &::-webkit-scrollbar-thumb {
        background: #cdcdcd;
        -webkit-border-radius: 1ex;
    }
`;

const ProfileHeader = styled.h1`      
    height: fit-content;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    color: #005ae6;
    margin: .5rem 0rem;
    padding-left: 2px;
`

const UpcommingDuties = styled.div`
    width: 100%;
    padding: 1rem;
    display: flex;
    flex: 1;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-start;
    /* overflow-y: hidden; */
    overflow-x: hidden;
    scrollbar-width: thin;
    border-radius: 1rem;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    /* background-color: #f5f5f5; */
    

    &::-webkit-scrollbar {
        height: 12px;
        width: 8px;
        background: #f0f0f0;
    }
    &::-webkit-scrollbar-thumb {
        background: #cdcdcd;
        -webkit-border-radius: 1ex;
    }
`;


const Header = styled.h2`
	font-size: 26px;
	font-weight: 600;
	text-align: center;    
    padding: .5rem 1rem 0rem;
	color: #545454;
	margin: 0 0 0rem;
`;

const ImageContainer = styled.div`
    height: fit-content;
    width: 100%;
    display: flex;
    flex-shrink: 1;
    align-items: center;
`;

const UpcommingDuty = styled.div`
    width: 100%;
    border-radius: 1rem;
    margin-top: .5rem;
    /* background-color: #005ae6; */
    
`;

const NoDutyText = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
	color: #545454;
`;

export default function Dashboard(props) {
    document.title = "Dashboard"

    const { user } = useUser();
    var moment = require('moment');
    const [upcommingDuties, setUpcommingDuties] = useState([]);

    const getUpcommingDuties = async () => {
        let res = await fetch(`${process.env.REACT_APP_API}/teacher/dashboard/getUpcommingDuties`, {
            method: "POST",
            headers: { Accept: 'application/json', "Content-Type": "application/json", },
            credentials: 'include',
            body: JSON.stringify({ username: user.username }),
        });
        res = await res.json();
        setUpcommingDuties(res);
    }

    useEffect(() => {
        getUpcommingDuties();
    }, []);

    return <ContentWrapper isActive={props.isActive}>
        <DashboardContent>
            <ProfileHeader>Teacher Dashboard</ProfileHeader>
            <DutyHistory />
        </DashboardContent>
        <UpcommingDuties>
            <Header>Upcoming Duties</Header>
            <ImageContainer>
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: Img,
                    }}
                    style={{ width: "85%", }}
                />
            </ImageContainer>
            {upcommingDuties[0]?.length !== 0 && upcommingDuties[0]?.map((duty, index) => {
                return <UpcommingDuty key={index}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: '#005ae6' }}>
                                <HowToRegOutlinedIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`Venue: ${duty.Venue_name}`} secondary={`Date: ${duty?.startTime?.substring(0, 10)}, Time: ${duty.Slot_startTime} - ${duty.Slot_endTime} ${moment(duty.startTime).from()}`} />
                    </ListItem>
                </UpcommingDuty>

            })}
            {upcommingDuties[1]?.length !== 0 && upcommingDuties[1]?.map((duty, index) => {
                return <UpcommingDuty key={index}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: '#f44336' }}>
                                <ReduceCapacityOutlinedIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`Venue: ${duty.Venue_name}`} secondary={`Date: ${duty?.Start_Time?.substring(0, 10)}, Time: ${duty.Slot_startTime} - ${duty.Slot_endTime},  ${moment(duty.Start_Time).from()}`} />
                    </ListItem>
                </UpcommingDuty>
            })}
            {upcommingDuties[0]?.length == 0 && upcommingDuties[1]?.length == 0 && <NoDutyText>No Upcomming Duties</NoDutyText>}
        </UpcommingDuties>
    </ContentWrapper>
}