import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const HelpWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 100px;
    overflow: auto;
    scrollbar-width: none;
    /* background-color: pink; */
`;

export default function Help(props) {

    return (
        <HelpWrapper>Help
        </HelpWrapper>
    )
}