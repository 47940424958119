import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    gridVisibleSortedRowIdsSelector,
    useGridApiContext
} from '@mui/x-data-grid';
import jsPDF from 'jspdf';
import useUser from '../../../hooks/useUser';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import PrintIcon from '@mui/icons-material/Print';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import ReduceCapacityOutlinedIcon from '@mui/icons-material/ReduceCapacityOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import logoImage from '../../../assets/logo/Rcmas logo with wordmark.webp';

const ContentWrapper = styled.div`
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    /* margin-bottom: 100px; */
    /* padding: 10px; */
    padding-bottom: 1rem;
    box-sizing: border-box;
    /* overflow: auto;
    scrollbar-width: none; */
    /* background-color: orange; */
`;

const ExamNutshellWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 1rem 0rem 1rem;
    padding-right: 1rem;
    padding-top: 0rem;
    padding-bottom: .5rem;
    box-sizing: border-box;
    border-radius: 1rem;
    border: 1px solid #cbcbcb;
    /* background-color: green; */

`;

const NutshellHeader = styled.h2`
	font-size: 27px;
	font-weight: 600;
	text-align: start;    
    padding: 1rem 1.5rem .5rem;
	color: #3d3c3c;
	margin: 0 0 0rem;
    /* background-color: pink; */
`;

const ExamDetils = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: pink; */
`;

const InputContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: yellow;
`;

const TableHeader = styled.h2`
	font-size: 23px;
	font-weight: 600;
	text-align: center;    
    padding: .5rem 1rem;
	color: #545454;
	margin: 1rem 0 0rem;
`;

const DataGridContainer = styled.div`
    width: 100%;
    /* padding: 1rem; */
    box-sizing: border-box;
    /* height: fit-content;;  */
    /* background-color: pink; */
`;

export default function ExamSchedule() {
    const { user } = useUser();
    var moment = require('moment');
    const [allExams, setAllExams] = useState([])
    const [selectedExam, setSelectedExam] = useState(null)
    const [slotName, setSlotName] = useState([]);
    const [examDetails, setExamDetails] = useState([]);
    const [relieverDetails, setRelieverDetails] = React.useState([]);
    const [relieverColumns, setRelieverColumns] = useState([]);

    const exportPdf = (PrintData, Heading, FileName, startDate = null, endDate = null) => {
        const doc = new jsPDF()
        // Calculating horizontal center of the page
        const PAGE_START = 15;
        const PAGE_CENTER = doc.internal.pageSize.getWidth() / 2;
        const PAGE_END = doc.internal.pageSize.getWidth() - 15;
        doc.autoTable({
            margin: { top: 90 },
            didDrawPage: (data) => {
                var currentPageNo = doc.internal.getCurrentPageInfo().pageNumber;
                var str = 'Page ' + currentPageNo;
                data.settings.margin.top = 15; // Reseting top margin. The change will be reflected only after print the first page.
                const TOP_TEXT_MARGIN = 70;
                if (currentPageNo === 1) {
                    doc.addImage(logoImage, 'PNG', PAGE_CENTER-(90/2), 15, 90, 30);
                    doc.setFontSize(15);
                    doc.text(Heading, PAGE_CENTER, TOP_TEXT_MARGIN - 5, { align: 'center' });
                    doc.setFontSize(9);
                    doc.text(`Exam Remarks: ${selectedExam.Exam_remark}`, PAGE_START, TOP_TEXT_MARGIN + 4, { align: 'left' });
                    doc.text(`Generated By: ${user.username} (Admin)`, PAGE_END, TOP_TEXT_MARGIN + 4, { align: 'right' });
                    doc.text(`Date: ${selectedExam.Exam_date}`, PAGE_START, TOP_TEXT_MARGIN + 8, { align: 'left' });
                    doc.text(`Generated At : ${moment().format('MMMM Do YYYY, h:mm:ss a')}`, PAGE_END, TOP_TEXT_MARGIN + 8, { align: 'right' });
                    doc.text(`Venues: ${selectedExam.Exam_rooms}`, PAGE_START, TOP_TEXT_MARGIN + 12, { align: 'left' });
                    doc.text(`Reliever: ${selectedExam.Exam_reliever == 1 ? 'Yes' : 'No'}`, PAGE_START, TOP_TEXT_MARGIN + 16, { align: 'left' });
                }
                doc.setFontSize(10);
                var pageSize = doc.internal.pageSize;
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                doc.text(str, data.settings.margin.left, pageHeight - 10);
            },
            ...PrintData
        });
        doc.save(`${FileName}.pdf`)
    }

    const getAllExams = async () => {
        let res = await fetch(`${process.env.REACT_APP_API}/admin/exam`, {
            credentials: 'include',
        });
        res = await res.json();
        setSelectedExam(res[0])
        setAllExams(res);
    }

    const getExamScedule = async () => {
        if (selectedExam == null) return
        let res = await fetch(`${process.env.REACT_APP_API}/admin/reports/getExamScedule`, {
            method: "POST",
            headers: { Accept: 'application/json', "Content-Type": "application/json", },
            credentials: 'include',
            body: JSON.stringify({ examId: selectedExam.Exam_id, }),
        });
        res = await res.json();
        res[1] = res[1].map(({ Room_id: id, ...rest }) => ({ id, ...rest }));
        setSlotName([`FIRST HALF: ${moment(res[0][0].Slot_startTime, "HH:mm").format("hh:mm A")} - ${moment(res[0][0].Slot_endTime, "HH:mm").format("hh:mm A")}`, `SECOND HALF: ${moment(res[0][1].Slot_startTime, "HH:mm").format("hh:mm A")} - ${moment(res[0][1].Slot_endTime, "HH:mm").format("hh:mm A")}`])
        setExamDetails(res[1]);
        setRelieverDetails(res[2])

        // Dynamically adding reliever teacher names to tempColumns
        let tempColumns = [];
        Object.keys(res[2][0]).forEach((teacher) => tempColumns.push({ field: teacher, headerName: teacher, flex: 3, }))
        tempColumns[0].field = 'id' 
        tempColumns[0].headerName = 'Sl. No.'  // Renaming header name from id -> Sl. No.
        setRelieverColumns(tempColumns)
    }

    useEffect(() => {
        getAllExams()
    }, [])

    useEffect(() => {
        getExamScedule()
    }, [selectedExam])


    const getFilteredRows = ({ apiRef }) => gridVisibleSortedRowIdsSelector(apiRef);

    const CustomToolbar = (props) => {
        const apiRef = useGridApiContext();

        const CSVToJSON = csv => {
            const lines = csv.split('\n');
            // Removing '\r'
            for (var i = 0; i < lines.length; i++) { lines[i] = lines[i].replace('\r', ''); }
            const keys = lines[0].split(',');
            return lines.slice(1).map(line => {
                return line.split(',').reduce((acc, cur, i) => {
                    const toAdd = {};
                    toAdd[keys[i]] = cur;
                    return { ...acc, ...toAdd };
                }, {});
            });
        };

        const handleExport = (options) => {
            if (options.type === 'pdf') {
                if (props.tableType === 'examDuty') {
                    exportPdf({
                        body: CSVToJSON(apiRef.current.getDataAsCsv(options)),
                        columns: [
                            { header: 'SlNo', dataKey: 'Sl. No.' },
                            { header: 'Venue Name', dataKey: 'Venue Name' },
                            { header: slotName[0], dataKey: slotName[0], },
                            { header: slotName[1], dataKey: slotName[1], },
                        ]
                    }, 'Exam Duty Allocation', 'Duty History');
                } else if (props.tableType === 'relieverDuty') {
                    let columnsInReport = [];
                    relieverColumns.forEach((teacher) => columnsInReport.push({ header: Object.values(teacher)[0], dataKey: Object.values(teacher)[0] }))
                    columnsInReport[0].header = columnsInReport[0].dataKey = 'Sl. No.'  // Renaming header name from id -> Sl. No.

                    exportPdf({
                        body: CSVToJSON(apiRef.current.getDataAsCsv(options)),
                        columns: columnsInReport
                    }, 'Reliever Duty Allocation', 'Reliever Allocation');

                }
            } else if (options.type === 'csv') {
                apiRef.current.exportDataAsCsv(options);
            }
        }

        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <Button
                    color={'primary'}
                    startIcon={<PrintIcon />}
                    onClick={() => handleExport({ getRowsToExport: getFilteredRows, type: 'pdf' })}
                >
                    Export pdf
                </Button>
                <Button
                    color={'primary'}
                    startIcon={<FileDownloadOutlinedIcon />}
                    onClick={() => handleExport({ getRowsToExport: getFilteredRows, type: 'csv' })}
                >
                    Export CSV
                </Button>
            </GridToolbarContainer>
        );
    };

    const examAllocationColumns = [
        { field: 'SlNo', headerName: 'Sl. No.', type: 'number', headerAlign: 'left', align: "left", flex: 1 },
        { field: 'id', headerName: 'ID', type: 'number', headerAlign: 'left', align: "left", hide: true, flex: 1 },
        {
            field: 'Venue_name', headerName: 'Venue Name', flex: 1,
            renderCell: (params) => {
                    return <Chip label={params.value} variant="outlined" color="primary" icon={<StoreOutlinedIcon />} />
            }
        },
        { field: 'Slot1_Teacher', headerName: slotName[0], flex: 3, },
        { field: 'Slot2_Teacher', headerName: slotName[1], type: 'number', headerAlign: 'left', align: "left", flex: 3 },
    ];

    return <ContentWrapper>
        <ExamNutshellWrapper>
            <NutshellHeader>Exam Details</NutshellHeader>
            <ExamDetils>
                <List sx={{ display: 'flex', width: '100%', m: 0, p: 0 }}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: '#005ae6' }}>
                                <EventOutlinedIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`${selectedExam != null ? selectedExam.Exam_date : 'Exam Date'}`} secondary={`${selectedExam != null ? moment(selectedExam.Exam_date).fromNow() : '-'}`} />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: '#005ae6' }}>
                                <TimerOutlinedIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Exam Duration" secondary={selectedExam != null ? `${selectedExam.Exam_duration / 60} Hours, ${selectedExam.Exam_duration % 60} Minutes` : '-'} />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: '#005ae6' }}>
                                <StoreOutlinedIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Venues" secondary={selectedExam != null ? selectedExam.Exam_rooms : '-'} />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: '#005ae6' }}>
                                <ReduceCapacityOutlinedIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Reliever" secondary={selectedExam != null ? selectedExam.Exam_reliever == 1 ? 'Yes' : 'No' : '-'} />
                    </ListItem>
                    {/* <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: '#005ae6' }}>
                                <HowToRegOutlinedIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`Allocation ${Math.round((allocationProgress?.NoOfAllocations / allocationProgress?.TotalDuties) * 100)}%`} secondary={`${allocationProgress?.NoOfAllocations} of ${allocationProgress?.TotalDuties}`} />
                    </ListItem> */}
                </List>

                <Autocomplete
                    sx={{ width: 444 }}
                    onChange={(_event, newTeam) => {
                        setSelectedExam(newTeam)
                    }}
                    onInputChange={(event, newInputValue, reason) => {
                        // Run when clear button is clicked
                        if (reason === 'clear') {
                            setSelectedExam(null)
                        }
                    }}
                    getOptionLabel={(option) => `${option.Exam_date} - (${moment(option.Exam_date).fromNow()}, id:${option.Exam_id})`}
                    options={allExams}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select Exam"
                        />
                    )}
                />
            </ExamDetils>
            <InputContainer>

            </InputContainer>
        </ExamNutshellWrapper>
        <DataGridContainer>
            <DataGrid
                rows={examDetails}
                columns={examAllocationColumns}
                autoHeight={true}
                components={{ Toolbar: CustomToolbar }}
                componentsProps={{ toolbar: { tableType: 'examDuty' } }}
                disableSelectionOnClick
            ></DataGrid>
            {selectedExam != null && selectedExam?.Exam_reliever == 1 && <>
                <TableHeader>Reliever Table</TableHeader>
                <DataGrid
                    rows={relieverDetails}
                    columns={relieverColumns}
                    autoHeight={true}
                    components={{ Toolbar: CustomToolbar }}
                    componentsProps={{ toolbar: { tableType: 'relieverDuty' } }}
                    disableSelectionOnClick
                ></DataGrid>
            </>}
        </DataGridContainer>
    </ContentWrapper>
}