import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useUser from '../../hooks/useUser'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import coverImg from '../../assets/images/login image.webp'
import logo from '../../assets/logo/Rcmas logo with wordmark.jpg';

const LoginWrapper = styled.div`
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	/* justify-content: center; */
	/* background-color: #005ae6; */
`;

const CollegeLogo = styled.img`
	height: 100px;
	max-height: 100px;
	width: 100%;
	position: absolute;
	margin: 1rem 0 0;
	object-fit: contain;

`;

const LoginContainer = styled.div`
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 4rem;
	padding: 0 4rem;
	box-sizing: border-box;
	/* background-color: yellow; */
`;

const LoginCoverSection = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: flex-start;
	
	background-color: red;
`;

const TextContent = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	/* padding: 0 2rem; */
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* background-color: blue; */

	img {
		height: 400px;
        max-height: 400px;
        object-fit: contain;
	}

	span {
		text-align: center;
		letter-spacing: 1px;
		user-select: none;
	}

	.normalText { 
		font-size: 1.1rem;
		font-weight: normal;
		color: #3e5060;
	}
	
	.boldText { 
		font-size: 1.25rem;
		font-weight: bold;
		color: #3b3f44;
	}
`;

const FormContainer = styled.div`
	/* height: fit-content; */
	height: 100%;
	width: 100%;
	min-width: 250px;
	max-width: 400px;
	justify-self: center;
	/* padding: 0 10%; */
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	box-sizing: border-box;
	/* border-radius: 1rem; */
	/* box-shadow:  -5px -5px 21px #003c9a, 5px 5px 21px #0078ff; */
	/* background-color: #670000;	 */
`;

const HeaderText = styled.h2`
	font-size: 40px;
	font-weight: 700;
	text-align: center;
	color: #005ae6;
	margin: 0 0 2rem;
`;

export const ErrorText = styled.p`
    margin: 0;
	margin-top: .5rem;
    padding: 0;
    font-size: .8rem;
    text-align: right;
    color: red;
`;

export default function Login() {
	document.title = "Login"
	const [showPassword, setShowPassword] = useState(false);
	const { user, setUser } = useUser();
	const navigate = useNavigate();
	const [loginErrorText, setLoginErrorText] = useState(null);
	const location = useLocation();

	const from = location.state?.from || '/';

	const {
		watch,
		register,
		reset,
		setValue,
		handleSubmit,
		formState: { errors }
	} = useForm({ mode: "all", reValidateMode: "all" });

	const OnSubmit = async data => {
		let result = await fetch(`${process.env.REACT_APP_API}/login`, {
			method: "POST",
			headers: { Accept: 'application/json', "Content-Type": "application/json", },
			credentials: 'include',
			body: JSON.stringify(data),
		});
		const res = await result.json();
		console.log('returned object is ', (res));
		if (res.error) {
			setLoginErrorText(res.errmsg);
		} else {
			setUser(res.user);
		}
	};

	useEffect(() => {
		if (user.isLoggedIn) {
			console.log('user is logged in')
			navigate(from, { replace: true });
		}
	}, [user]);

	useEffect(() => {
		return () => {
			reset();
		}
	}, []);

	return (
		<LoginWrapper>
			<CollegeLogo src={logo} alt="" />
			<LoginContainer>
				<TextContent>
					<img src={coverImg} alt="" />
					<span class='normalText'>
						<span class='boldText'>Welcome to our Exam Duty Management System!   </span>
						We're here to ensure smooth and stress-free exam days. Our system helps organize schedules, assign duties, and keep track of exam personnel. Let's work together to make exam days a success!
					</span>
				</TextContent>
				<FormContainer>
					<HeaderText>Login</HeaderText>
					<TextField
						name="email"
						label="User ID"
						autoFocus
						variant="outlined"
						{...register("email", {
							required: "Please Enter E-mail ID"
						})}
					/>
					{errors.email && <ErrorText>{errors.email.message}</ErrorText>}
					<FormControl sx={{ marginTop: '2rem' }} variant="outlined">
						<InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
						<OutlinedInput
							name="password"
							type={showPassword ? 'text' : 'password'}
							value={watch().password}
							onChange={(event, value) => setValue('password', value.password)}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										onClick={() => setShowPassword(!showPassword)}
										edge="end"
									>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
							label="Password"
							{...register("password", {
								required: "Please Enter Your Password"
							})}
						/>
						{errors.password && <ErrorText>{errors.password.message}</ErrorText>}
					</FormControl>
					<Button
						sx={{ marginTop: '2rem' }}
						variant="contained"
						style={{
							borderRadius: "2rem",
							color: "#fff",
							backgroundColor: "#005ae6",
						}}
						onClick={handleSubmit(OnSubmit)}
					>Login</Button>
					{loginErrorText && <ErrorText>{loginErrorText}</ErrorText>}
				</FormContainer>
			</LoginContainer>
		</LoginWrapper>
	)
}
