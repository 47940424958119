/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import useUser from '../../../hooks/useUser';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import InsertChartOutlinedOutlinedIcon from '@mui/icons-material/InsertChartOutlinedOutlined';
import DirectionsWalkOutlinedIcon from '@mui/icons-material/DirectionsWalkOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';

const Sidebar = styled.div`
    height: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    margin: 10px;
    padding: 0;
    position: fixed;
    width: ${props => props.isActive ? '210px' : '78px'};
    /* background: linear-gradient(45deg,rgba(0,109,182,1) 0%,  rgba(9,57,121,1) 100%); */
    /* background: green; */
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    /* border: .1px solid #005ae6; */
    border-radius: 1rem;
    z-index: 99;
    transition: all 0.2s ease;
`;

const LogoContainer = styled.div`
    width: 100%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: ${props => props.isActive ? 'zoom-out' : 'zoom-in'};);
    /* background-color: green; */

    #btn{
        position: absolute;
        color: #005ae6;
        display:flex;
        font-size: 39px;
        box-sizeing: border-box;
        padding: 3px;
        cursor: pointer;
        text-align: center;
        left: ${props => props.isActive ? '200px' : '67px'};
        box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
        border-radius: 2rem;
    /* transition: all 0.2s ease; */
        transform: ${props => props.isActive ? 'rotate(0deg)' : 'rotate(180deg)'};);
        background-color: white;
    }
`;

const Logo = styled.div`
    color: #fff;
    height: 60px;
    width: 100%;
    padding:.7rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    pointer-events: none;
    white-space: nowrap;
    transition: all .4s ease;
    pointer-events: none;
    /* box-sizing: border-box; */
    /* background-color: pink; */

    svg {
        height: 53px;
        width: 53px;
        /* margin: 0px; */
    }

    div {
        display: ${props => props.isActive ? 'block' : 'none'};
        margin-left: 15px;
        /* flex-grow: 1; */
        /* flex: 1; */
        font-size: 22px;
        font-weight: 400;
        color: #005ae6;
        transition: 5s;
        /* background-color: yellow; */
    }
`;

const NavList = styled.ul`
    height: 100%;
    /* padding-top: 400px; */
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    z-index: 200;
    /* background-color: blue; */
`;

const Nav = styled.li`
    position: relative;
    height: 50px;
    /* width: 100%; */
    margin: 0 5px;
    list-style: none;
    line-height: 50px;
    margin: 5px 14px;
    /* background-color: azure; */

    div {
        position: absolute;
        left: 125px;
        top: 0;
        transform: translate(-35% , -50%);
        border-radius: 6px;
        height: 35px;
        width: 150px;
        /* background: linear-gradient(90deg,#15519f 0%, rgba(0,109,182,1) 100%); */
        background: #005ae6;
        line-height: 35px;
        text-align: center;
        color: #fff;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        transition: 0s;
        opacity: 0;
        pointer-events: none;
        display: block;
    }

    
    &:hover  div {
        transition: ${props => props.isActive ? 'none' : 'all .5s ease'};        
        opacity: ${props => props.isActive ? 0 : 1};
        top: ${props => props.isActive ? '0' : '50%'};
        /* background-color: red; */
    }
    
    &:hover span {
            color: #fff;
        }

    a {
        color: #005ae6;
        display: flex;
        align-items: center;
        text-decoration: none;
        border-radius: 12px;
        white-space: nowrap;
        transition: all 0.4s ease;

        &:hover {
            color: #fff;
            background: #005ae6;
        }
    }

    i {
        /* vertical-align: sub; */
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 23px;
        font-weight: 400;
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;
        text-align: center;
        /* background-color: #c4e600; */
    }

    

    span {
        font-size: 15px;
        font-weight: 400;
        transition: 0s;
        opacity: 1;
        color: #005ae6;
        pointer-events: auto;
        display: ${props => props.isActive ? 'block' : 'none'};
        transition: all 0.4s ease;
    }
`;

const DotIndicionator = styled.p`
    height: 10px;
    width: 10px;
    margin: 0;
    position: absolute;
    left: 32px;
    top: 8px;
    opacity: ${props => props.showDot ? 1 : 0};
    background: red;
    border-radius: 12px;

`;

const ProfileContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #005ae6;
    padding-bottom: .7rem;
    /* left: 0; */
    /* background-color: orange; */

`;

const ProfileBanner = styled.p`
    /* height: 15px; */
    
    font-size: 12px;
    margin: 0;
    opacity: ${props => props.isActive ? 1 : 0};
    overflow: ${props => props.isActive ? 'hidden' : 'visible'};
    /* background-color: orange; */
`;


export default function SideBar(props) {
    const { user, setUser } = useUser();
    const navigate = useNavigate();
    var moment = require('moment');
    const [showDot, setShowDot] = useState(false);

    const logout = async () => {
        let res = await fetch(`${process.env.REACT_APP_API}/logout`, {
            credentials: 'include',
        });
        setUser({ isLoggedIn: false });
        navigate('/');
    }


    const getActiveExams = async () => {
        let res = await fetch(`${process.env.REACT_APP_API}/teacher/getActiveExams`, {
            credentials: 'include',
        });
        res = await res.json();

        // Filtering exams that are not over yet
        let liveExams = [];
        res.forEach(exam => {
            if (moment(exam.Exam_date, 'YYYY/MM/DD HH:mm').subtract(2, 'days').format('YYYY/MM/DD HH:mm') >= moment().format('YYYY/MM/DD HH:mm')) {
                liveExams.push(exam);
            }
        });
        setShowDot(liveExams.length > 0)
    };

    useEffect(() => {
        getActiveExams();
        const interval = setInterval(() => {
            getActiveExams()
        }, 5000);
        return () => clearInterval(interval);
    }, [])

    return <><Sidebar isActive={props.isActive}>
        <LogoContainer isActive={props.isActive} onClick={() => props.setActive(!props.isActive)}>
            <Logo isActive={props.isActive}>
                <svg width="234.375" height="265.74310128341455" viewBox="0 0 234.375 265.74310128341455" className="css-1j8o68f">
                    <defs id="SvgjsDefs8003"></defs>
                    <g id="SvgjsG8004" featurekey="rootContainer"
                        transform="matrix(4.725301111045653,0,0,4.725301111045653,0.0000347689931040783,0.019721259814305742)"
                        fill="#005ae6">
                        <path xmlns="http://www.w3.org/2000/svg"
                            d="M23.11 55.78L1.69 43.41A3.39 3.39 0 0 1 0 40.48V15.75a3.39 3.39 0 0 1 1.69-2.94L23.11.45a3.39 3.39 0 0 1 3.39 0l21.41 12.37a3.39 3.39 0 0 1 1.69 2.94v24.72a3.39 3.39 0 0 1-1.69 2.94L26.5 55.78a3.39 3.39 0 0 1-3.39 0z">
                        </path>
                    </g>
                    <g id="SvgjsG8005" featurekey="symbolFeature-0" transform="matrix(1.2,0,0,1.2,81,74)" fill="#ffffff">
                        <g xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M59,4h-7V1c0-0.552-0.447-1-1-1h-4c-0.553,0-1,0.448-1,1v3H33V1c0-0.552-0.447-1-1-1h-4c-0.552,0-1,0.448-1,1v3H14V1   c0-0.552-0.448-1-1-1H9C8.448,0,8,0.448,8,1v3H1C0.448,4,0,4.448,0,5v16v38c0,0.553,0.448,1,1,1h58c0.553,0,1-0.447,1-1V21V5   C60,4.448,59.553,4,59,4z M48,2h2v8h-2V2z M29,2h2v8h-2V2z M10,2h2v8h-2V2z M58,58H2V22h56V58z M58,20H2V6h6v5c0,0.552,0.448,1,1,1   h4c0.552,0,1-0.448,1-1V6h13v5c0,0.552,0.448,1,1,1h4c0.553,0,1-0.448,1-1V6h13v5c0,0.552,0.447,1,1,1h4c0.553,0,1-0.448,1-1V6h6   V20z">
                            </path>
                            <path
                                d="M8,35h8c0.552,0,1-0.447,1-1v-8c0-0.552-0.448-1-1-1H8c-0.552,0-1,0.448-1,1v8C7,34.553,7.448,35,8,35z M9,27h6v6H9V27z">
                            </path>
                            <path
                                d="M20,35h8c0.552,0,1-0.447,1-1v-8c0-0.552-0.448-1-1-1h-8c-0.552,0-1,0.448-1,1v8C19,34.553,19.448,35,20,35z M21,27h6v6h-6   V27z">
                            </path>
                            <path d="M32,35h8v-2h-7v-6h3v-2h-4c-0.553,0-1,0.448-1,1v8C31,34.553,31.447,35,32,35z"></path>
                            <path
                                d="M32,51h8c0.553,0,1-0.447,1-1v-8c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v8C31,50.553,31.447,51,32,51z M33,43h6v6h-6   V43z">
                            </path>
                            <path
                                d="M44,51h8c0.553,0,1-0.447,1-1v-8c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v8C43,50.553,43.447,51,44,51z M45,43h6v6h-6   V43z">
                            </path>
                            <path
                                d="M34.293,29.707l2,2C36.488,31.902,36.744,32,37,32s0.512-0.098,0.707-0.293l4-4l-1.414-1.414L37,29.586l-1.293-1.293   L34.293,29.707z">
                            </path>
                            <path d="M44,35h8v-2h-7v-6h3v-2h-4c-0.553,0-1,0.448-1,1v8C43,34.553,43.447,35,44,35z"></path>
                            <path
                                d="M46.293,29.707l2,2C48.488,31.902,48.744,32,49,32s0.512-0.098,0.707-0.293l4-4l-1.414-1.414L49,29.586l-1.293-1.293   L46.293,29.707z">
                            </path>
                            <path d="M8,51h8v-2H9v-6h3v-2H8c-0.552,0-1,0.447-1,1v8C7,50.553,7.448,51,8,51z"></path>
                            <path
                                d="M13,45.586l-1.293-1.293l-1.414,1.414l2,2C12.488,47.902,12.744,48,13,48s0.512-0.098,0.707-0.293l4-4l-1.414-1.414   L13,45.586z">
                            </path>
                            <path d="M20,51h8v-2h-7v-6h3v-2h-4c-0.552,0-1,0.447-1,1v8C19,50.553,19.448,51,20,51z"></path>
                            <path
                                d="M25,45.586l-1.293-1.293l-1.414,1.414l2,2C24.488,47.902,24.744,48,25,48s0.512-0.098,0.707-0.293l4-4l-1.414-1.414   L25,45.586z">
                            </path>
                        </g>
                    </g>
                    <g id="SvgjsG8006" featurekey="nameFeature-0"
                        transform="matrix(0.8750027926852031,0,0,0.8750027926852031,39.499856360294935,156.00055030362608)"
                        fill="#fff">
                        <path
                            d="M27.143 17.714 l-16.857 0 l0 4.8571 l14.571 0 l0 6.2857 l-14.571 0 l0 4.8571 l16.857 0 l0 6.2857 l-20 0 l-4.2857 -4.2857 l0 -24.286 l24.286 0 l0 6.2857 z M32.85714285714286 40 l0 -3.4286 l7.8857 -10.857 l-7.8857 -10.857 l0 -3.4286 l6.5714 0 l6.2857 9.1429 l6.2857 -9.1429 l6.5714 0 l0 3.4286 l-7.8857 10.857 l7.8857 10.857 l0 3.4286 l-6.5714 0 l-6.2857 -9.1429 l-6.2857 9.1429 l-6.5714 0 z M62.857171428571434 23.143 l14.286 0 l0 5.1429 l-14.286 0 l0 -5.1429 z M103.42857142857143 11.428999999999998 l7.4286 0 l0 28.571 l-7.4286 0 l0 -16 l-5.0286 9.7143 l-3.7143 0 l-4.9714 -9.7143 l0 16 l-7.4286 0 l0 -28.571 l7.4286 0 l6.8571 12.971 z M117.42852857142857 40 l0 -24.286 l4.2857 -4.2857 l16.571 0 l4.2857 4.2857 l0 24.286 l-7.4286 0 l0 -8 l-10.286 0 l0 8 l-7.4286 0 z M124.85742857142857 25.714 l10.286 0 l0 -8 l-10.286 0 l0 8 z M166.85671428571428 11.428999999999998 l7.4286 0 l0 28.571 l-6.5714 0 l-11.143 -16 l0 16 l-7.4286 0 l0 -28.571 l6.5714 0 l11.143 15.886 l0 -15.886 z">
                        </path>
                    </g>
                </svg>
                <div>Exam<br /> Manager</div>
            </Logo>
            {/* <i isActive={props.isActive} id="btn" onClick={() => props.setActive(!props.isActive)}><ArrowCircleLeftOutlinedIcon /></i> */}
        </LogoContainer>
        <NavList>
            <Nav isActive={props.isActive} onClick={() => props.setMenu('dashboard')}>

                <a href="/#">
                    <i><SchoolOutlinedIcon /></i>
                    <span>Dashboard</span>
                </a>
                <div>Dashboard</div>
            </Nav>
            <Nav isActive={props.isActive} onClick={() => props.setMenu('examNotification')}>
                <a href="/#">
                    <i>
                        <NotificationsActiveOutlinedIcon />
                        <DotIndicionator showDot={showDot} />
                    </i>
                    <span>Exam Notifications</span>
                </a>
                <div>Exam Notifications</div>
            </Nav>
            <Nav isActive={props.isActive} onClick={() => props.setMenu('leaderBoard')}>
                <a href="/#">
                    <i><InsertChartOutlinedOutlinedIcon /></i>
                    <span>Leader Board</span>
                </a>
                <div>Leader Board</div>
            </Nav>
            <Nav isActive={props.isActive} onClick={() => props.setMenu('profile')}>
                <a href="/#">
                    <i><ManageAccountsOutlinedIcon /></i>
                    <span>Profile</span>
                </a>
                <div>Profile</div>
            </Nav>
            <Nav isActive={props.isActive} onClick={() => props.setMenu('help')}>
                <a href="/#">
                    <i><OndemandVideoOutlinedIcon /></i>
                    <span>Help</span>
                </a>
                <div>Help</div>
            </Nav>
        </NavList>
        <ProfileContent>
            <Tooltip title="Logout" placement="top" sx={{ width: 'fit-content', }} >
                <IconButton aria-label="logout" size="large" onClick={logout}>
                    <DirectionsWalkOutlinedIcon sx={{ color: '#005ae6' }} />
                </IconButton>
            </Tooltip>
            <ProfileBanner isActive={props.isActive}>Loged in As: {user.username}</ProfileBanner>
        </ProfileContent>
    </Sidebar>
    </>
}

